interface Props {
  height?: number
  width?: number
}

export const UploadCircle = ({ height = 24, width = 24 }: Props) => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M21 14.2506V19.5006C21 19.8984 20.842 20.2799 20.5607 20.5612C20.2794 20.8426 19.8978 21.0006 19.5 21.0006H4.5C4.10218 21.0006 3.72064 20.8426 3.43934 20.5612C3.15804 20.2799 3 19.8984 3 19.5006V14.2506C3 14.0517 3.07902 13.8609 3.21967 13.7203C3.36032 13.5796 3.55109 13.5006 3.75 13.5006C3.94891 13.5006 4.13968 13.5796 4.28033 13.7203C4.42098 13.8609 4.5 14.0517 4.5 14.2506V19.5006H19.5V14.2506C19.5 14.0517 19.579 13.8609 19.7197 13.7203C19.8603 13.5796 20.0511 13.5006 20.25 13.5006C20.4489 13.5006 20.6397 13.5796 20.7803 13.7203C20.921 13.8609 21 14.0517 21 14.2506ZM8.78063 8.03122L11.25 5.5609V14.2506C11.25 14.4495 11.329 14.6403 11.4697 14.7809C11.6103 14.9216 11.8011 15.0006 12 15.0006C12.1989 15.0006 12.3897 14.9216 12.5303 14.7809C12.671 14.6403 12.75 14.4495 12.75 14.2506V5.5609L15.2194 8.03122C15.3601 8.17195 15.551 8.25101 15.75 8.25101C15.949 8.25101 16.1399 8.17195 16.2806 8.03122C16.4214 7.89048 16.5004 7.69961 16.5004 7.50059C16.5004 7.30157 16.4214 7.1107 16.2806 6.96996L12.5306 3.21996C12.461 3.15023 12.3783 3.09491 12.2872 3.05717C12.1962 3.01943 12.0986 3 12 3C11.9014 3 11.8038 3.01943 11.7128 3.05717C11.6217 3.09491 11.539 3.15023 11.4694 3.21996L7.71937 6.96996C7.57864 7.1107 7.49958 7.30157 7.49958 7.50059C7.49958 7.69961 7.57864 7.89048 7.71937 8.03122C7.86011 8.17195 8.05098 8.25101 8.25 8.25101C8.44902 8.25101 8.63989 8.17195 8.78063 8.03122Z" fill="#333333"/>
  </svg>
  )
}
