import React, { useState, useEffect } from 'react';
import { StarCircle } from 'assets/icons/StarCircle'
import { NotificationCloseIcon } from 'assets/icons/NotificationCloseIcon'

interface ToastProps {
  message: string;
  onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
      onClose();
    }, 3000); // 3000 миллисекунд (3 секунды) - время, через которое закроется уведомление

    return () => clearTimeout(timeout);
  }, [onClose]);

  return visible ? 
    <div className="toast">
        <div className='toast-container df fdr aic' style={{"gap": "16px"}}>
            <button className="notification-button"/>
            <div className='df aic'>
                <StarCircle/>
            </div>
            <div className='df aic'>
                {message}
            </div>
            <div className='df aic cp' onClick={() => {onClose();}}>
                <NotificationCloseIcon/>
            </div>
        </div>
    </div> : null;
};
