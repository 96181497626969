interface props {
    size?: number
    color?: string
}

export const GlobeIcon = ({ size = 24, color = '#333333' }: props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM9.52782 15.75H14.4722C13.9688 17.4694 13.125 19.0191 12 20.2397C10.875 19.0191 10.0313 17.4694 9.52782 15.75ZM9.1875 14.25C8.93876 12.7603 8.93876 11.2397 9.1875 9.75H14.8125C15.0613 11.2397 15.0613 12.7603 14.8125 14.25H9.1875ZM3.75 12C3.74935 11.2392 3.85442 10.4819 4.06219 9.75H7.66782C7.44407 11.2417 7.44407 12.7583 7.66782 14.25H4.06219C3.85442 13.5181 3.74935 12.7608 3.75 12ZM14.4722 8.25H9.52782C10.0313 6.53062 10.875 4.98094 12 3.76031C13.125 4.98094 13.9688 6.53062 14.4722 8.25ZM16.3322 9.75H19.9378C20.3541 11.2211 20.3541 12.7789 19.9378 14.25H16.3322C16.5559 12.7583 16.5559 11.2417 16.3322 9.75ZM19.3472 8.25H16.0256C15.6429 6.74392 15.0001 5.31623 14.1263 4.03125C15.2427 4.33127 16.2839 4.86162 17.1831 5.58818C18.0823 6.31475 18.8194 7.22146 19.3472 8.25ZM9.87375 4.03125C8.9999 5.31623 8.35713 6.74392 7.97438 8.25H4.65282C5.18056 7.22146 5.91772 6.31475 6.81689 5.58818C7.71606 4.86162 8.75733 4.33127 9.87375 4.03125ZM4.65282 15.75H7.97438C8.35713 17.2561 8.9999 18.6838 9.87375 19.9688C8.75733 19.6687 7.71606 19.1384 6.81689 18.4118C5.91772 17.6852 5.18056 16.7785 4.65282 15.75ZM14.1263 19.9688C15.0001 18.6838 15.6429 17.2561 16.0256 15.75H19.3472C18.8194 16.7785 18.0823 17.6852 17.1831 18.4118C16.2839 19.1384 15.2427 19.6687 14.1263 19.9688Z" fill={color} />
        </svg>
    )
}
