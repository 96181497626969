import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { postUserData, setToken } from 'store/features/apiSlice';
import { useNavigate } from 'react-router-dom';

import s from './index.module.scss';
import Tractor1 from '../../assets/images/tractor1.jpg';
import Tractor2 from '../../assets/images/tractor2.jpg';
import Tractor3 from '../../assets/images/tractor3.jpg';
import { InputComponent } from 'ui/InputComponent';
import { LogoIcon } from 'assets/icons/Logo';
import config from 'config.json'

export const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorApi, setErrorApi] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const images = [Tractor1, Tractor2, Tractor3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('authtoken');
    if (authToken) {
      navigate('/main/requests')
    }
  }, [navigate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Переключаемся на следующее изображение
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, config.image_next_speed_login);

    // Очистка таймера при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [images.length]); // Пересоздаем таймер при изменении длины массива изображений


  useEffect(() => {
    setErrorApi('');
  }, [username, password]);

  useEffect(() => {
    setIsDisabled(!(password !== '' && username !== ''));
  }, [password, username]);

  const registerHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    const userObj = {
      username,
      password,
    };

    try {
      const response = await dispatch(postUserData(userObj));

      if (response?.payload?.message === 'ok') {
        const token = response?.payload?.data?.token;
        dispatch(setToken(token));
        localStorage.setItem('authtoken', token);
        localStorage.setItem('username', userObj.username);
        navigate('/main/requests');
      } else if (response?.error?.message.includes('404')) {
        setErrorApi('Вы ввели неверный логин или пароль');
      } else if (response?.error?.message.includes('401')){
        setErrorApi('Вы ввели неверный логин или пароль');
      }
      else {
        setErrorApi('Ошибка сервера');
      }
    } catch (error) {
      localStorage.removeItem("authtoken");
      localStorage.removeItem("username");
      console.error(error);
    }
  };

  return (
    <div className={`${s.main} df`}>
      <div className={`${s.left}`}>
        <img src={images[currentImageIndex]} className="h100 w100" alt={`Tractor ${currentImageIndex + 1}`} />
      </div>
      <div className={`${s.right} df fdc jcsb aic`}>
        <div className={`${s.top} w100 df fdc aic`}>
          <div>
          <LogoIcon />
          </div>
          <span className="fw700">Менеджер заявок</span>
          <form className="w100 df fdc aic" style={{ gap: '20px' }}>
            <InputComponent
              type="user"
              placeholder="Логин"
              state={username}
              setState={setUsername}
              onKeyDown={(e: React.KeyboardEvent) => e.key === 'Enter' && registerHandler(e)}
            />
            <div className="df fdc aifs w100">
              <InputComponent
                type="password"
                placeholder="Пароль"
                state={password}
                setState={setPassword}
                onKeyDown={(e: React.KeyboardEvent) => e.key === 'Enter' && registerHandler(e)}
              />
              {errorApi !== '' && (
                <span style={{ color: '#EB5757', fontSize: '14px', margin: '10px 0 0 12px' }}>{errorApi}</span>
              )}
            </div>
            <button
              disabled={isDisabled}
              className={`${s.btn} cp w100 df jcc aic fw400`}
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                background: isDisabled ? 'rgba(242, 201, 76, 0.58)' : '#F2C94C',
              }}
              onClick={(e) => {
                e.stopPropagation();
                registerHandler(e);
              }}
            >
              Войти
            </button>
          </form>
        </div>
        <div className={`${s.bottom} df aic`}>
          <p className="tac fz14" style={{ color: '#4F4F4F' }}>
            Нажимая на кнопку “Войти” Вы соглашаетесь с <span className='tdu cp'>Правилами пользования</span>
          </p>
        </div>
      </div>
    </div>
  );
};
