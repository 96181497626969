interface props {
    width?: string
    height?: string
}

export const ToggleOff = ({ width = '56px', height = '28px' }: props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 28" fill="none">
            <rect width={width} height={height} rx="14" fill="#F2C94C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.8333 24.5C46.6322 24.5 51.3333 19.799 51.3333 14C51.3333 8.20101 46.6322 3.5 40.8333 3.5C35.0343 3.5 30.3333 8.20101 30.3333 14C30.3333 19.799 35.0343 24.5 40.8333 24.5Z" fill="white" />
        </svg>
    )
}