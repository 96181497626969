import s from './index.module.scss'
import 'react-loading-skeleton/dist/skeleton.css'

import { useCallback, useEffect, useState } from "react";
import { InputComponent } from "ui/InputComponent"
import { SelectComponent } from 'ui/SelectComponent';
import { PlusIcon } from 'assets/icons/Plus';
import { EditCompanyPopup } from './components/EditCompanyPopup';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesData, getOffCompaniesData, postAddCompaniesData, selectHide } from 'store/features/apiSlice';
import { companyTypes, parseCompanyType, companyFunction, parseCompanyFunction } from 'constDatas';
import { SkeletonComponent } from 'ui/SkeletonComponent';
import { RestoreCompanyPopup } from './components/RestoreCompanyPopup';
import { AddCompanyPopup } from './components/AddCompanyPopup'

export const Companies = () => {
    const [name, setName] = useState('');
    const [type, setType]: any | null = useState(null);
    const [compFunction, setCompFunction]:  any | null = useState(null);
    const [contact, setContact] = useState('');

    const [selectedCompany, setSelectedCompany] = useState('');
    const [apierror, setApierror] = useState('');
    const [editpopup, setEditpopup] = useState(false);
    const [restorepopup, setRestorepopup] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [addCompanyPopup, setAddCompanyPopup] = useState(false);

    const [currentData, setCurrentData] = useState([]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const dispatch = useDispatch()
    const hide = useSelector(selectHide)

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        // Добавляем слушатель события изменения размера окна
        window.addEventListener('resize', handleResize);
    
        // Убираем слушатель при размонтировании компонента
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 

    const getCompanies = useCallback(async () => {
        setLoading(true);
        const companiesData = await dispatch(getCompaniesData());
        setCurrentData(companiesData?.payload?.data);
        setLoading(false);
    }, [dispatch]);

    const getOffCompanies = useCallback(async () => {
        setLoading(true);
        const companiesData = await dispatch(getOffCompaniesData());
        setCurrentData(companiesData?.payload?.data);
        setLoading(false);
    }, [dispatch]);

    const addCompany = async () => {
        const obj = {
            name: name,
            company_type: parseCompanyType(type),
            company_function: parseCompanyFunction(compFunction),
            representative_contacts: contact,
        }
        const response = await dispatch(postAddCompaniesData(obj))
        if (response?.payload?.message === 'ok') {
            hide ? getCompanies() : getOffCompanies()
            setName('')
            setContact('')
            setType(null)
            setCompFunction(null)
            setIsDisabled(false)
        } else if (response?.error?.message.includes('409')) {
            setApierror('Компания с такими данными уже существует.')
            return
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        }  else {
            setApierror('Ошибка сервера')
            return
        }
    }

    useEffect(() => {
        setApierror('')
        if (isNumber && name !== '' && type !== null) setIsDisabled(false)
        else setIsDisabled(true)
    }, [isNumber, type, name]);

    useEffect(() => {
        hide ? getCompanies() : getOffCompanies()
    }, [hide, getCompanies, getOffCompanies]);

    const RenderDesktopCompanies = (item: any, index: any) => (
        <div
        className={`${s.secondrow} cp w100 df aic fz16 textellipsis`}
        onClick={() => {
            setEditpopup(true);
            setSelectedCompany(item);
        }}
        key={index}
    >
        <span className='w30'>{item.name}</span>
        <span className='w30'>{parseCompanyType(item.company_type)}</span>
        <span className='w30'>{parseCompanyFunction(item.company_function)}</span>
        {!hide ? (
            <div className='df jcsb aic textellipsis w40'>
                <span>{item.representative_contacts}</span>
                <div
                    className={`${s.recover} cp df jcc aic`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setRestorepopup(true);
                        setSelectedCompany(item)
                    }}
                >
                    Восстановить
                </div>
            </div>
        ) : <span className='w40'>{item.representative_contacts}</span>}
    </div>
    ) 

    const RenderMobileCompanies = (item: any, index: any) => (
        <div 
        className={s.mobileCompanyBlock}
        onClick={() => {
          setEditpopup(true);
          setSelectedCompany(item);
        }}
        key={index}>
          <div className={s.nameBlock}>
            <span className='fz16 fw600' style={{color: "#333"}}>{`${item?.name}`}</span>
          </div>
          <div className={s.infoBlock}>
            <span className='fz14 fw600' style={{color: "#828282"}}>{`Тип компаний: ${parseCompanyType(item.company_type)}`}</span>
            <span className='fz14 fw600' style={{color: "#828282"}}>{`Функция компаний: ${parseCompanyFunction(item.company_function)}`}</span>
          </div>
          <div style={{borderBottom: "1px dashed #E0E0E0", height: "1px"}}></div>
          <div className={`${s.infoSecondBlock} df jcsb`}>
            <span className='fz14 fw600' style={{color: "#333"}}>{`${item?.representative_contacts}`}</span>
            {!hide ? (<div>
            <button className={`${s.recoverMobileBlock} cp df aic fz14 fw600`} onClick={(e) => { 
                    e.stopPropagation();
                    setRestorepopup(true);
                    setSelectedCompany(item) }}>
                Восстановить
            </button>
          </div>) : <></>}
          </div>
        </div>
    ) 

    return (
        <>
            {addCompanyPopup && <AddCompanyPopup
                popup={addCompanyPopup}
                setPopup={setAddCompanyPopup}
                refreshData={getCompanies}
                refreshData2={getOffCompanies}
                hide={hide}
            />}
            {restorepopup && <RestoreCompanyPopup
                title={"Восстановить компанию?"}
                subtitle={"Подтвердите восстановление компании"}
                popup={restorepopup}
                setPopup={setRestorepopup}
                selected={selectedCompany}
                refreshData={getCompanies}
                refreshData2={getOffCompanies}
                hide={hide}
            />}
            {editpopup && <EditCompanyPopup
                selected={selectedCompany}
                popup={editpopup}
                setPopup={setEditpopup}
                refreshData={getCompanies}
                refreshData2={getOffCompanies}
                hide={hide}
            />}
            <div className={s.main}>
                <div className={`${s.createFields} fww-mobile`} style={{ gap: '15px' }}>
                    <InputComponent
                        type={"default"}
                        icon={false}
                        placeholder={'Наименование'}
                        state={name}
                        setState={setName}
                        width={"100%"}
                        autocomplete={"off"}
                        maxLength={30}
                    />
                    <SelectComponent
                        placeholder={"Тип компании"}
                        items={companyTypes}
                        state={type}
                        setState={setType}
                        width={"100%"}
                    />
                    <SelectComponent
                        placeholder={"Функция компании"}
                        items={companyFunction}
                        state={compFunction}
                        setState={setCompFunction}
                        width={"100%"}
                    />

                </div>
                <div className={`${s.createFields} fww-mobile`} style={{ gap: '15px', marginTop: '10px' }}>
                <InputComponent
                        type={"phone"}
                        icon={false}
                        placeholder={'Номер представителя'}
                        state={contact}
                        setState={setContact}
                        width={"100%"}
                        setIs={setIsNumber}
                    />
                <div className='df fdc aic w100'>
                        <button
                            disabled={isDisabled}
                            className={`${s.adduser} df jcc aic`}
                            style={{
                                width: '100%',
                                height: '49px',
                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                background: isDisabled ? '#f2c94c94' : '#f2c94c'
                            }}
                            onClick={() => { addCompany(); setIsDisabled(true) }}
                        >
                            <PlusIcon />
                            <span>Добавить в справочник</span>
                        </button>
                        {apierror !== '' && <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{apierror}</span>}
                    </div>

                </div>
                <div className={`${s.row} w100 aic fz18 fw600`}>
                    <span className='w30'>Наименование</span>
                    <span className='w30'>Тип компании</span>
                    <span className='w30'>Фукнция компании</span>
                    <span className='w40'>Контакты представителя</span>
                </div>
                <div className={`${s.lists}`}>
                    {loading ? (
                        <>
                            {currentData.length !== 0 ? (
                                <>
                                    {Array(currentData.length).fill('').map((_, i: number) => (
                                        <div style={{ height: '50px', borderRadius: '16px', marginTop: '5px' }} key={i}>
                                            <SkeletonComponent height={"100%"} bradius={'16px'} />
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {Array(7).fill('').map((_, i: number) => (
                                        <div style={{ height: '50px', borderRadius: '16px', marginTop: '5px' }} key={i}>
                                            <SkeletonComponent height={"100%"} bradius={'16px'} />
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                          {currentData.map((item, index) => (isMobile ? RenderMobileCompanies(item, index) : RenderDesktopCompanies(item, index)))}
                        </>
                    )}
                </div>
                {isMobile && !addCompanyPopup && !restorepopup && !editpopup && (
                <div className={`${s.mobileadd} cp df jcc aic`} onClick={() => setAddCompanyPopup(true)}>
                    <PlusIcon color={'black'} />
                    <span className='fw700 fz16'>Добавить в справочник</span>
                </div>
                )}
            </div>
        </>
    )
}