import { useCallback, useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import s from './index.module.scss'
import { InputComponent } from 'ui/InputComponent';
import { PlusIcon } from 'assets/icons/Plus';
import { EditMaterialsPopup } from './components/EditMaterialPopup';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterialsData, getOffMaterialsData, postAddmaterialsData, selectHide } from 'store/features/apiSlice';
import { SkeletonComponent } from 'ui/SkeletonComponent';
import { RestoreMaterialPopup } from './components/RestoreMaterialPopup';
import { AddMaterialPopup } from './components/AddMaterialPopup'

export const Materials = () => {
    const [name, setName] = useState('');
    const [density, setDensity] = useState('1.6');
    const [errorapi, setErrorapi] = useState('');
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [editpopup, setEditpopup] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [restorepopup, setRestorepopup] = useState(false);
    const [addmaterialpopup, setAddMaterialPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDensity, setIsDensity] = useState(false);
    const [data, setData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const dispatch = useDispatch()
    const hide = useSelector(selectHide)

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 

    const getMaterials = useCallback(async () => {
        setLoading(true);
        const requestData = await dispatch(getMaterialsData());
        setData(requestData?.payload?.data);
        setLoading(false);
    }, [dispatch]);

    const getOffMaterials = useCallback(async () => {
        setLoading(true);
        const requestData = await dispatch(getOffMaterialsData());
        setData(requestData?.payload?.data);
        setLoading(false);
    }, [dispatch]);

    async function addMaterial() {
        const obj = {
            name: name,
            density: density
        }
        const response = await dispatch(postAddmaterialsData(obj))
        if (response?.payload?.message === 'ok') {
            hide ? getMaterials() : getOffMaterials()
            setName('')
            setDensity('1.6')
            setIsDisabled(false)
        } else if (response?.error?.message.includes('409')) {
            setErrorapi('Такой материал уже существует')
            return
        } else if (response?.error?.message.includes('403')) {
            setErrorapi('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setErrorapi('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setErrorapi('Ошибка сервера')
            return
        }
    }

    useEffect(() => {
        getMaterials()
    }, [getMaterials]);

    useEffect(() => {
        hide ? getMaterials() : getOffMaterials()
    }, [hide, getMaterials, getOffMaterials]);

    useEffect(() => {
        if (name !== '' && /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(density)) setIsDisabled(false)
        else setIsDisabled(true)
    }, [name, density, isDensity]);

    const RenderDesktopMaterials = (item: any, index: any) => (
        <div
        className={`${s.secondrow} cp w100 df aic fz16`}
        onClick={() => {
            setEditpopup(true);
            setSelectedMaterial(item);
        }}
        key={index}
    >
        <span style={{ width: '100%' }}>{item.name}</span>
        {hide ? (
            <div className='df aic' style={{ width: '100%' }}>
                <span>{item.density}</span>
            </div>
        ) : (
            <div className='df jcsb aic' style={{ width: '100%' }}>
                <span>{item.density}</span>
                <div className={`${s.recover} cp df jcc aic`} onClick={(e) => { e.stopPropagation(); setRestorepopup(true); setSelectedMaterial(item) }}>Восстановить</div>
            </div>
        )}
    </div>
    ) 
  
    const RenderMobileMaterials = (item: any, index: any) => (
        <div 
        className={s.mobileMaterialsBlock}
        onClick={() => {
          setEditpopup(true);
          setSelectedMaterial(item);
        }}
        key={index}>
          <div className={s.nameBlock}>
            <span className='fz16 fw600' style={{color: "#333"}}>{`${item?.name}`}</span>
          </div>
          <div className={s.infoBlock}>
            <span className='fz14 fw600' style={{color: "#828282"}}>{`Плотность: ${item?.density}`}</span>
          </div>
          {!hide ? (<div>
            <button className={`${s.recoverMobileBlock} cp df aic fz14 fw600`} onClick={(e) => { 
                    e.stopPropagation();
                    setRestorepopup(true);
                    setSelectedMaterial(item) }}>
                Восстановить
            </button>
          </div>) : <></>}
        </div>
    ) 

    return (
        <>
            {addmaterialpopup && <AddMaterialPopup
                popup={addmaterialpopup}
                setPopup={setAddMaterialPopup}
                refreshData={getMaterials}
                refreshOffData={getOffMaterials}
                hide={hide}
            />}
            {editpopup && <EditMaterialsPopup
                selected={selectedMaterial}
                popup={editpopup}
                setPopup={setEditpopup}
                refreshData={getMaterials}
                refreshOffData={getOffMaterials}
                hide={hide}
            />}
            {restorepopup && <RestoreMaterialPopup
                title={'Восстановить материал?'}
                subtitle={'Подтвердите восстановление материала'}
                popup={restorepopup}
                setPopup={setRestorepopup}
                selected={selectedMaterial}
                refresh={getMaterials}
                refreshOff={getOffMaterials}
                hide={hide}
            />}
            <div className={s.main}>
                <div className={`${s.createFields} fww-mobile`} style={{ gap: '15px' }}>
                    <InputComponent
                        type={"default"}
                        icon={false}
                        placeholder={'Наименование'}
                        state={name}
                        setState={setName}
                        // width={"100%"}
                        autocomplete={"off"}
                        maxLength={40}
                    />
                    <InputComponent
                        type={"default"}
                        icon={false}
                        placeholder={'Введите плотность (т/м3)*'}
                        state={density}
                        setState={setDensity}
                        regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                        errortext={'Неккоректное значение'}
                        // width={"50%"}
                        autocomplete={"off"}
                        setIs={setIsDensity}
                        maxLength={20}
                    />
                    <div>
                        <button
                            className={`${s.adduser} w100 df jcc aic`}
                            disabled={isDisabled}
                            style={{
                                background: isDisabled ? 'rgba(242, 201, 76, 0.58)' : '',
                                cursor: isDisabled ? 'not-allowed' : 'pointer'
                            }}
                            onClick={() => { addMaterial(); setIsDisabled(true) }}
                        >
                            <PlusIcon />
                            <span className='wsn'>Добавить в справочник</span>
                        </button>
                        {errorapi !== '' && <span style={{ color: 'rgb(235, 87, 87)', marginLeft: '10px', fontSize: '14px' }}>{errorapi}</span>}
                    </div>
                </div>
                <div className={`${s.row} w100 aic fz18 fw600`}>
                    <span style={{ width: '100%' }}>Наименование</span>
                    <span style={{ width: '100%' }}>Плотность</span>
                </div>
                <div className={`${s.lists}`}>
                    {loading ? (
                        <>
                            {data.length !== 0 ? (
                                <>
                                    {Array(data.length).fill('').map((_, i: number) => (
                                        <div style={{ height: '50px', borderRadius: '16px', marginTop: '5px' }} key={i}>
                                            <SkeletonComponent height={"100%"} bradius={'16px'} />
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {Array(7).fill('').map((_, i: number) => (
                                        <div style={{ height: '50px', borderRadius: '16px', marginTop: '5px' }} key={i}>
                                            <SkeletonComponent height={"100%"} bradius={'16px'} />
                                        </div>
                                    ))}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {data.map((item, index) => (isMobile ? RenderMobileMaterials(item, index) : RenderDesktopMaterials(item, index)))}
                        </>
                    )}
                </div>
                {isMobile && !addmaterialpopup && !editpopup && !restorepopup && (
              <div className={`${s.mobileadd} cp df jcc aic`} onClick={() => setAddMaterialPopup(true)}>
                  <PlusIcon color={'black'} />
                  <span className='fw700 fz16'>Добавить в справочник</span>
              </div>
              )}
            </div >
        </>
    )
}