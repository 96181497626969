interface props {
    size?: number
    color?: string
}

export const BellIcon = ({ size = 24, color = '#333333' }: props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill={color}>
            <path d="M15.7501 21C15.7501 21.1989 15.671 21.3897 15.5304 21.5303C15.3897 21.671 15.199 21.75 15.0001 21.75H9.00005C8.80114 21.75 8.61037 21.671 8.46972 21.5303C8.32907 21.3897 8.25005 21.1989 8.25005 21C8.25005 20.8011 8.32907 20.6103 8.46972 20.4697C8.61037 20.329 8.80114 20.25 9.00005 20.25H15.0001C15.199 20.25 15.3897 20.329 15.5304 20.4697C15.671 20.6103 15.7501 20.8011 15.7501 21ZM20.7985 18C20.6684 18.2292 20.4794 18.4196 20.2512 18.5514C20.0229 18.6833 19.7636 18.7518 19.5001 18.75H4.50005C4.23637 18.7496 3.97744 18.6798 3.74935 18.5475C3.52127 18.4151 3.3321 18.225 3.20091 17.9963C3.06972 17.7676 3.00114 17.5083 3.00208 17.2446C3.00303 16.9809 3.07347 16.7222 3.2063 16.4944C3.72661 15.5981 4.50005 13.0631 4.50005 9.75C4.50005 7.76088 5.29023 5.85322 6.69675 4.4467C8.10327 3.04018 10.0109 2.25 12.0001 2.25C13.9892 2.25 15.8968 3.04018 17.3034 4.4467C18.7099 5.85322 19.5001 7.76088 19.5001 9.75C19.5001 13.0622 20.2744 15.5981 20.7947 16.4944C20.9289 16.7225 20.9999 16.9822 21.0004 17.2468C21.0009 17.5114 20.9309 17.7714 20.7976 18H20.7985ZM19.5001 17.25C18.7754 16.0059 18.0001 13.1297 18.0001 9.75C18.0001 8.1587 17.3679 6.63258 16.2427 5.50736C15.1175 4.38214 13.5914 3.75 12.0001 3.75C10.4088 3.75 8.88263 4.38214 7.75741 5.50736C6.63219 6.63258 6.00005 8.1587 6.00005 9.75C6.00005 13.1306 5.2238 16.0069 4.50005 17.25H19.5001Z" fill="#333333" />
        </svg>
    )
}
