import axios from 'axios'

const api = axios.create({
  baseURL: 'https://api.zayavki.kz/',
})

const { CancelToken } = axios
let cancel: Function | null

// Интерцептор для запросов
axios.interceptors.request.use(
  (config: any) => {
    // Устанавливаем токен отмены для запроса
    config.cancelToken = new CancelToken((c) => {
      cancel = c
    })

    const token = localStorage.getItem('authtoken')
    if (token) {
      config.headers = {
        ...config.headers,
        'Authentication-Token': token,
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Интерцептор для ответов
axios.interceptors.response.use(
  (response) => {
    cancel = null
    return response
  },
  (error) => {
    cancel = null
    return Promise.reject(error)
  }
)

export const cancelPendingRequests = () => {
  if (cancel) {
    cancel('Запрос отменен из-за размонтирования компонента')
  }
}

// USERS
export const getUsers: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/user', { headers })
}
export const postUser: any = (obj: any) => {
  return api.post('/user/auth', obj)
}

export const getCurrentUser: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/whoami', { headers })
}

export const postAdduser: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.post('/user', obj, { headers })
}

export const putEdituser: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.put('/user', obj, { headers })
}

// COMPANIES
export const getCompanies: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/companies', { headers })
}

export const getCompaniesWithFiltering: any = (obj: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  const { company_function } = obj

  return api.get(`/companies?company_function=${company_function}`, { headers })
}

export const getOffCompanies: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/companies/deactivated', { headers })
}

export const putEditcompanies: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.put('/companies', obj, { headers })
}

export const postAddcompanies: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.post('/companies', obj, { headers })
}

// ОБЪЕКТЫ
export const getObjects: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/objects', { headers })
}

export const getOffObjects: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/objects/deactivated', { headers })
}

export const postAddobject: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.post('/objects', obj, { headers })
}

export const putEditobjects: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.put('/objects', obj, { headers })
}

// МАТЕРИАЛЫ
export const getMaterials: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/materials', { headers })
}

export const getOffMaterials: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/materials/deactivated', { headers })
}

export const postAddmaterials: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.post('/materials', obj, { headers })
}

export const putEditmaterials: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.put('/materials', obj, { headers })
}

// REQUEST

export const getDeactivatedOrFinishedRequests: any = (
  obj: any, page?: number, per_page?: number, company_customer_name?: string, deposit?:any, status?:any,
  date_from?: any, date_to?:any,
  token: any = localStorage.getItem('authtoken')
) => {
  // const { limit,company_customer_name, offset, status, deposit } = obj
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  const queryParams: any = {
    ...(page && { page }),
    ...(company_customer_name && { company_customer_name }),
    ...(per_page && { per_page }),
    ...(status && { status }),
    ...(deposit && { deposit }),
    ...(date_from && { date_from }),
    ...(date_to && { date_to }),
  };

  return api.get(`/requisition`, {
    params: queryParams,
    headers,
  })
}

export const getRequestDataByCurrentId: any = (
  obj: any,
  token: any = localStorage.getItem('authtoken')
) => {
  const { req_id } = obj
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get(`/requisition/${req_id}`, { headers })
}

export const getTodayRequests: any = (
  obj: any, page?: number, per_page?: number, company_customer_name?: string, deposit?:any, status?:any,
  token: any = localStorage.getItem('authtoken')
) => {
  // const { limit,company_customer_name, offset, status, deposit } = obj
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  const queryParams: any = {
    ...(page && { page }),
    ...(company_customer_name && { company_customer_name }),
    ...(per_page && { per_page }),
    ...(status && { status }),
    ...(deposit && { deposit }),
  };

  return api.get(`/requisition`, {
    params: queryParams,
    headers,
  })
}


export const postAddrequest: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.post('/requisition', obj, { headers })
}

export const putEditrequestStatus: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.put('/requisition/state', obj, { headers })
}

export const putEditrequest: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.put('/requisition', obj, { headers })
}

// WEIGHINGS

export const getWeighings: any = (token: string | null) => {
  if (token === undefined) token = localStorage.getItem('authtoken')
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/weighings', { headers })
}

export const postAddweighings: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.post('/weigh', obj, { headers })
}

export const getWeighingByID: any = (id: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.get(`/weigh/${id}`, { headers })
}

export const putEditWeighings: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.put('/weighings', obj, { headers })
}

export const retieWeighings: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.put('/weigh/relation', obj, { headers })
}

export const getWeighingsHistory: any = (weigh_id: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get(`/weighings/changes/${weigh_id}`, { headers })
}

export const getElectronReport: any = (weigh_id: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get(`/weighings/report/${weigh_id}`, { headers })
}

// BRIEFCASE
export const getBriefCases: any = (token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }
  return api.get('/file/info', { headers })
}

export const getBriefCase: any = (file_id: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.get(`/file/${file_id}`, { headers })
}

export const postBriefCase: any = (obj: any) => {
  return api.post('/file', obj)
}

export const postAddBriefCase: any = (obj: any, token: string) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.post('/file', obj, { headers })
}

export const deleteBriefCase: any = (file_id: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.delete(`/file/${file_id}`, { headers })
}

// REQUEST REPORT
export const getRequestReport: any = (obj: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  const { date_from, date_to, deposit } = obj

  const createRequestURL = (): string => {
    if (deposit?.length) {
      return `/request_report?date_from=${date_from}&date_to=${date_to}&deposit=${encodeURIComponent(
        deposit?.join(',')
      )}`
    }
    return `/request_report?date_from=${date_from}&date_to=${date_to}`
  }

  return api.get(createRequestURL(), { headers })
}

// SUMMARY INVOICE
export const getSummaryInvoice: any = (obj: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  const {
    date_from,
    date_to,
    company_customer_id,
    company_provider_id,
    shipping_type,
    object_ids,
  } = obj

  const createRequestURL = (): string => {
    if (object_ids?.length) {
      return `/summary_invoice?date_from=${date_from}&date_to=${date_to}&company_customer_id=${company_customer_id}&company_provider_id=${company_provider_id}&shipping_type=${shipping_type}&object_ids=${encodeURIComponent(
        object_ids?.join(',')
      )}`
    }
    return `/summary_invoice?date_from=${date_from}&date_to=${date_to}&company_customer_id=${company_customer_id}&company_provider_id=${company_provider_id}&shipping_type=${shipping_type}`
  }

  return api.get(createRequestURL(), { headers })
}

// NOTIFICATIONS
export const getNotifications: any = (obj: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  // const { per_page, page } = obj

  return api.get(`/notifications`, { headers })
}

export const markNotificationAsRead: any = (obj: any, token: string) => {
  const headers: any = {
    'Content-Type': 'application/json',
    'Authentication-Token': token,
  }

  return api.post(`/notifications`, obj, { headers })
}
