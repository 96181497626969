import { CloseIcon } from "assets/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postAddObjectsData } from "store/features/apiSlice";
import { InputComponent } from "ui/InputComponent";
import { SelectComponent } from "ui/SelectComponent";

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    companyNames: any[]
    refreshObjects: any
    refreshOffObjects: any
    hide: boolean
}

export const AddObjectPopup = ({ popup, setPopup, companyNames, refreshObjects, refreshOffObjects, hide }: props) => {
    const [name, setName] = useState('');
    const [clientName, setClientName] = useState('');
    const [number, setNumber] = useState('');
    const [addressurl, setAddressurl] = useState('');
    const [address, setAddress] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [apierror, setApierror] = useState('');

    const dispatch = useDispatch()

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    useEffect(() => {
        if (selectedCompany !== null && name !== '' && number !== '' && clientName !== '' && address !== '' && addressurl !== '') setIsDisabled(false)
        else setIsDisabled(true)
    }, [name, number, address, clientName, addressurl, selectedCompany]);

    async function addObject() {
        setIsDisabled(true)
        const obj = {
            name: name,
            company_id: companyNames?.filter((item: any) => item.name === selectedCompany)[0].company_id,
            address: address,
            address_link: addressurl,
            client_name: clientName,
            client_number: number
        }
        const response = await dispatch(postAddObjectsData(obj))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                hide ? refreshObjects() : refreshOffObjects()
            }, 500);
            setPopup(false)
            setIsDisabled(false)
        } else if (response?.error?.message.includes('409')) {
            setApierror('Такой объект уже существует')            
            return
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setApierror('Ошибка сервера')
            return
        }
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '60px' }}>
                        Добавить объект
                    </span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                    <>
                        <div className="df fdc" style={{ gap: '15px' }}>
                            <InputComponent
                                type={"default"}
                                placeholder={"Наименования"}
                                icon={false}
                                autocomplete={"off"}
                                state={name}
                                setState={setName}
                                maxLength={20}
                            />
                            <SelectComponent
                                placeholder={"Компания"}
                                items={companyNames}
                                state={selectedCompany}
                                setState={setSelectedCompany}
                            />
                            <InputComponent
                                type={'default'}
                                placeholder={'Имя встречающего лица'}
                                icon={false}
                                autocomplete={'off'}
                                state={clientName}
                                setState={setClientName}
                                maxLength={50}
                            />
                            <InputComponent
                                type={"phone"}
                                icon={false}
                                placeholder={'Номер встречающего лица'}
                                state={number}
                                setState={setNumber}
                                width={"100%"}
                            />
                            <InputComponent
                                type={"default"}
                                icon={false}
                                placeholder={'Ссылка на адрес'}
                                state={addressurl}
                                setState={setAddressurl}
                                width={"100%"}
                                maxLength={40}
                            />
                            <InputComponent
                                type={"default"}
                                icon={false}
                                placeholder={'Адрес'}
                                state={address}
                                setState={setAddress}
                                width={"100%"}
                                maxLength={30}
                            />
                        </div>
                        <div>
                            {apierror !== '' && <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{apierror}</span>}
                        </div>
                        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                            <button
                                disabled={isDisabled}
                                onClick={() => addObject()}
                                className="tac"
                                style={{
                                    width: '100%',
                                    cursor: isDisabled ? "not-allowed" : "pointer",
                                    background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                }}
                            >
                                Сохранить
                            </button>
                            <button
                                onClick={() => setPopup(false)}
                                className="cp tac"
                                style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                            >
                                Отменить
                            </button>
                        </div>
                    </>
            </div>
        </div>
    )
}