import { CloseIcon } from "assets/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getHistoryWeighingsData } from "store/features/apiSlice";
import { parseWeighingHistory } from 'constDatas';

import s from '../index.module.scss'

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    refreshData: Function
}

export const EditHistoryWeighingsPopup = ({ selected, popup, setPopup, refreshData }: props) => {
    const [desc, setDesc] = useState('');
    const [errortext, setErrortext] = useState('');
    const [weighingHistory, setWeighingHistory] = useState([]);

    const [isDisabled, setIsDisabled] = useState(true);

    const dispatch = useDispatch()

    async function getWeighingsHistory() {
        const response = await dispatch(getHistoryWeighingsData(selected?.id));
        if (response?.payload?.message === 'ok') {
            setWeighingHistory(response?.payload?.data);
        } else if (response?.error?.message.includes('403')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('404')) {
            setErrortext('Истории по данному отвесу, не найдены')
            return
        } else {
            setErrortext("Ошибка сервера")
            return
        }
    }

    useEffect(() => {
        getWeighingsHistory()
    }, []);

    useEffect(() => {
        if (desc !== '') setIsDisabled(false)
        else setIsDisabled(true)
    }, [desc]);

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs w100" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px', maxWidth: '90vh' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>
                        Отвес ID: {selected?.id}
                    </span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                    <>
                    
                    <div className={`${s.scroll} df fdr jcsb`}>
                        <div className="df fdc jcsb w100">
                            {weighingHistory.map((item:any, index:any) => (
                                <div className="df fdc" style={{gap: "20px"}} key={index}>
                                    <div className="df fdc" style={{gap: "20px"}}>
                                        <span><strong>Действие по отвесу</strong></span>
                                        <span><strong>Дата дейстия:</strong> {item?.created_on}</span>
                                        <span><strong>Примечание:</strong> {item?.desc}</span>
                                        <span><strong>Пользователь:</strong> {item?.user?.full_name || '-'}</span>
                                    </div>
                                    <div className="df fdr jcsb">
                                        <div className="df fdc w50" style={{gap:"10px"}}>
                                            <span><strong>Старые данные:</strong></span>
                                            <div className="df fdc" style={{ gap: '15px' }} key={index}>
                                            {item?.old_data && Object.keys(item?.old_data).length > 0 ? (
                                                <>
                                                    {Object.entries(item.old_data).map(([key, value]) => (
                                                        <div key={key}>
                                                            {`${parseWeighingHistory(key)}: ${parseWeighingHistory(value)}`}
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <span>История отсутствует</span>
                                            )}
                                        </div>
                                        </div>

                                        <div style={{borderLeft: "1px dashed #E0E0E0", height: "100%", marginRight: "10px"}}></div>

                                        <div className="df fdc w50" style={{gap:"10px"}}>
                                            <span><strong>Новые данные:</strong></span>
                                        <div className="df fdc" style={{ gap: '15px' }} key={index}>
                                        {item?.new_data && Object.keys(item?.new_data).length > 0 ? (
                                            <>
                                                {Object.entries(item.new_data).map(([key, value]) => (
                                                    <div key={key}>
                                                        {`${parseWeighingHistory(key)}: ${parseWeighingHistory(value)}`}
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <span>История отсутствует</span>
                                        )}
                                    </div>
                                        </div>
                                    </div>
                                    <div style={{borderBottom: "1px dashed #E0E0E0", marginBottom: "10px"}}></div>
                                </div>

                            ))}
                            </div>
                    </div>

                    {errortext !== '' && (<span className="fz14 w100" style={{ color: '#EB5757' }}>{errortext}</span>)}
                        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                            <button
                                onClick={() => setPopup(false)}
                                className="cp tac w100"
                                style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                            >
                                Закрыть
                            </button>
                        </div>
                    </>
            </div>
        </div>
    )
}