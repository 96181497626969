import { DatePicker } from 'antd'
import { CloseIcon } from 'assets/icons/Close'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { InputComponent } from 'ui/InputComponent'
import { postAddweighingsData } from 'store/features/apiSlice'
import dayjs from 'dayjs'

import config from 'config.json'
import { SelectComponent } from 'ui/SelectComponent'
import { exportWeighingType, parseShip } from 'constDatas'

interface props {
  popup: boolean
  setPopup: Function
  refreshData: any
  selected: any
}

export const AddWeighingsPopup = ({
  popup,
  setPopup,
  refreshData,
  selected,
}: props) => {
  const [fullname, setFullname] = useState('')
  const [carnumber, setCarnumber] = useState('')
  const [tara, setTara] = useState('')
  const [brutto, setBrutto] = useState('')
  const [netto, setNetto] = useState('')
  const [tsWeighingID, setTsWeighingID] = useState('')
  const [typeexport, setTypeexport] = useState<null | string>(null)
  const [comment, setComment] = useState('')
  const [cubature, setCubature] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isTons, setIsTons] = useState(true)
  const [date, setDate]: any = useState(null)
  const [errortext, setErrortext] = useState('')
  const [userRole, setUserRole] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    if (selected?.unit_type === 'Tons') {
      setIsTons(true)
    } else {
      setIsTons(false)
      setComment('-')
    }
  }, [])

  useEffect(() => {
    if (popup) document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [popup])

  useEffect(() => {
    const userRole = localStorage.getItem('userrole')

    if (userRole) {
      setUserRole(userRole)
    }
  }, [userRole])

  useEffect(() => {
    if (isTons) {
      if (
        fullname !== '' &&
        date !== null &&
        /^[0-9.]+$/.test(tara) &&
        /^[0-9.]+$/.test(brutto) &&
        /^[0-9.]+$/.test(netto) &&
        comment !== ''
      )
        setIsDisabled(false)
      else setIsDisabled(true)
    } else {
      if (
        fullname !== '' &&
        date !== null &&
        /^[0-9.]+$/.test(cubature) &&
        comment !== ''
      )
        setIsDisabled(false)
      else setIsDisabled(true)
    }
  }, [fullname, carnumber, tara, brutto, netto, comment, cubature, date])

  async function addWeighings() {
    if (isTons) {
      const obj = {
        deposit: selected?.deposit,
        req_id: selected?.id,
        car_number: carnumber,
        desc: comment,
        operator_name: fullname,
        ts_weighing_id: tsWeighingID || undefined,
        shipping_weighing_type: parseShip(typeexport) || undefined,
        weight_netto: netto ? parseFloat(netto) : 0,
        weight_tare: tara ? parseFloat(tara) : 0,
        weight_gross: brutto ? parseFloat(brutto) : 0,
        rq_date_create: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
      }
      if (config.add_weigh_tons_in_tons.includes(userRole)) {
        const response = await dispatch(postAddweighingsData(obj))
        if (response?.payload?.message === 'ok') {
          refreshData()
          setPopup(false)
        } else if (response?.error?.message.includes('403')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else if (response?.error?.message.includes('401')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else {
          setErrortext('Ошибка сервера')
          return
        }
      } else {
        setErrortext('Недостаточно прав для выполнения этого запроса.')
        return
      }
    } else {
      const obj = {
        deposit: selected?.deposit,
        req_id: selected?.id,
        car_number: carnumber,
        desc: comment,
        operator_name: fullname,
        ts_weighing_id: tsWeighingID || undefined,
        shipping_weighing_type: parseShip(typeexport) || undefined,
        ...(selected?.unit_type === 'Tons' && {
          weight_netto: selected?.material?.density * parseFloat(cubature),
        }),
        ...(selected?.unit_type === 'Cubes' && {
          cubic_capacity: parseFloat(cubature),
        }),
        rq_date_create: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
      }
      if (
        selected?.unit_type === 'Tons' &&
        config.add_weigh_tons_in_cubes.includes(userRole)
      ) {
        const response = await dispatch(postAddweighingsData(obj))
        if (response?.payload?.message === 'ok') {
          refreshData()
          setPopup(false)
        } else if (response?.error?.message.includes('403')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else if (response?.error?.message.includes('401')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else {
          setErrortext('Ошибка сервера')
          return
        }
      } else if (
        selected?.unit_type === 'Cubes' &&
        config.add_weigh_cubes_in_cubes.includes(userRole)
      ) {
        const response = await dispatch(postAddweighingsData(obj))
        if (response?.payload?.message === 'ok') {
          refreshData()
          setPopup(false)
        } else if (response?.error?.message.includes('403')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else if (response?.error?.message.includes('401')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else {
          setErrortext('Ошибка сервера')
          return
        }
      } else {
        setErrortext('Недостаточно прав для выполнения этого запроса.')
        return
      }
    }
  }

  const handleClickOverlay = (e: any) => {
    if (e.target.classList.contains('overlay')) {
      setPopup(false)
    }
  }

  return (
    <div
      className="h100vh posa w100 df jcc aic overlay"
      style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}
      onClick={handleClickOverlay}
    >
      <div
        className="df fdc alfs"
        style={{
          overflowY: 'auto',
          maxHeight: '100vh',
          background: 'white',
          width: '380px',
          borderRadius: '30px',
          padding: '20px',
          gap: '20px',
        }}
      >
        <div className="df jcsb aic">
          <span style={{ fontSize: '20px' }}>Добавить отвес</span>
          <div className="df jcc aic cp" onClick={() => setPopup(false)}>
            <CloseIcon />
          </div>
        </div>
        <div className="df fdc" style={{ gap: '15px' }}>
          <div className="df w100 usa" style={{ gap: '10px' }}>
            {selected?.unit_type === 'Tons' ? (
              <>
                <div
                  className="df jcc aic cp"
                  onClick={() => setIsTons(true)}
                  style={{
                    padding: '10px 5px',
                    borderBottom: isTons ? '1px solid black' : '',
                  }}
                >
                  Тонны
                </div>
                <div
                  className="df jcc aic cp"
                  onClick={() => setIsTons(false)}
                  style={{
                    padding: '10px 5px',
                    borderBottom: isTons ? '' : '1px solid black',
                  }}
                >
                  Кубы
                </div>
              </>
            ) : (
              <div
                className="df jcc aic cp"
                onClick={() => setIsTons(false)}
                style={{
                  padding: '10px 5px',
                  borderBottom: isTons ? '' : '1px solid black',
                }}
              >
                Кубы
              </div>
            )}
          </div>

          {isTons ? (
            <>
              <InputComponent
                type={'default'}
                placeholder={'Введите ФИО'}
                icon={false}
                state={fullname}
                setState={setFullname}
                autocomplete={'off'}
                maxLength={30}
              />
              <InputComponent
                type={'default'}
                placeholder={'Гос.номер транспорта'}
                icon={false}
                state={carnumber}
                setState={setCarnumber}
                regex={/^[a-zA-Z0-9]+$/}
                errortext={'Некорректное значение'}
                autocomplete={'off'}
                maxLength={50}
              />
              <InputComponent
                type={'default'}
                placeholder={'Тара'}
                icon={false}
                state={tara}
                setState={setTara}
                regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                errortext={'Некорректное значение'}
                autocomplete={'off'}
                maxLength={20}
              />
              <InputComponent
                type={'default'}
                placeholder={'Брутто'}
                icon={false}
                state={brutto}
                setState={setBrutto}
                regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                errortext={'Некорректное значение'}
                autocomplete={'off'}
                maxLength={20}
              />
              <InputComponent
                type={'default'}
                placeholder={'Нетто'}
                icon={false}
                state={netto}
                setState={setNetto}
                regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                errortext={'Некорректное значение'}
                autocomplete={'off'}
                maxLength={20}
              />
              <DatePicker
                style={{
                  borderRadius: '16px',
                  height: '49px',
                  paddingLeft: '18px',
                  fontFamily: 'inherit',
                }}
                showTime
                format="DD-MM-YYYY HH:mm:ss"
                className="datePickerWeighingsInput"
                value={date}
                onChange={(selectedDate: any) => setDate(selectedDate)}
                placeholder="Дата - время отвеса"
              />
              <SelectComponent
                placeholder="Тип перевозки отвеса"
                items={exportWeighingType}
                state={typeexport}
                setState={setTypeexport}
              />
              <InputComponent
                type={'default'}
                placeholder={'TS ID'}
                icon={false}
                state={tsWeighingID}
                setState={setTsWeighingID}
                autocomplete={'off'}
                regex={/^\d+$/}
                errortext={'Неккоректное значение'}
                maxLength={100}
              />
              <InputComponent
                type={'textarea'}
                placeholder={'Примечание'}
                icon={false}
                state={comment}
                setState={setComment}
                maxLength={500}
              />
            </>
          ) : (
            <>
              <InputComponent
                type={'default'}
                placeholder={'Введите ФИО'}
                icon={false}
                state={fullname}
                setState={setFullname}
                autocomplete={'off'}
                maxLength={30}
              />
              <InputComponent
                type={'default'}
                placeholder={'Гос.номер транспорта'}
                icon={false}
                state={carnumber}
                setState={setCarnumber}
                regex={/^[a-zA-Z0-9]+$/}
                errortext={'Некорректное значение'}
                autocomplete={'off'}
                maxLength={50}
              />
              <InputComponent
                type={'default'}
                placeholder={'Кубатура'}
                icon={false}
                state={cubature}
                setState={setCubature}
                regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                errortext={'Некорректное значение'}
                autocomplete={'off'}
                maxLength={20}
              />
              <DatePicker
                style={{
                  borderRadius: '16px',
                  height: '49px',
                  paddingLeft: '18px',
                  fontFamily: 'inherit',
                }}
                showTime
                className="datePickerWeighingsInput"
                format="DD-MM-YYYY HH:mm:ss"
                value={date}
                onChange={(selectedDate: any) => setDate(selectedDate)}
                placeholder="Дата - время отвеса"
              />
              <SelectComponent
                placeholder="Тип перевозки отвеса"
                items={exportWeighingType}
                state={typeexport}
                setState={setTypeexport}
              />
              <InputComponent
                type={'default'}
                placeholder={'TS ID'}
                icon={false}
                state={tsWeighingID}
                setState={setTsWeighingID}
                autocomplete={'off'}
                regex={/^\d+$/}
                errortext={'Неккоректное значение'}
                maxLength={100}
              />
              <InputComponent
                type={'textarea'}
                placeholder={'Примечание'}
                icon={false}
                state={comment}
                setState={setComment}
                maxLength={500}
              />
            </>
          )}
        </div>
        {errortext !== '' && (
          <span className="fz14 w100" style={{ color: '#EB5757' }}>
            {errortext}
          </span>
        )}{' '}
        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
          <button
            disabled={isDisabled}
            onClick={() => addWeighings()}
            className="tac"
            style={{
              width: '100%',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
              borderRadius: '16px',
              padding: '10px 15px',
            }}
          >
            Добавить
          </button>
          <div
            onClick={() => setPopup(false)}
            className="cp tac"
            style={{
              width: '100%',
              border: '1px solid #333',
              borderRadius: '16px',
              padding: '10px 15px',
            }}
          >
            Отменить
          </div>
        </div>
      </div>
    </div>
  )
}
