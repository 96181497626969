import { CloseIcon } from "assets/icons/Close";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { putEditUserData } from "store/features/apiSlice";

interface props {
    title: string
    subtitle: string
    popup: boolean
    setPopup: Function
    selected?: any
    refreshData?: any
}

export const RestorePopup = ({ title, subtitle, popup, setPopup, selected, refreshData }: props) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden'
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    async function recoverHandler() {
        const { is_online, last_online, roles, deposit, full_name, username, ...obj2 }: any = selected;
        obj2.active = true
        obj2.user_id = obj2.id
        const { id, ...obj } = obj2
        await dispatch(putEditUserData(obj))
        refreshData()
        setPopup(false)
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>{title}</span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                <span>{subtitle}</span>
                <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                    <div
                        onClick={() => recoverHandler()}
                        className="cp tac" style={{ width: '100%', background: '#F2C94C', borderRadius: '16px', padding: '10px 15px' }}
                    >
                        Восстановить
                    </div>
                    <div
                        onClick={() => setPopup(false)}
                        className="cp tac"
                        style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                    >
                        Нет
                    </div>
                </div>
            </div>
        </div>
    )
}