import s from '../RequestAdd/index.module.scss'
import { ProfileBtn } from 'ui/ProfileBtn'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getMaterialsData,
  getObjectsData,
  putEditrequestData,
  getRequestDataById,
  getCompaniesWithFilteringData,
} from 'store/features/apiSlice'
import { SelectComponent } from 'ui/SelectComponent'
import { PlusIcon } from 'assets/icons/Plus'
import { InputComponent } from 'ui/InputComponent'
import {
  allPriority,
  allUnits,
  anotherLimit,
  bornData,
  exportType,
  parseBorn,
  parseLimit,
  parsePriority,
  parseShip,
  parseUnit,
} from 'constDatas'
import { useNavigate, useParams } from 'react-router-dom'
import { UnclickedCircle } from 'assets/icons/UnclickedCircle'
import { ClickedCircle } from 'assets/icons/ClickedCircle'
import { BreadCrumbsComponent } from 'ui/BreadCrumbs'
import { AddObjectPopup } from 'pages/RequestAdd/components/AddObjectPopup'
import { AddCompanyPopup } from 'pages/RequestAdd/components/AddCompanyPopup'

interface RequestObject {
  req_id: any
}

interface BornDataItem {
  key: string
  label: string
}

export const RequestEditPage = () => {
  const [requestId, setRequestId] = useState<number>(0)
  const [address, setAddress] = useState<string>('')
  const [addressurl, setAddressurl] = useState<string>('')
  const [clientName, setClientName] = useState<string>('')
  const [number, setNumber] = useState<string>('')
  const [volume, setVolume] = useState<string>('')
  const [min, setMin] = useState<string>('')
  const [max, setMax] = useState<string>('')
  const [price, setPrice] = useState('')
  const [comment, setComment] = useState<string>('')
  const [errorapi, setErrorapi] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isMin, setisMin] = useState<boolean>(true)
  const [popup, setPopup] = useState<boolean>(false)
  const [addCompanyPopup, setAddCompanyPopup] = useState(false)

  const [selected, setSelected] = useState<any>([])
  const [companies, setCompanies] = useState<any>({})
  const [providers, setProviders]: any = useState({})
  const [objects, setObjects] = useState<any>([])
  const [materials, setMaterials] = useState<any>([])
  const [company, setCompany] = useState<string | null>(null)

  const [obj, setObj] = useState<string | null>(null)
  const [provider, setProvider] = useState<string | null>(null)
  const [material, setMaterial] = useState<string | null>(null)
  const [priority, setPriority] = useState<string | null>(null)
  const [unit, setUnit] = useState<string | null>(null)
  const [limit, setLimit] = useState<string | null>(null)
  const [typeexport, setTypeexport] = useState<string | null>(null)
  const [born, setBorn] = useState<string | null>(null)

  const [requestDetail, setRequestData] = useState<any>({})
  const [requestLoading, setRequestLoading] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filteredBornData: BornDataItem[] = bornData.filter(
    (item: BornDataItem) => item.label !== 'Все'
  )

  const isTypeTransportationPickup = useCallback(() => {
    return (
      typeexport === 'Самовывоз безлимитный' ||
      typeexport === 'Самовывоз с лимитом' ||
      typeexport === 'Самовывоз одноразовый'
    )
  }, [typeexport])

  const isTypeTransportationPickupDisposable = useCallback(() => {
    return typeexport === 'Самовывоз с лимитом'
  }, [typeexport])

  const getFilteredPriorities = (allPriority: Array<any>) => {
    if (typeexport) {
      if (
        typeexport === 'Отдел сбыта' ||
        typeexport === 'Наёмник' ||
        typeexport === 'Отдел сбыта + Наёмник'
      ) {
        return allPriority.filter(
          (item: any) => item.label === 'Высокий' || item.label === 'Средний'
        )
      } else {
        return allPriority.filter((item: any) => item.label === 'Низкий')
      }
    }
    return allPriority
  }

  useEffect(() => {
    if (
      typeexport === 'Самовывоз безлимитный' ||
      typeexport === 'Самовывоз с лимитом' ||
      typeexport === 'Самовывоз одноразовый'
    ) {
      setPriority('Низкий')
    }
  }, [typeexport])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getRequestData = async () => {
    const obj: RequestObject = {
      req_id: id,
    }
    setRequestLoading(true)
    const requestData = await dispatch(getRequestDataById(obj))
    setRequestData(requestData?.payload?.data)
    setRequestLoading(false)
  }

  const getCompanies = useCallback(async () => {
    const obj = {
      company_function: 'Customer',
    }
    const companiesData = await dispatch(getCompaniesWithFilteringData(obj))
    setCompanies(
      companiesData?.payload?.data.filter((item: any) => item.active === true)
    )
  }, [dispatch])

  const getProviderCompanies = useCallback(async () => {
    const obj = {
      company_function: 'Provider',
    }
    const companiesData = await dispatch(getCompaniesWithFilteringData(obj))
    setProviders(
      companiesData?.payload?.data.filter((item: any) => item.active === true)
    )
  }, [dispatch])

  const getObjects = useCallback(
    async (companyname: string | undefined) => {
      const objectsData = await dispatch(getObjectsData())
      const filteredObjects =
        companyname === undefined
          ? objectsData?.payload?.data?.reverse()
          : objectsData?.payload?.data?.filter(
              (item: any) => item.company.name === companyname
            )
      setObjects(filteredObjects)
    },
    [dispatch]
  )

  const getMaterial = useCallback(async () => {
    const materialData = await dispatch(getMaterialsData())
    setMaterials(materialData?.payload?.data.reverse())
  }, [dispatch])

  useEffect(() => {
    getRequestData()
    getCompanies()
    getProviderCompanies()
    getMaterial()
  }, [])

  async function editRequest() {
    setIsDisabled(true)
    const dataobj = {
      req_id: requestId,
      deposit: parseBorn(born),
      company_customer_id: companies.filter(
        (item: any) => item.name === company
      )[0].company_id,
      company_provider_id: providers.filter(
        (item: any) => item.name === provider
      )[0].company_id,
      object_id: isTypeTransportationPickup()
        ? undefined
        : objects.filter((item: any) => item.name === obj)?.[0]?.object_id,
      material_id: materials.filter((item: any) => item.name === material)?.[0]
        ?.material_id,
      alltime_requisition_volume:
        isTypeTransportationPickup() && !isTypeTransportationPickupDisposable()
          ? undefined
          : parseFloat(volume),
      day_minimum_volume: isTypeTransportationPickup()
        ? undefined
        : parseFloat(min),
      day_maximum_volume: isTypeTransportationPickup()
        ? undefined
        : parseFloat(max),
      price: typeexport === 'Самовывоз одноразовый' ? price : undefined,
      car_number: [comment],
      shipping_type: parseShip(typeexport),
      priority: parsePriority(priority),
      fulfilling_daily_norm: isTypeTransportationPickup()
        ? undefined
        : isMin
          ? 'Min'
          : 'Max',
      residual_limit:
        isTypeTransportationPickup() && !isTypeTransportationPickupDisposable()
          ? undefined
          : parseLimit(limit),
      unit_type: parseUnit(unit),
    }
    const response = await dispatch(putEditrequestData(dataobj))
    if (response?.payload?.message === 'ok') {
      setIsDisabled(false)
      navigate('/main/requests')
    } else if (response?.error?.message.includes('409')) {
      setErrorapi('Такой объект уже существует')
      return
    } else if (response?.error?.message.includes('403')) {
      setErrorapi('Недостаточно прав для выполнения этого запроса.')
      return
    } else if (response?.error?.message.includes('401')) {
      setErrorapi('Недостаточно прав для выполнения этого запроса.')
      return
    } else {
      setErrorapi('Ошибка сервера')
      return
    }
  }

  const objectHandler = (e: any) => {
    const filteredObject = objects?.filter((item: any) => item?.name === e)
    setAddress(filteredObject?.[0]?.address)
    setAddressurl(filteredObject?.[0]?.address_link)
    setNumber(filteredObject?.[0]?.client_number)
  }

  useEffect(() => {
    if (
      company !== null &&
      (isTypeTransportationPickup() || obj !== null) &&
      (isTypeTransportationPickup() || address !== '') &&
      (isTypeTransportationPickup() || addressurl !== '') &&
      provider !== null &&
      ((isTypeTransportationPickup() &&
        !isTypeTransportationPickupDisposable()) ||
        limit !== null) &&
      born !== null &&
      ((isTypeTransportationPickup() &&
        !isTypeTransportationPickupDisposable()) ||
        /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(volume)) &&
      (typeexport !== 'Самовывоз одноразовый' || /^\d+$/.test(price)) &&
      material !== null &&
      priority !== null &&
      (isTypeTransportationPickup() ||
        /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(min)) &&
      (isTypeTransportationPickup() ||
        /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(max)) &&
      unit !== null &&
      typeexport !== null
    )
      setIsDisabled(false)
    else setIsDisabled(true)
  }, [
    company,
    obj,
    address,
    addressurl,
    number,
    provider,
    limit,
    born,
    volume,
    material,
    min,
    price,
    priority,
    max,
    unit,
    typeexport,
    comment,
    isTypeTransportationPickup,
    isTypeTransportationPickupDisposable,
  ])

  useEffect(() => {
    setSelected(requestDetail)
    setBorn(parseBorn(requestDetail?.deposit))
    setCompany(requestDetail?.company_customer?.name)
    getObjects(requestDetail?.company_customer?.name)
    setObj(requestDetail?.object?.name)
    setProvider(requestDetail?.company_provider?.name)
    setMaterial(requestDetail?.material?.name)
    setPriority(parsePriority(requestDetail?.priority))
    setVolume(requestDetail?.alltime_requisition_volume)
    setMin(requestDetail?.day_minimum_volume)
    setMax(requestDetail?.day_maximum_volume)
    setPrice(requestDetail?.price)
    setTypeexport(parseShip(requestDetail?.shipping_type))
    setisMin(requestDetail?.fulfilling_daily_norm === 'Min' ? true : false)
    setUnit(parseUnit(requestDetail?.unit_type))
    setComment(requestDetail?.car_number?.[0] || '')
    setRequestId(requestDetail?.id)
    setAddress(requestDetail?.object?.address)
    setAddressurl(requestDetail?.object?.address_link)
    setNumber(requestDetail?.object?.client_number)
    setClientName(requestDetail?.object?.client_name)
    setLimit(parseLimit(requestDetail?.residual_limit))
  }, [requestDetail])

  return (
    <>
      {popup && (
        <AddObjectPopup
          popup={popup}
          setPopup={setPopup}
          refreshData={getObjects}
          setErrorapi={setErrorapi}
        />
      )}
      {addCompanyPopup && (
        <AddCompanyPopup
          popup={addCompanyPopup}
          setPopup={setAddCompanyPopup}
          refreshData={getCompanies}
        />
      )}
      <div className={`${s.main} w100`}>
        <div className={`${s.navbar} df jcsb aic`}>
          <BreadCrumbsComponent
            backurl={`/main/requests/details/${requestDetail?.id}`}
            subtitle="Редактирование заявки"
            title={selected?.object?.company?.name}
          />
          <div className="profile-button">
            <ProfileBtn />
          </div>
        </div>
        <div className={`${s.menu} df`}>
          <div className={`${s.left} df fdc w100`}>
            <div className={`${s.info} df fdc w100`}>
              <span className={s.title}>Информация о заказчике</span>
              <div className="df aic">
                <div
                  className="df jcc aic cp"
                  style={{
                    borderRadius: '15px',
                    padding: '5px',
                    background: '#F2C94C',
                    height: '45px',
                    width: '50px',
                    marginRight: '8px',
                  }}
                  onClick={() => setAddCompanyPopup(true)}
                >
                  <PlusIcon size={23} />
                </div>
                <SelectComponent
                  placeholder="Заказчик"
                  items={companies}
                  state={company}
                  doSome={getObjects}
                  setState={setCompany}
                  loadingText={'Список подгружается...'}
                />
              </div>
              <div>
                {!isTypeTransportationPickup() && (
                  <div className="df aic">
                    <div
                      className="df jcc aic cp"
                      style={{
                        borderRadius: '15px',
                        padding: '5px',
                        background: '#F2C94C',
                        height: '45px',
                        width: '50px',
                        marginRight: '8px',
                      }}
                      onClick={() => setPopup(true)}
                    >
                      <PlusIcon size={23} />
                    </div>

                    <SelectComponent
                      placeholder="Объект"
                      items={objects}
                      state={obj}
                      doSome={objectHandler}
                      setState={setObj}
                      loadingText={'Список подгружается...'}
                    />
                  </div>
                )}
                {errorapi !== '' && (
                  <span
                    style={{
                      color: '#EB5757',
                      fontSize: '14px',
                      marginLeft: '8px',
                    }}
                  >
                    {errorapi}
                  </span>
                )}
              </div>
              {!isTypeTransportationPickup() && (
                <InputComponent
                  type={'default'}
                  placeholder={'Адрес'}
                  autocomplete={'off'}
                  state={address}
                  setState={setAddress}
                />
              )}
              {!isTypeTransportationPickup() && (
                <InputComponent
                  type={'default'}
                  placeholder={'Ссылка на адрес'}
                  autocomplete={'off'}
                  state={addressurl}
                  setState={setAddressurl}
                />
              )}
              {!isTypeTransportationPickup() && (
                <InputComponent
                  type={'default'}
                  placeholder={'Имя встречающего лица'}
                  icon={false}
                  autocomplete={'off'}
                  state={clientName}
                  setState={setClientName}
                  maxLength={50}
                />
              )}
              {!isTypeTransportationPickup() && (
                <InputComponent
                  type={'phone'}
                  placeholder={'Номер встречающего лица'}
                  autocomplete={'off'}
                  state={number}
                  setState={setNumber}
                />
              )}
            </div>
            <div className={`${s.btnsBlock}`} style={{ gap: '16px' }}>
              <button
                disabled={isDisabled}
                className="w100 df jcc aic"
                style={{
                  background: isDisabled
                    ? 'rgba(242, 201, 76, 0.58)'
                    : '#F2C94C',
                  height: '50px',
                  borderRadius: '16px',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  editRequest()
                  setIsDisabled(true)
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  height: '50px',
                  border: '1px solid #333333',
                  color: '#333333',
                  borderRadius: '16px',
                }}
                className="df jcc aic cp w100"
                onClick={() => navigate('/main/requests')}
              >
                Отменить
              </button>
            </div>
          </div>
          <div className={`${s.right} df fdc`}>
            <span className={s.title}>Данные заявки</span>
            <div className={`${s.list} df fdc`}>
              <div className={`${s.row} df fww-mobile`}>
                <SelectComponent
                  placeholder="Поставщик"
                  items={providers}
                  state={provider}
                  setState={setProvider}
                />
                {(!isTypeTransportationPickup() ||
                  isTypeTransportationPickupDisposable()) && (
                  <SelectComponent
                    placeholder="Остаточный лимит"
                    items={anotherLimit}
                    state={limit}
                    setState={setLimit}
                  />
                )}
              </div>
              <div className={`${s.row} df fww-mobile`}>
                <SelectComponent
                  placeholder="Месторождение"
                  items={filteredBornData}
                  state={born}
                  setState={setBorn}
                />
                {(!isTypeTransportationPickup() ||
                  isTypeTransportationPickupDisposable()) && (
                  <InputComponent
                    type={'default'}
                    placeholder={'Общий объем заявки'}
                    autocomplete="off"
                    state={volume}
                    setState={setVolume}
                    regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                    errortext={'Неккоректное значение'}
                    maxLength={20}
                  />
                )}
              </div>
              <div className={`${s.row} df fww-mobile`}>
                <SelectComponent
                  placeholder="Материал"
                  items={materials}
                  state={material}
                  setState={setMaterial}
                />
                {!isTypeTransportationPickup() && (
                  <InputComponent
                    type={'default'}
                    placeholder={'Дневной минимум (min)'}
                    autocomplete="off"
                    state={min}
                    setState={setMin}
                    regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                    errortext={'Неккоректное значение'}
                    maxLength={20}
                  />
                )}
              </div>
              <div className={`${s.row} df fww-mobile`}>
                <SelectComponent
                  placeholder="Приоритет"
                  items={getFilteredPriorities(allPriority)}
                  state={
                    getFilteredPriorities(allPriority).length === 1
                      ? getFilteredPriorities(allPriority)[0]
                      : priority
                  }
                  disabled={getFilteredPriorities(allPriority).length === 1}
                  setState={setPriority}
                />
                {!isTypeTransportationPickup() && (
                  <InputComponent
                    type={'default'}
                    placeholder={'Дневной максимум (max)'}
                    autocomplete="off"
                    state={max}
                    setState={setMax}
                    regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                    errortext={'Неккоректное значение'}
                    maxLength={20}
                  />
                )}
              </div>
              <div className={`${s.row} df fww-mobile`}>
                <SelectComponent
                  placeholder="Единица измерения"
                  items={allUnits}
                  state={unit}
                  disabled={true}
                  setState={setUnit}
                />
                <SelectComponent
                  disabled={true}
                  placeholder="Тип перевозки"
                  items={exportType}
                  state={typeexport}
                  setState={setTypeexport}
                />
              </div>
            </div>
            {!isTypeTransportationPickup() && (
              <div className={`${s.daystandart} df fdc`}>
                <span className={s.title}>
                  Ориентир выполнения дневной нормы
                </span>
                <div className="df" style={{ gap: '10px' }}>
                  <div className="df aic">
                    {isMin ? (
                      <div onClick={() => setisMin(false)} className="cp">
                        <UnclickedCircle />
                      </div>
                    ) : (
                      <div>
                        <ClickedCircle />
                      </div>
                    )}
                    <span style={{ marginBottom: '3px', marginLeft: '5px' }}>
                      Максимум (max)
                    </span>
                  </div>
                  <div className="df aic">
                    {isMin ? (
                      <div>
                        <ClickedCircle />
                      </div>
                    ) : (
                      <div onClick={() => setisMin(true)} className="cp">
                        <UnclickedCircle />
                      </div>
                    )}
                    <span style={{ marginBottom: '3px', marginLeft: '5px' }}>
                      Минимум (min)
                    </span>
                  </div>
                </div>
              </div>
            )}
            {typeexport === 'Самовывоз одноразовый' && (
              <InputComponent
                type={'default'}
                placeholder={'Цена'}
                autocomplete="off"
                state={price}
                setState={setPrice}
                regex={/^\d+$/}
                errortext={'Неккоректное значение'}
                maxLength={20}
              />
            )}
            <InputComponent
              type={'textarea'}
              placeholder={'Примечание'}
              icon={false}
              state={comment}
              setState={setComment}
              maxLength={500}
            />
          </div>
          {isMobile && (
            <div className="df fdc w100" style={{ gap: '16px' }}>
              <button
                disabled={isDisabled}
                className="w100 df jcc aic"
                style={{
                  background: isDisabled
                    ? 'rgba(242, 201, 76, 0.58)'
                    : '#F2C94C',
                  height: '50px',
                  borderRadius: '16px',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  editRequest()
                  setIsDisabled(true)
                }}
              >
                Сохранить
              </button>
              <button
                style={{
                  height: '50px',
                  border: '1px solid #333333',
                  color: '#333333',
                  borderRadius: '16px',
                }}
                className="df jcc aic cp w100"
                onClick={() => navigate('/main/requests')}
              >
                Отменить
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
