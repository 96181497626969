import { ArrowLeft } from "assets/icons/ArrowLeft"
import { Link } from "react-router-dom"

interface Props {
  backurl: string
  title: string
  subtitle: string
}

export const BreadCrumbsComponent = ({ backurl, title, subtitle }: Props) => {
  return (
    <div style={{ height: '100%' }} className="df aic">
      <Link to={backurl}>
        <ArrowLeft />
      </Link>
      <div className="df fdc" style={{ marginLeft: '15px' }}>
        <Link to={backurl} style={{ color: '#828282' }}>{subtitle}</Link>
        <span style={{ fontWeight: '700', fontSize: "22px", color: '#333333', marginTop: '5px' }}>{title}</span>
      </div>
    </div>
  )
}