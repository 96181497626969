import { CloseIcon } from "assets/icons/Close";
import { companyTypes, parseCompanyType, companyFunction, parseCompanyFunction } from "constDatas";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { putEditCompaniesData } from "store/features/apiSlice";
import { InputComponent } from "ui/InputComponent";
import { SelectComponent } from "ui/SelectComponent";

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    refreshData: Function
    refreshData2: Function
    hide: boolean
}

export const EditCompanyPopup = ({ selected, popup, setPopup, refreshData, refreshData2, hide }: props) => {
    const [name, setName] = useState(selected ? selected?.name : '');
    const [type, setType]: any | null = useState(selected ? parseCompanyType(selected?.company_type) : null);
    const [compFunction, setCompFunction]:  any | null = useState(selected ? parseCompanyFunction(selected?.company_function) : null);
    const [contact, setContact] = useState(selected ? selected?.representative_contacts : '');
    const [isDisabled, setIsDisabled] = useState(true);
    const [want, setWant] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [apierror, setApierror] = useState('');

    const dispatch = useDispatch()

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    useEffect(() => {
        if (name !== '' && type !== null && compFunction !== null) {
            setIsDisabled(false)
        } else setIsDisabled(true)
    }, [type, name, compFunction]);

    async function deleteCompany() {
        setIsDelete(true)
        const { company_type, representative_contacts, name, ...obj } = selected
        obj.active = false
        const response = await dispatch(putEditCompaniesData(obj))
        if (response?.payload?.message === 'ok') {
            hide ? refreshData() : refreshData2()
            setPopup(false)
            setIsDelete(false)
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        }  else {
            setApierror('Ошибка сервера')
            return
        }
    }

    async function editCompany() {
        const { company_type, company_function, representative_contacts, ...obj } = selected
        obj.name = name
        obj.company_type = parseCompanyType(type)
        obj.company_function = parseCompanyFunction(compFunction)
        obj.representative_contacts = contact
        const response = await dispatch(putEditCompaniesData(obj))
        if (response?.payload?.message === 'ok') {
            hide ? refreshData() : refreshData2()
            setName('')
            setType('')
            setContact('')
            setPopup(false)
            setWant(false)
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setApierror('Ошибка сервера')
            return
        }
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            {!want ? (
                <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                    <div className="df jcsb aic">
                        <span style={{ fontSize: '20px', marginRight: '30px' }}>Редактировать компанию</span>
                        <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                    </div>
                    <div className="df fdc" style={{ gap: '15px' }}>
                        <InputComponent
                            type={"default"}
                            icon={false}
                            placeholder={'Наименование'}
                            state={name}
                            setState={setName}
                            width={"100%"}
                            autocomplete={"off"}
                            maxLength={30}
                        />
                        <SelectComponent
                            placeholder={"Тип компании"}
                            items={companyTypes}
                            state={type}
                            setState={setType}
                            width={"100%"}
                        />
                        <SelectComponent
                            placeholder={"Функция компании"}
                            items={companyFunction}
                            state={compFunction}
                            setState={setCompFunction}
                            width={"100%"}
                        />
                        <InputComponent
                            type={"phone"}
                            icon={false}
                            placeholder={'Номер представителя'}
                            state={contact}
                            setState={setContact}
                            width={"100%"}
                            autocomplete={"off"}
                        />
                    </div>
                    {apierror !== '' && <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{apierror}</span>}
                    <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                        <button disabled={isDisabled}
                            onClick={() => editCompany()}
                            className="tac" style=
                            {{
                                width: '100%',
                                cursor: isDisabled ? "not-allowed" : "pointer",
                                background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                                borderRadius: '16px',
                                padding: '10px 15px',
                            }}
                        >
                            Сохранить
                        </button>
                        {selected?.active &&
                        <button
                            onClick={() => setWant(true)}
                            className="cp tac"
                            style={{ width: '100%', border: '1px solid #D32929', borderRadius: '16px', padding: '10px 15px', color: '#D32929' }}
                        >
                            Удалить
                        </button>
                        }
                    </div>
                </div>
            ) : (
                <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                    <div className="df jcsb aic">
                        <span style={{ fontSize: '20px', marginRight: '30px' }}>Удалить компанию?</span>
                        <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                    </div>
                    <span>Подтвердите удаление компании.</span>
                    <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                        <button
                            disabled={isDelete}
                            onClick={() => deleteCompany()}
                            className="tac" style={{
                                width: '100%',
                                border: '1px solid #D32929',
                                borderRadius: '16px',
                                padding: '10px 15px',
                                color: '#D32929'
                            }}
                        >
                            Удалить
                        </button>
                        <button
                            onClick={() => setWant(false)}
                            className="cp tac"
                            style={{
                                width: '100%', border: '1px solid #333',
                                borderRadius: '16px',
                                padding: '10px 15px',
                            }}
                        >
                            Нет
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}