interface props {
    size?: number
    color?: string
}

export const OnlineDot = ({ size = 8, color = '#F2C94C' }: props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 8 8" fill="none">
            <circle cx="4" cy="4" r="4" fill={color} />
        </svg>
    )
}