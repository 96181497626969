import ReactDOMServer from 'react-dom/server'

import { ProfileBtn } from 'ui/ProfileBtn'
import { BreadCrumbsComponent } from 'ui/BreadCrumbs'
import { useCallback, useEffect, useState } from 'react'
import {
  parseDate,
  parseFulfilling,
  parsePriority,
  parseShip,
  parseStatus,
  parseUnit,
  bornData,
  parseBorn,
} from 'constDatas'
import { ProgressBar } from 'ui/ProgressBar'
import { useDispatch } from 'react-redux'
import {
  putEditrequestStatusData,
  getRequestDataById,
  getElectronReportData,
  getWeighingByIDData,
} from 'store/features/apiSlice'
import { PlusIcon } from 'assets/icons/Plus'
import { useNavigate } from 'react-router-dom'
import { AddWeighingsPopup } from './components/AddWeighingsPopup'
import { EditWeighingsPopup } from './components/EditWeighingsPopup'
import { RetieWeighingsPopup } from './components/RetieWeighingsPopup'
import { ReturnWeighingsPopup } from './components/ReturnWeighingsPopup'
import { CancelWeighingsPopup } from './components/CancelWeighingsPopup'
import { EditHistoryWeighingsPopup } from './components/EditHistoryWeighingsPopup'
import { RSComponent } from './components/ElectronReportFile'

import { PrinterIcon } from 'assets/icons/Printer'
import { RequestPriorityStar } from 'assets/icons/RequestPriorityStar'
import { parseCompanyType } from 'constDatas'
import { useParams } from 'react-router-dom'

import { CancelIcon } from 'assets/icons/CancelIcon'
import { EditIcon } from 'assets/icons/EditIcon'
import { ClockCounterIcon } from 'assets/icons/ClockCounterIcon'

import s from './index.module.scss'
import config from 'config.json'

let mammoth = require('mammoth/mammoth.browser')

interface FinishObject {
  req_id: number
  status: string
}

interface RequestObject {
  req_id: any
}

export const RequestDetails = () => {
  const { id } = useParams<{ id: string }>()
  const [requestDetail, setRequestData] = useState<any>({})
  const [requestLoading, setRequestLoading] = useState(false)
  const [weighings, setWeighings] = useState<any>({})
  const [weighingsLoading, setWeighingsLoading] = useState(false)
  const [selectedWeighing, setSelectedWeighing] = useState<any>({})
  const [addpopup, setAddpopup] = useState(false)
  const [editpopup, setEditpopup] = useState(false)
  const [retiepopup, setRetiepopup] = useState(false)
  const [returnpopup, setReturnpopup] = useState(false)
  const [cancelpopup, setCancelpopup] = useState(false)
  const [edithistorypopup, setEditHistorypopup] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [successText, setSuccessText] = useState('')
  const [errortext, setErrortext] = useState('')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setWeighings(requestDetail?.weighings)
  }, [requestDetail])

  const checkDailyNormStatus = (status: string) => {
    if (status === 'OpenTodayNotFinished') {
      return 'Не выполнено'
    } else if (status === 'OpenTodayFinished') {
      return 'Выполнено'
    } else if (status === 'Finished') {
      return 'Выполнено'
    } else if (status === 'Deactivated') {
      return '-'
    }
  }

  const checkAllWeightNormStatus = (status: string) => {
    if (status === 'OpenTodayNotFinished') {
      return 'Не выполнено'
    } else if (status === 'OpenTodayFinished') {
      return 'Не выполнено'
    } else if (status === 'Finished') {
      return 'Выполнено'
    } else if (status === 'Deactivated') {
      return '-'
    }
  }

  const getRequestData = useCallback(async () => {
    const obj: RequestObject = {
      req_id: id,
    }
    setRequestLoading(true)
    const requestData = await dispatch(getRequestDataById(obj))
    setRequestData(requestData?.payload?.data)
    setRequestLoading(false)
  }, [dispatch, id])

  async function downloadElectronReport(weigh_id: any) {
    const response = await dispatch(getElectronReportData(weigh_id))
    const weighResponse = await dispatch(getWeighingByIDData(weigh_id))

    if (response?.payload?.message === 'ok' && response?.payload?.data?.file) {
      // const base64Data = response.payload.data.file;

      // // Декодируем данные из base64
      // const byteCharacters = atob(base64Data);

      // // Преобразуем данные в массив байт
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }

      // // Создаем Uint8Array и ArrayBuffer
      // const byteArray = new Uint8Array(byteNumbers);
      // const arrayBuffer = byteArray.buffer;

      // const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

      // const downloadLink = document.createElement('a');
      // downloadLink.href = window.URL.createObjectURL(blob);
      // downloadLink.download = `отвес_${weigh_id}`;
      // downloadLink.click();

      const htmlContent = ReactDOMServer.renderToString(
        <RSComponent weighData={weighResponse?.payload?.data} />
      )

      const newWindow = window.open('', '_blank')
      if (newWindow) {
        newWindow.document.write(htmlContent)
        newWindow.document.write(
          '<style>body { font-size: 14px; } ul { list-style-type: none; padding: 0; margin: 0; } .df { display: flex; } .fdr { flex-direction: row; } .jcsa { justify-content: space-around; } hr { border: 1px dashed #ccc; margin: 10px 0; } .logo { width: 100px; height: 35px; } .qr { width: 60px; height: 60px; } .firstBlock { margin-top: 50px; } .secondBlock { margin-top: 50px; } .rightBlock { margin-top: 35px; }</style>'
        ) // Пример встроенных стилей
        newWindow.document.close()
        newWindow.onafterprint = function () {
          newWindow.close()
        }

        setTimeout(() => {
          newWindow.print()
        }, 100)
      }

      // if (printWindow) {
      //   printWindow.document.title = 'Электронный отвес';
      //   printWindow.document.head.innerHTML = document.head.innerHTML;

      //   printWindow.onafterprint = function () {
      //     printWindow.close();
      //   };

      //   printWindow.print();
      // }
    } else if (response?.error?.message.includes('403')) {
      setErrortext('Недостаточно прав для выполнения этого запроса.')
      return
    } else if (response?.error?.message.includes('401')) {
      setErrortext('Недостаточно прав для выполнения этого запроса.')
      return
    } else {
      setErrortext('Ошибка при загрузке электронного отвеса')
      return
    }
  }

  const finishHandler = async () => {
    const obj: FinishObject = {
      req_id: requestDetail?.id,
      status: 'Finished',
    }
    const requestData = await dispatch(putEditrequestStatusData(obj))
    if (requestData?.payload?.message === 'ok') {
      setSuccessText('Вы успешно закрыли заявку досрочно')
      setTimeout(() => {
        setSuccessText('')
      }, 5000)
    }
    getRequestData()
    setIsDisabled(false)
  }

  const offHandler = async () => {
    const obj: FinishObject = {
      req_id: requestDetail?.id,
      status: 'Deactivated',
    }
    const requestData = await dispatch(putEditrequestStatusData(obj))
    if (requestData?.payload?.message === 'ok')
      setSuccessText('Вы успешно деактивировали заявку')
    setIsDisabled(false)
    getRequestData()
  }

  const isUnlimitedPickupAndOpen = () => {
    if (requestDetail?.shipping_type === 'UnlimitedPickup') {
      if (requestDetail?.status === 'OpenTodayNotFinished') {
        return true
      }
    }
    return false
  }

  const isUnlimitedPickupOrIsOnetimePickipAndFinished = () => {
    if (
      requestDetail?.shipping_type === 'UnlimitedPickup' ||
      requestDetail?.shipping_type === 'OnetimePickup'
    ) {
      if (
        requestDetail?.status === 'Finished' ||
        requestDetail?.status === 'Deactivated'
      ) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    getRequestData()
  }, [getRequestData])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      getRequestData()
    }, config.get_request_details_in_rd)

    return () => clearInterval(intervalId)
  }, [dispatch, getRequestData])

  return (
    <>
      {addpopup && (
        <AddWeighingsPopup
          popup={addpopup}
          setPopup={setAddpopup}
          refreshData={getRequestData}
          selected={requestDetail}
        />
      )}
      {editpopup && (
        <EditWeighingsPopup
          selected={requestDetail}
          popup={editpopup}
          setPopup={setEditpopup}
          selectedWeighing={selectedWeighing}
          refreshData={getRequestData}
        />
      )}
      {retiepopup && (
        <RetieWeighingsPopup
          selected={selectedWeighing}
          popup={retiepopup}
          setPopup={setRetiepopup}
          refreshData={getRequestData}
        />
      )}
      {returnpopup && (
        <ReturnWeighingsPopup
          selected={selectedWeighing}
          popup={returnpopup}
          setPopup={setReturnpopup}
          refreshData={getRequestData}
        />
      )}
      {cancelpopup && (
        <CancelWeighingsPopup
          selected={selectedWeighing}
          popup={cancelpopup}
          setPopup={setCancelpopup}
          refreshData={getRequestData}
        />
      )}
      {edithistorypopup && (
        <EditHistoryWeighingsPopup
          selected={selectedWeighing}
          popup={edithistorypopup}
          setPopup={setEditHistorypopup}
          refreshData={getRequestData}
        />
      )}
      <div className="main w100">
        <div className={`${s.navbar} df jcsb aic fww-mobile`}>
          <BreadCrumbsComponent
            backurl="/main/requests"
            subtitle="Журнал заявок"
            title={`${parseCompanyType(
              requestDetail?.company_customer?.company_type
            )} "${requestDetail?.company_customer
              ?.name}" - ID: ${requestDetail?.id}`}
          />
          <div className="df aic fww-mobile" style={{ gap: '20px' }}>
            <div
              className={`${s.add} aic usa cp`}
              style={{ cursor: 'pointer' }}
              onClick={() => setAddpopup(true)}
            >
              <PlusIcon />
              <span style={{ marginLeft: '10px' }}>Добавить отвес</span>
            </div>
            <div className="profile-button">
              <ProfileBtn />
            </div>
          </div>
        </div>
        <div className={`${s.lists} df w100 fww-mobile`}>
          <div className={`${s.left} w100`}>
            {/* ЗАКАЗЧИК И ПОСТАВЩИК */}
            <div className={s.row}>
              <div className={s.rowleft}>
                <span>Заказчик</span>
                <span className={s.bold}>
                  {`${parseCompanyType(
                    requestDetail?.company_customer?.company_type
                  )} "${requestDetail?.company_customer?.name}"`}
                </span>
              </div>
              <div className={s.rowright}>
                <span>Поставщик</span>
                <span className={s.bold}>
                  {parseCompanyType(
                    requestDetail?.company_provider?.company_type
                  )}{' '}
                  "{requestDetail?.company_provider?.name}"
                </span>
              </div>
            </div>

            {/* ОБЪЕКТ И МАТЕРИАЛ */}
            <div className={s.row}>
              <div className={s.rowleft}>
                <span>Объект</span>
                <span>{requestDetail?.object?.name}</span>
              </div>
              <div className={s.rowright}>
                <span>Материал</span>
                <span>{requestDetail?.material?.name}</span>
              </div>
            </div>

            {/* ТИП ПЕРЕВОЗКИ И ПРИОРИТЕТ */}
            <div className={s.row}>
              <div className={s.rowleft}>
                <span>Тип перевозки</span>
                <span>{parseShip(requestDetail?.shipping_type)}</span>
              </div>
              <div className={s.rowright}>
                <span>Приоритет</span>
                <div style={{ gap: '8px' }} className="df aic">
                  {`${parsePriority(requestDetail?.priority)}` === 'Высокий' ? (
                    <div>
                      <RequestPriorityStar />
                      <RequestPriorityStar />
                      <RequestPriorityStar />
                    </div>
                  ) : `${parsePriority(requestDetail?.priority)}` ===
                    'Средний' ? (
                    <div>
                      <RequestPriorityStar />
                      <RequestPriorityStar />
                    </div>
                  ) : (
                    <div>
                      <RequestPriorityStar />
                    </div>
                  )}
                  <span>{parsePriority(requestDetail?.priority)}</span>
                </div>
              </div>
            </div>

            {/* ЕДИНИЦА ИЗМЕРЕНИЙ И ОРИЕНТИР ВДН */}
            <div className={s.row}>
              <div className={s.rowleft}>
                <span>Единица измерений</span>
                <span>
                  {parseUnit(requestDetail?.unit_type)?.toUpperCase()}
                </span>
              </div>
              <div className={s.rowright}>
                <span>Ориентир ВДН</span>
                <span>
                  {parseFulfilling(requestDetail?.fulfilling_daily_norm)}
                </span>
              </div>
            </div>

            {/* ПРИМЕЧАНИЕ И СТАТУС */}
            <div className={s.row}>
              <div className={s.rowleft}>
                <span>Примечание</span>
                <span>{requestDetail?.car_number?.[0]}</span>
              </div>
              <div className={s.rowright}>
                <span>Статус</span>
                <span>{parseStatus(requestDetail?.status)}</span>
              </div>
            </div>

            {/* МЕСТОРОЖДЕНИЕ */}
            <div className={s.row}>
              <div className={s.rowleft}>
                <span>Месторождение</span>
                <span>{parseBorn(requestDetail?.deposit)}</span>
              </div>
              <div className={s.rowright}></div>
            </div>

            {/* ЦЕНА И СУММА */}
            {requestDetail?.shipping_type === 'OnetimePickup' && (
              <div className={s.row}>
                <div className={s.rowleft}>
                  <span>Цена</span>
                  <span>{requestDetail?.price || '-'}</span>
                </div>
                <div className={s.rowright}>
                  <span>Сумма</span>
                  <span>{requestDetail?.amount || '-'}</span>
                </div>
              </div>
            )}

            {/* ДНЕВНОЙ МИНИУМ И ДНЕВНОЙ МАКСИМУМ */}
            {requestDetail?.shipping_type !== 'OnetimePickup' &&
              requestDetail?.shipping_type !== 'UnlimitedPickup' &&
              requestDetail?.shipping_type !== 'LimitedPickup' && (
                <div className={s.row}>
                  <div className={s.rowleft}>
                    <span>Дневной минимум (min), т</span>
                    <span>{requestDetail?.day_minimum_volume}</span>
                  </div>
                  <div className={s.rowright}>
                    <span>Дневной максимум (max), т</span>
                    <span>{requestDetail?.day_maximum_volume}</span>
                  </div>
                </div>
              )}

            {/* СТАТУС ВЫПОЛНЕНИЯ ДНЕВНОЙ НОРМЫ */}
            {requestDetail?.shipping_type !== 'OnetimePickup' &&
              requestDetail?.shipping_type !== 'UnlimitedPickup' &&
              requestDetail?.shipping_type !== 'LimitedPickup' && (
                <div className={s.onerow} style={{ marginBottom: '10px' }}>
                  <div className={s.onerowleft}>
                    <span>Статус выполнения дневной нормы</span>
                    <span>{checkDailyNormStatus(requestDetail?.status)}</span>
                  </div>
                  <div className={s.onerowright}>
                    <ProgressBar
                      min={
                        requestDetail?.today_weight
                          ? requestDetail?.today_weight
                          : 0
                      }
                      max={
                        requestDetail?.fulfilling_daily_norm === 'Max'
                          ? requestDetail?.day_maximum_volume
                          : requestDetail?.day_minimum_volume
                      }
                    />
                  </div>
                </div>
              )}

            {/* ОБЩИЙ ОБЬЕМ */}
            {requestDetail?.shipping_type !== 'OnetimePickup' &&
              requestDetail?.shipping_type !== 'UnlimitedPickup' && (
                <div className={s.onerow} style={{ marginBottom: '10px' }}>
                  <div className={s.onerowleft}>
                    <span>Общий объем, т</span>
                    <span>
                      {checkAllWeightNormStatus(requestDetail?.status)}
                    </span>
                  </div>
                  <div className={s.onerowright}>
                    <ProgressBar
                      min={
                        requestDetail?.alltime_weight
                          ? requestDetail?.alltime_weight
                          : 0
                      }
                      max={
                        requestDetail?.alltime_requisition_volume
                          ? requestDetail?.alltime_requisition_volume
                          : 0
                      }
                    />
                  </div>
                </div>
              )}

            {/* ОБЩИЙ ОБЬЕМ И ОБЪЕМ ЗА ДЕНЬ ДЛЯ БЕЗЛИМТНЫХ ЗАЯВОК С ОТКРЫТЫМ СТАТУСОМ */}
            {isUnlimitedPickupAndOpen() && (
              <div className={s.row}>
                <div className={s.rowleft}>
                  <span>Отгружено за сегодня</span>
                  <span>{requestDetail?.today_weight}</span>
                </div>
                <div className={s.rowright}>
                  <span>Отгружено за все время</span>
                  <span>{requestDetail?.alltime_weight}</span>
                </div>
              </div>
            )}

            {/* ОБЩИЙ ОБЬЕМ ДЛЯ БЕЗЛИМТНЫХ И ОДНОРАЗОВЫХ ЗАЯВОК С ЗАВЕРШЕННЫМ СТАТУСОМ */}
            {isUnlimitedPickupOrIsOnetimePickipAndFinished() && (
              <div className={s.row}>
                <div className={s.rowleft}></div>
                <div className={s.rowright}>
                  <span>Отгружено за все время</span>
                  <span>{requestDetail?.alltime_weight}</span>
                </div>
              </div>
            )}

            {/* ДАТА СОЗДАНИЯ И ДАТА ЗАВЕРШЕНИЯ */}
            <div className={s.row}>
              <div className={s.rowleft}>
                <span>Создано</span>
                <span>{requestDetail?.user?.full_name}</span>
              </div>
              <div className={s.rowright}>
                <span>Дата создания</span>
                <span>{parseDate(requestDetail?.created_on)}</span>
              </div>

              <div className={s.rowright}>
                <span>Дата завершения</span>
                <span>{parseDate(requestDetail?.finished_on)}</span>
              </div>
            </div>

            <div className={`${s.btns} df usa`}>
              <button
                disabled={isDisabled}
                className={`${s.edit} hover`}
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                onClick={() =>
                  navigate(`/main/requests/edit/${requestDetail?.id}`)
                }
              >
                Редактировать
              </button>
              <button
                disabled={isDisabled}
                className={`${s.close} hover`}
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  finishHandler()
                  setIsDisabled(true)
                }}
              >
                Закрыть досрочно
              </button>
              <button
                disabled={isDisabled}
                className={`${s.off} hover`}
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  offHandler()
                  setIsDisabled(true)
                }}
              >
                Отключить
              </button>
            </div>
            {errortext !== '' && (
              <span
                style={{
                  color: '#EB5757',
                  fontSize: '14px',
                  marginLeft: '8px',
                }}
              >
                {errortext}
              </span>
            )}
            {successText !== '' && (
              <div className="df jcc aic" style={{ padding: '10px' }}>
                <span style={{ color: '#02cf00' }}>{successText}</span>
              </div>
            )}
          </div>
          <div className={`${s.rightbox} w100`}>
            <span className={s.title}>ОТВЕСЫ ПО ЗАЯВКЕ</span>
            <div className={s.right}>
              {requestDetail?.weighings?.length > 0 ? (
                requestDetail.weighings.map((weighing: any) => (
                  <div className={`${s.item} df fdc`} key={weighing.id}>
                    <div className="df jcsb aic">
                      <span>{`ID: ${weighing.id}`}</span>
                      <div className="df aic" style={{ gap: '16px' }}>
                        <span>
                          Создан:{' '}
                          {weighing?.created_by === 'web_app'
                            ? 'веб-приложение'
                            : 'утилита'}
                        </span>
                        <span>{`${weighing.rq_date_create}`}</span>
                        <span
                          className={s.status}
                          style={{
                            color:
                              weighing.status === 'Canceled'
                                ? '#D32929'
                                : '#333333',
                          }}
                        >
                          {parseStatus(weighing.status)}
                        </span>
                        <div
                          className="df aic cp hover"
                          onClick={() => {
                            setEditHistorypopup(true)
                            setSelectedWeighing(weighing)
                          }}
                        >
                          <ClockCounterIcon />
                        </div>
                      </div>
                    </div>
                    <div className={`${s.itemrow2} df aic fww`}>
                      {weighing.cubic_capacity ? (
                        <div className="df fdc">
                          <span>Кубатура:</span>
                          <span>{weighing.cubic_capacity}</span>
                        </div>
                      ) : (
                        <>
                          <div className="df fdc">
                            <span>Нетто:</span>
                            <span>
                              {weighing.weight_netto
                                ? weighing.weight_netto
                                : '-'}
                            </span>
                          </div>
                          <div className="df fdc">
                            <span>Тара:</span>
                            <span>
                              {weighing.weight_tare
                                ? weighing.weight_tare
                                : '-'}
                            </span>
                          </div>
                          <div className="df fdc">
                            <span>Брутто:</span>
                            <span>
                              {weighing.weight_gross
                                ? weighing.weight_gross
                                : '-'}
                            </span>
                          </div>
                        </>
                      )}
                      <div className="df fdc">
                        <span>Гос.номер:</span>
                        <span>
                          {weighing.car_number ? weighing.car_number : '-'}
                        </span>
                      </div>
                      {weighing?.shipping_weighing_type && (
                        <div className="df fdc">
                          <span>Тип перевозки отвеса:</span>
                          <span>
                            {parseShip(weighing?.shipping_weighing_type)}
                          </span>
                        </div>
                      )}
                      {weighing?.ts_weighing_id && (
                        <div className="df fdc">
                          <span>TS ID:</span>
                          <span>{weighing?.ts_weighing_id}</span>
                        </div>
                      )}
                    </div>
                    <div className={`${s.itemrow} df aic usa jcfs`}>
                      {weighing?.status !== 'Canceled' ? (
                        <>
                          <button
                            className={`${s.btn} df jcc aic hover`}
                            onClick={() => {
                              setEditpopup(true)
                              setSelectedWeighing(weighing)
                            }}
                          >
                            {isMobile && <EditIcon />}
                            {!isMobile && 'Редактировать'}
                          </button>
                          <button
                            className={`${s.btn} df jcc aic hover`}
                            onClick={() => {
                              setRetiepopup(true)
                              setSelectedWeighing(weighing)
                            }}
                          >
                            Перепривязать
                          </button>
                          <button
                            className={`${s.btn} df jcc aic hover`}
                            onClick={() => {
                              setCancelpopup(true)
                              setSelectedWeighing(weighing)
                            }}
                          >
                            {isMobile && <CancelIcon />}
                            {!isMobile && 'Отменить'}
                          </button>
                          <button
                            className={`${s.btn} df jcc aic hover`}
                            onClick={() => {
                              downloadElectronReport(weighing.id)
                            }}
                          >
                            <PrinterIcon />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className={`${s.btn} df jcc aic hover`}
                            onClick={() => {
                              setReturnpopup(true)
                              setSelectedWeighing(weighing)
                            }}
                          >
                            Восстановить
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className={s.noone}>
                  <span>Нету ни одного отвеса</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMobile &&
        !addpopup &&
        !editpopup &&
        !retiepopup &&
        !returnpopup &&
        !edithistorypopup &&
        !cancelpopup && (
          <div
            className={`${s.mobileaddweight} cp df jcc aic`}
            onClick={() => setAddpopup(true)}
          >
            <PlusIcon color={'black'} />
            <span className="fw700 fz16">Добавить отвес</span>
          </div>
        )}
    </>
  )
}
