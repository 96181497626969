interface Props {
  height?: number
  width?: number
}

export const StarCircle = ({ height = 15, width = 15 }: Props) => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8ZM7.50001 4C7.69387 4 7.87026 4.11206 7.95263 4.28755L8.92087 6.35035L11.0755 6.67933C11.2607 6.70761 11.4146 6.83713 11.4741 7.01485C11.5336 7.19257 11.4888 7.38864 11.3579 7.52278L9.78412 9.13573L10.1567 11.4195C10.1875 11.6085 10.1077 11.7985 9.95121 11.9087C9.79468 12.019 9.58892 12.0302 9.42134 11.9376L7.50001 10.8755L5.57868 11.9376C5.41111 12.0302 5.20534 12.019 5.04881 11.9087C4.89228 11.7985 4.81248 11.6085 4.84331 11.4195L5.2159 9.13573L3.64214 7.52278C3.51126 7.38864 3.46638 7.19257 3.52588 7.01485C3.58539 6.83713 3.73928 6.70761 3.92454 6.67933L6.07916 6.35035L7.04739 4.28755C7.12976 4.11206 7.30615 4 7.50001 4Z" fill="#F2994A"/>
  </svg>
  )
}
