import { CloseIcon } from "assets/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { putEditMaterialsData } from "store/features/apiSlice";
import { InputComponent } from "ui/InputComponent";

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    refreshData: Function
    refreshOffData: Function
    hide: boolean
}

export const EditMaterialsPopup = ({ selected, popup, setPopup, refreshData, refreshOffData, hide }: props) => {
    const [name, setName] = useState(selected ? selected?.name : '');
    const [density, setDensity] = useState(selected ? selected?.density : '');
    const [want, setWant] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [errorapi, setErrorapi] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    const dispatch = useDispatch()

    useEffect(() => {
        if (name !== '' && /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(density)) setIsDisabled(false)
        else setIsDisabled(true)
    }, [name, density]);

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    async function deleteMaterial() {
        setIsDelete(true)
        const { density, name, ...obj } = selected
        obj.active = false
        const response = await dispatch(putEditMaterialsData(obj))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                hide ? refreshData() : refreshOffData()
            }, 500);
            setPopup(false)
            setIsDelete(false)
        } else if (response?.error?.message.includes('403')) {
            setErrorapi('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setErrorapi('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setErrorapi('Ошибка сервера')
            return
        }
    }

    async function editMaterial() {
        setIsDisabled(true)
        if (isDisabled) return
        const { active, ...obj } = selected
        obj.density = density
        obj.name = name
        const response = await dispatch(putEditMaterialsData(obj))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                hide ? refreshData() : refreshOffData()
            }, 500);
            setPopup(false)
            setIsDisabled(false)
        } else if (response?.error?.message.includes('403')) {
            setErrorapi('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setErrorapi('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setErrorapi('Ошибка сервера')
            return
        }
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>
                        {want ? 'Удалить материал?' : 'Редактировать материал'}
                    </span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                {!want ? (
                    <>
                        <div className="df fdc" style={{ gap: '15px' }}>
                            <InputComponent
                                type={"default"}
                                icon={false}
                                placeholder={'Наименование'}
                                state={name}
                                setState={setName}
                                width={"100%"}
                                autocomplete={"off"}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        editMaterial();
                                    }
                                }}
                                maxLength={40}
                            />
                            <InputComponent
                                type={"default"}
                                icon={false}
                                placeholder={'Введите плотность (т/м3)*'}
                                state={density}
                                setState={setDensity}
                                regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                                errortext={'Некорректное значение'}
                                maxLength={20}
                                width={"100%"}
                                autocomplete={"off"}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        editMaterial();
                                    }
                                }}
                            />
                        </div>
                        {errorapi !== '' && <span style={{ color: 'rgb(235, 87, 87)', marginLeft: '10px', fontSize: '14px' }}>{errorapi}</span>}
                        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                            <button disabled={isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editMaterial();
                                }}
                                className="tac"
                                style={{
                                    width: '100%',
                                    cursor: isDisabled ? "not-allowed" : "pointer",
                                    background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                }}
                            >
                                Сохранить
                            </button>
                            {selected?.active && 
                            <button
                                onClick={() => setWant(true)}
                                className="cp tac"
                                style={{ width: '100%', border: '1px solid #D32929', borderRadius: '16px', padding: '10px 15px', color: '#D32929' }}
                            >
                                Удалить
                            </button>
                            }
                        </div>
                    </>
                ) : (
                    <>
                        <span>Подтвердите удаление материала</span>
                        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                            <button
                                disabled={isDelete}
                                onClick={() => deleteMaterial()}
                                className="tac"
                                style={{
                                    width: '100%',
                                    cursor: isDisabled ? "not-allowed" : "pointer",
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                    color: 'rgb(211, 41, 41)',
                                    border: '1px solid rgb(211, 41, 41)',
                                }}
                            >
                                Удалить
                            </button>
                            <div
                                onClick={() => setWant(false)}
                                className="cp tac"
                                style={{
                                    width: '100%',
                                    border: '1px solid rgb(51, 51, 51)',
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                    color: 'rgb(51, 51, 51)',
                                }}
                            >
                                Нет
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}