import { CloseIcon } from "assets/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { retieWeighingsData } from "store/features/apiSlice";
import { InputComponent } from "ui/InputComponent";

import config from 'config.json'

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    refreshData: Function
}

export const RetieWeighingsPopup = ({ selected, popup, setPopup, refreshData }: props) => {
    const [newRequestNumber, setNewRequestNumber] = useState('');
    const [desc, setDesc] = useState('');
    const [errortext, setErrortext] = useState('');
    const [userRole, setUserRole] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    const dispatch = useDispatch()

    useEffect(() => {
        if (newRequestNumber !== '' && desc !== '') setIsDisabled(false)
        else setIsDisabled(true)
    }, [newRequestNumber, desc]);

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    useEffect(() => {
        const userRole = localStorage.getItem('userrole');
    
        if (userRole) {
          setUserRole(userRole);
        }
    }, [userRole])

    async function retieWeighing() {
        setIsDisabled(true)
        if (isDisabled) return
        const obj = {
            weighing_id: selected?.id,
            new_req_id: parseInt(newRequestNumber),
            desc: desc
        }
        if (config.retie_weigh.includes(userRole)) {
            const response = await dispatch(retieWeighingsData(obj))
            if (response?.payload?.message === 'ok') {
                setTimeout(() => {
                    refreshData()
                }, 500);
                setPopup(false)
                setIsDisabled(false)
            } else if (response?.error?.message.includes('403')) {
                setErrortext('Недостаточно прав для выполнения этого запроса.')
                return
            } else if (response?.error?.message.includes('401')) {
                setErrortext('Недостаточно прав для выполнения этого запроса.')
                return
            } else {
                setErrortext("Ошибка при перепривязки отвеса")
                return
            }
        } else {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        }
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>
                        Перепривязать отвес
                    </span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                    <>
                        <div className="df fdc" style={{ gap: '15px' }}>
                            <InputComponent
                                type={"default"}
                                icon={false}
                                placeholder={'Номер заявки'}
                                state={newRequestNumber}
                                setState={setNewRequestNumber}
                                width={"100%"}
                                autocomplete={"off"}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        retieWeighing();
                                    }
                                }}
                                maxLength={40}
                            />
                            <InputComponent
                                type={"default"}
                                placeholder={"Примечание"}
                                icon={false}
                                state={desc}
                                setState={setDesc}
                                autocomplete={"off"}
                                maxLength={100}
                            />
                        </div>
                        {errortext !== '' && (<span className="fz14 w100" style={{ color: '#EB5757' }}>{errortext}</span>)}
                        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                            <button disabled={isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    retieWeighing();
                                }}
                                className="tac"
                                style={{
                                    width: '100%',
                                    cursor: isDisabled ? "not-allowed" : "pointer",
                                    background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                }}
                            >
                                Сохранить
                            </button>
                            <button
                                onClick={() => setPopup(false)}
                                className="cp tac w100"
                                style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                            >
                                Отменить
                            </button>
                        </div>
                    </>
            </div>
        </div>
    )
}