import { CloseIcon } from 'assets/icons/Close'
import { useEffect } from 'react'
import { SelectComponent } from 'ui/SelectComponent'
import { useNavigate } from 'react-router-dom'
import { exportType } from 'constDatas'
import { useSelector } from 'react-redux'

interface props {
  popup: boolean
  setPopup: Function
  typeexport: any
  setTypeexport: Function
}

export const ChooseExportTypePopup = ({
  popup,
  setPopup,
  typeexport,
  setTypeexport,
}: props) => {
  const navigate = useNavigate()
  const userRoles = useSelector((state: any) => state?.api?.user?.roles)

  useEffect(() => {
    if (typeexport) {
      setPopup(false)
    }
  }, [typeexport, setPopup])

  const handleClickOverlay = (e: any) => {
    if (e.target.classList.contains('overlay')) {
      setPopup(false)
      navigate('/main/requests')
    }
  }

  return (
    <div
      className="h100vh posa w100 df jcc aic overlay"
      style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}
      onClick={handleClickOverlay}
    >
      <div
        className="df fdc alfs"
        style={{
          background: 'white',
          width: '350px',
          borderRadius: '30px',
          padding: '20px',
          gap: '20px',
        }}
      >
        <div className="df jcsb aic">
          <span style={{ fontSize: '20px' }}>Выбрать тип перевозки</span>
          <div
            className="df jcc aic cp"
            onClick={(e) => {
              setPopup(false)
              navigate('/main/requests')
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="df fdc" style={{ gap: '15px' }}>
          <SelectComponent
            placeholder="Тип перевозки"
            items={exportType}
            state={typeexport}
            setState={setTypeexport}
          />
        </div>
      </div>
    </div>
  )
}
