import { BreadCrumbsComponent } from 'ui/BreadCrumbs'
import s from './index.module.scss'
import { ProfileBtn } from 'ui/ProfileBtn'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMaterialsData,
  getObjectsData,
  postAddrequestData,
  getCompaniesWithFilteringData,
} from 'store/features/apiSlice'
import { SelectComponent } from 'ui/SelectComponent'
import { PlusIcon } from 'assets/icons/Plus'
import { InputComponent } from 'ui/InputComponent'
import {
  allPriority,
  allUnits,
  anotherLimit,
  bornData,
  exportType,
  parseBorn,
  parseLimit,
  parsePriority,
  parseShip,
  parseUnit,
} from 'constDatas'
import { useNavigate } from 'react-router-dom'
import { UnclickedCircle } from 'assets/icons/UnclickedCircle'
import { ClickedCircle } from 'assets/icons/ClickedCircle'
import { AddObjectPopup } from './components/AddObjectPopup'
import { AddCompanyPopup } from './components/AddCompanyPopup'
import { ChooseExportTypePopup } from './components/ChooseExportTypePopup'
import config from 'configDefaultCompanies.json'

interface BornDataItem {
  key: string
  label: string
}

export const RequestAddPage = () => {
  const [address, setAddress] = useState('')
  const [addressurl, setAddressurl] = useState('')
  const [clientName, setClientName] = useState('')
  const [number, setNumber] = useState('')
  const [volume, setVolume] = useState('')
  const [min, setMin] = useState('')
  const [max, setMax] = useState('')
  const [price, setPrice] = useState('')
  const [comment, setComment] = useState('')
  const [errorapi, setErrorapi] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isMin, setisMin] = useState(true)
  const [popup, setPopup] = useState(false)
  const [addCompanyPopup, setAddCompanyPopup] = useState(false)
  const [chooseExportTypePopup, setChooseExportTypePopup] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const [companies, setCompanies]: any = useState([])
  const [providers, setProviders]: any = useState([])
  const [objects, setObjects]: any = useState([])
  const [materials, setMaterials]: any = useState([])
  const [company, setCompany]: any | string | null = useState(null)

  const [obj, setObj] = useState(null)
  const [provider, setProvider] = useState<null | string>(null)
  const [material, setMaterial] = useState(null)
  const [priority, setPriority] = useState<null | string>(null)
  const [unit, setUnit] = useState(null)
  const [limit, setLimit] = useState(null)
  const [typeexport, setTypeexport] = useState<null | string>(null)
  const [born, setBorn] = useState(null)

  const userRoles = useSelector((state: any) => state?.api?.user?.roles)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filteredBornData: BornDataItem[] = bornData.filter(
    (item: BornDataItem) => item.label !== 'Все'
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isTypeTransportationPickup = useCallback(() => {
    return (
      typeexport === 'Самовывоз безлимитный' ||
      typeexport === 'Самовывоз с лимитом' ||
      typeexport === 'Самовывоз одноразовый'
    )
  }, [typeexport])

  const isTypeTransportationPickupDisposable = useCallback(() => {
    return typeexport === 'Самовывоз с лимитом'
  }, [typeexport])

  useEffect(() => {
    if (
      typeexport === 'Самовывоз безлимитный' ||
      typeexport === 'Самовывоз с лимитом' ||
      typeexport === 'Самовывоз одноразовый'
    ) {
      setPriority('Низкий')
    }
  }, [typeexport])

  const getCompanies = useCallback(async () => {
    const obj = {
      company_function: 'Customer',
    }
    const companiesData = await dispatch(getCompaniesWithFilteringData(obj))
    setCompanies(
      companiesData?.payload?.data.filter((item: any) => item.active === true)
    )
  }, [dispatch])

  const getProviderCompanies = useCallback(async () => {
    const obj = {
      company_function: 'Provider',
    }
    const companiesData = await dispatch(getCompaniesWithFilteringData(obj))
    setProviders(
      companiesData?.payload?.data.filter((item: any) => item.active === true)
    )
  }, [dispatch])

  useEffect(() => {
    if (providers) {
      if (typeexport === 'Самовывоз одноразовый') {
        setProvider(
          providers?.find(
            (item: any) => item.company_id === config?.provider_id
          )?.name
        )
      }
    }
  }, [providers, typeexport])

  useEffect(() => {
    if (companies) {
      if (typeexport === 'Самовывоз одноразовый') {
        setCompany(
          companies?.find(
            (item: any) => item.company_id === config?.customer_id
          )?.name
        )
      }
    }
  }, [companies, typeexport])

  const getObjects = useCallback(
    async (companyname: string | undefined) => {
      const objectsData = await dispatch(getObjectsData())
      const filteredObjects =
        companyname === undefined
          ? objectsData?.payload?.data?.reverse()
          : objectsData?.payload?.data?.filter(
              (item: any) => item.company.name === companyname
            )
      setObjects(filteredObjects)
    },
    [dispatch]
  )

  const getFilteredPriorities = (allPriority: Array<any>) => {
    if (typeexport) {
      if (
        typeexport === 'Отдел сбыта' ||
        typeexport === 'Наёмник' ||
        typeexport === 'Отдел сбыта + Наёмник'
      ) {
        return allPriority.filter(
          (item: any) => item.label === 'Высокий' || item.label === 'Средний'
        )
      } else {
        return allPriority.filter((item: any) => item.label === 'Низкий')
      }
    }
    return allPriority
  }

  const getMaterial = useCallback(async () => {
    const materialData = await dispatch(getMaterialsData())
    setMaterials(materialData?.payload?.data.reverse())
  }, [dispatch])

  async function addRequest() {
    const dataobj = {
      deposit: parseBorn(born),
      company_customer_id: companies.filter(
        (item: any) => item.name === company
      )[0].company_id,
      company_provider_id: providers.filter(
        (item: any) => item.name === provider
      )[0].company_id,
      object_id: objects.filter((item: any) => item.name === obj)[0]?.object_id,
      material_id: materials.filter((item: any) => item.name === material)[0]
        ?.material_id,
      alltime_requisition_volume:
        isTypeTransportationPickup() && !isTypeTransportationPickupDisposable()
          ? undefined
          : parseFloat(volume),
      day_minimum_volume: isTypeTransportationPickup()
        ? undefined
        : parseFloat(min),
      day_maximum_volume: isTypeTransportationPickup()
        ? undefined
        : parseFloat(max),
      shipping_type: parseShip(typeexport),
      price: typeexport === 'Самовывоз одноразовый' ? price : undefined,
      car_number: [comment],
      priority: parsePriority(priority),
      fulfilling_daily_norm: isTypeTransportationPickup()
        ? undefined
        : isMin
          ? 'Min'
          : 'Max',
      residual_limit:
        isTypeTransportationPickup() && !isTypeTransportationPickupDisposable()
          ? undefined
          : parseLimit(limit),
      unit_type: parseUnit(unit),
    }
    const response = await dispatch(postAddrequestData(dataobj))
    if (response?.payload?.message === 'ok') {
      navigate('/main/requests')
    } else if (response?.error?.message.includes('409')) {
      setErrorapi('Заявка с таким объектом уже существует')
      return
    } else if (response?.error?.message.includes('401')) {
      setErrorapi('Недостаточно прав для выполнения этого запроса.')
      return
    } else if (response?.error?.message.includes('403')) {
      setErrorapi('Недостаточно прав для выполнения этого запроса.')
      return
    } else {
      setErrorapi('Ошибка сервера')
      return
    }
  }

  const objectHandler = (e: any) => {
    const filteredObject = objects?.filter((item: any) => item?.name === e)
    setAddress(filteredObject?.[0]?.address)
    setAddressurl(filteredObject?.[0]?.address_link)
    setClientName(filteredObject?.[0]?.client_name)
    setNumber(filteredObject?.[0]?.client_number)
  }

  useEffect(() => {
    if (
      company !== null &&
      (isTypeTransportationPickup() || address !== '') &&
      (isTypeTransportationPickup() || obj !== null) &&
      (isTypeTransportationPickup() || addressurl !== '') &&
      provider !== null &&
      ((isTypeTransportationPickup() &&
        !isTypeTransportationPickupDisposable()) ||
        limit !== null) &&
      born !== null &&
      ((isTypeTransportationPickup() &&
        !isTypeTransportationPickupDisposable()) ||
        /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(volume)) &&
      material !== null &&
      (typeexport !== 'Самовывоз одноразовый' || /^\d+$/.test(price)) &&
      priority !== null &&
      (isTypeTransportationPickup() ||
        /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(min)) &&
      (isTypeTransportationPickup() ||
        /^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/.test(max)) &&
      unit !== null &&
      typeexport !== null
    ) {
      setIsDisabled(false)
    } else setIsDisabled(true)
  }, [
    company,
    obj,
    address,
    addressurl,
    number,
    provider,
    limit,
    born,
    volume,
    material,
    min,
    priority,
    max,
    price,
    unit,
    typeexport,
    comment,
    isTypeTransportationPickup,
    isTypeTransportationPickupDisposable,
  ])

  useEffect(() => {
    setTimeout(() => {
      setErrorapi('')
    }, 5000)
  }, [errorapi])

  useEffect(() => {
    getCompanies()
    getProviderCompanies()
    getMaterial()
  }, [getCompanies, getMaterial])

  useEffect(() => {
    setChooseExportTypePopup(true)
  }, [])

  const renderMenu = () => {
    return (
      <div className={`${s.menu}`}>
        <div className={`${s.left} df fdc w100`}>
          <div className={`${s.info} df fdc w100`}>
            <span className={s.title}>Информация о заказчике</span>
            <div className="df aic">
              <div
                className="df jcc aic cp"
                style={{
                  borderRadius: '15px',
                  padding: '5px',
                  background: '#F2C94C',
                  height: '45px',
                  width: '50px',
                  marginRight: '8px',
                }}
                onClick={() => setAddCompanyPopup(true)}
              >
                <PlusIcon size={23} />
              </div>
              <SelectComponent
                placeholder="Заказчик"
                items={companies}
                state={company}
                doSome={getObjects}
                setState={setCompany}
                loadingText={'Список подгружается...'}
              />
            </div>
            <div>
              {!isTypeTransportationPickup() && (
                <div className="df aic">
                  <div
                    className="df jcc aic cp"
                    style={{
                      borderRadius: '15px',
                      padding: '5px',
                      background: '#F2C94C',
                      height: '45px',
                      width: '50px',
                      marginRight: '8px',
                    }}
                    onClick={() => setPopup(true)}
                  >
                    <PlusIcon size={23} />
                  </div>

                  <SelectComponent
                    placeholder="Объект"
                    items={objects}
                    state={obj}
                    doSome={objectHandler}
                    setState={setObj}
                    loadingText={'Список подгружается...'}
                  />
                </div>
              )}
              {errorapi !== '' && (
                <span
                  style={{
                    color: '#EB5757',
                    fontSize: '14px',
                    marginLeft: '8px',
                  }}
                >
                  {errorapi}
                </span>
              )}
            </div>
            {!isTypeTransportationPickup() && (
              <InputComponent
                type={'default'}
                placeholder={'Адрес'}
                autocomplete={'off'}
                state={address}
                setState={setAddress}
                maxLength={50}
              />
            )}
            {!isTypeTransportationPickup() && (
              <InputComponent
                type={'default'}
                placeholder={'Ссылка на адрес'}
                autocomplete={'off'}
                state={addressurl}
                setState={setAddressurl}
                maxLength={300}
              />
            )}
            {!isTypeTransportationPickup() && (
              <InputComponent
                type={'default'}
                placeholder={'Имя встречающего лица'}
                icon={false}
                autocomplete={'off'}
                state={clientName}
                setState={setClientName}
                maxLength={50}
              />
            )}
            {!isTypeTransportationPickup() && (
              <InputComponent
                type={'phone'}
                placeholder={'Номер встречающего лица'}
                autocomplete={'off'}
                state={number}
                setState={setNumber}
              />
            )}
          </div>
          <div className={`${s.btnsBlock}`} style={{ gap: '16px' }}>
            <button
              disabled={isDisabled}
              className="w100 df jcc aic"
              style={{
                background: isDisabled ? 'rgba(242, 201, 76, 0.58)' : '#F2C94C',
                height: '50px',
                borderRadius: '16px',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                addRequest()
                setIsDisabled(true)
              }}
            >
              Сохранить
            </button>
            <button
              style={{
                height: '50px',
                border: '1px solid #333333',
                color: '#333333',
                borderRadius: '16px',
              }}
              className="df jcc aic cp w100"
              onClick={() => navigate('/main/requests')}
            >
              Отменить
            </button>
          </div>
        </div>
        <div className={`${s.right} w100 df fdc`}>
          <span className={s.title}>Данные заявки</span>
          <div className={`${s.list} df fdc`}>
            <div className={`${s.row}`}>
              <SelectComponent
                placeholder="Поставщик"
                items={providers}
                state={provider}
                setState={setProvider}
                loadingText={'Список подгружается...'}
              />
              {(!isTypeTransportationPickup() ||
                isTypeTransportationPickupDisposable()) && (
                <SelectComponent
                  placeholder="Остаточный лимит"
                  items={anotherLimit}
                  state={limit}
                  setState={setLimit}
                />
              )}
            </div>
            <div className={`${s.row}`}>
              <SelectComponent
                placeholder="Месторождение"
                items={filteredBornData}
                state={born}
                setState={setBorn}
              />
              {(!isTypeTransportationPickup() ||
                isTypeTransportationPickupDisposable()) && (
                <InputComponent
                  type={'default'}
                  placeholder={'Общий объем заявки'}
                  autocomplete="off"
                  state={volume}
                  setState={setVolume}
                  regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                  errortext={'Неккоректное значение'}
                  maxLength={20}
                />
              )}
            </div>
            <div className={`${s.row}`}>
              <SelectComponent
                placeholder="Материал"
                items={materials}
                state={material}
                setState={setMaterial}
                loadingText={'Список подгружается...'}
              />
              {!isTypeTransportationPickup() && (
                <InputComponent
                  type={'default'}
                  placeholder={'Дневной минимум (min)'}
                  autocomplete="off"
                  state={min}
                  setState={setMin}
                  regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                  errortext={'Неккоректное значение'}
                  maxLength={20}
                />
              )}
            </div>
            <div className={`${s.row}`}>
              <SelectComponent
                placeholder="Приоритет"
                items={getFilteredPriorities(allPriority)}
                state={
                  getFilteredPriorities(allPriority).length === 1
                    ? getFilteredPriorities(allPriority)[0]
                    : priority
                }
                disabled={getFilteredPriorities(allPriority).length === 1}
                setState={setPriority}
              />
              {!isTypeTransportationPickup() && (
                <InputComponent
                  type={'default'}
                  placeholder={'Дневной максимум (max)'}
                  autocomplete="off"
                  state={max}
                  setState={setMax}
                  regex={/^(?!.*\s)[0-9]+(?:\.[0-9]+)?$/}
                  errortext={'Неккоректное значение'}
                  maxLength={20}
                />
              )}
            </div>
            <div className={`${s.row}`}>
              <SelectComponent
                placeholder="Единица измерения"
                items={allUnits}
                state={unit}
                setState={setUnit}
              />
              <SelectComponent
                placeholder="Тип перевозки"
                items={exportType}
                state={typeexport}
                setState={setTypeexport}
              />
            </div>
          </div>
          {!isTypeTransportationPickup() && (
            <div className={`${s.daystandart} df fdc`}>
              <span className={s.title}>Ориентир выполнения дневной нормы</span>
              <div className="df" style={{ gap: '10px' }}>
                <div className="df aic">
                  {isMin ? (
                    <div onClick={() => setisMin(false)} className="cp">
                      <UnclickedCircle />
                    </div>
                  ) : (
                    <div>
                      <ClickedCircle />
                    </div>
                  )}
                  <span style={{ marginBottom: '3px', marginLeft: '5px' }}>
                    Максимум (max)
                  </span>
                </div>
                <div className="df aic">
                  {isMin ? (
                    <div>
                      <ClickedCircle />
                    </div>
                  ) : (
                    <div onClick={() => setisMin(true)} className="cp">
                      <UnclickedCircle />
                    </div>
                  )}
                  <span style={{ marginBottom: '3px', marginLeft: '5px' }}>
                    Минимум (min)
                  </span>
                </div>
              </div>
            </div>
          )}
          {typeexport === 'Самовывоз одноразовый' && (
            <InputComponent
              type={'default'}
              placeholder={'Цена'}
              autocomplete="off"
              state={price}
              setState={setPrice}
              regex={/^\d+$/}
              errortext={'Неккоректное значение'}
              maxLength={20}
            />
          )}
          <InputComponent
            type={'textarea'}
            placeholder={'Примечание'}
            icon={false}
            state={comment}
            setState={setComment}
            maxLength={500}
          />
        </div>
        {isMobile && (
          <div className="df fdc w100" style={{ gap: '16px' }}>
            <button
              disabled={isDisabled}
              className="w100 df jcc aic"
              style={{
                background: isDisabled ? 'rgba(242, 201, 76, 0.58)' : '#F2C94C',
                height: '50px',
                borderRadius: '16px',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                addRequest()
                setIsDisabled(true)
              }}
            >
              Сохранить
            </button>
            <button
              style={{
                height: '50px',
                border: '1px solid #333333',
                color: '#333333',
                borderRadius: '16px',
              }}
              className="df jcc aic cp w100"
              onClick={() => navigate('/main/requests')}
            >
              Отменить
            </button>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {popup && (
        <AddObjectPopup
          popup={popup}
          setPopup={setPopup}
          refreshData={getObjects}
          setErrorapi={setErrorapi}
          nowcompany={company}
        />
      )}
      {addCompanyPopup && (
        <AddCompanyPopup
          popup={addCompanyPopup}
          setPopup={setAddCompanyPopup}
          refreshData={getCompanies}
        />
      )}
      {chooseExportTypePopup && (
        <ChooseExportTypePopup
          popup={chooseExportTypePopup}
          setPopup={setChooseExportTypePopup}
          typeexport={typeexport}
          setTypeexport={setTypeexport}
        />
      )}
      <div className={`main w100`}>
        <div className={`${s.navbar} df jcsb aic`}>
          <BreadCrumbsComponent
            backurl="/main/requests"
            subtitle="Журнал заявок"
            title="Добавить новую заявку"
          />
          <div className="profile-button">
            <ProfileBtn />
          </div>
        </div>
        {typeexport && renderMenu()}
      </div>
    </>
  )
}
