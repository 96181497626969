import { useCallback, useEffect, useState } from 'react';
import s from './index.module.scss'
import 'react-loading-skeleton/dist/skeleton.css'

// ICONS
import { PlusIcon } from 'assets/icons/Plus';
import { DownloadIcon } from 'assets/icons/DownloadIcon';

// UI
import { ProfileBtn } from 'ui/ProfileBtn';
import { AddBriefCasePopup } from './components/AddBriefCasePopup';
import { useDispatch } from 'react-redux';
import { getBriefCasesData, getBriefCaseData } from 'store/features/apiSlice';
import { SkeletonComponent } from 'ui/SkeletonComponent';
import { parseDate, parseShip, parseCompanyType } from 'constDatas';

import { EditPopup } from './components/EditPopup';

export const BriefcasePage = () => {
    const [selectedUser, setSelectedBriefCase] = useState<any | null>(null);
    const [briefCasesData, setBriefCasesData] = useState([]);
    const [loading, setLoading] = useState(true);

    // states for popup menus
    const [popup, setPopup] = useState(false);
    const [addpopup, setAddpopup] = useState(false);
    const [editpopup, setEditpopup] = useState(false);
    const [errortext, setErrortext] = useState('');
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const dispatch = useDispatch()


    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 

    const getBriefCases = useCallback(async () => {
        setLoading(true);
        const responseData = await dispatch(getBriefCasesData());
        setBriefCasesData(responseData?.payload?.data);
        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
      getBriefCases()
    }, [getBriefCases]);

    const RenderSkeleton = () => (
      <>
        {Array(loading ? briefCasesData?.length || 7 : 7).fill('').map((_, i) => (
          <div style={{ height: '45px', borderRadius: '16px', marginTop: '3px' }} key={i}>
            <SkeletonComponent height={'100%'} bradius={'16px'} />
          </div>
        ))}
      </>
    );

    async function downloadBriefCaseButton(file_id: any) {
      const response = await dispatch(getBriefCaseData(file_id))
      if (response?.payload?.message === 'ok') {
        const byteCharacters = atob(response?.payload?.data?.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = response?.payload?.data?.filename;
        downloadLink.click();
    } else if (response?.error?.message.includes('401')) {
        setErrortext('Недостаточно прав для выполнения этого запроса.')
        return
    } else if (response?.error?.message.includes('403')) {
        setErrortext('Недостаточно прав для выполнения этого запроса.')
        return
    } else {
        setErrortext('Ошибка сервера')
        return
    }
    }
      
    const RenderDesktopUser = (item: any, index: any) => (
      <div className={`${s.secondrow} cp w100 df aic fz16`} 
      key={index}
      onClick={(e) => {
        e.stopPropagation();
        setEditpopup(true);
        setSelectedBriefCase(item);
      }}
      >
        <span className={`textellipsis w30`}>{parseCompanyType(item?.company?.company_type)} "{item?.company?.name}"</span>
        <span className={`textellipsis w30`}>{parseShip(item?.shipping_type)}</span>
        <span className={`textellipsis w20`}>{parseDate(item?.rq_date_create)}</span>
        <button className={`${s.downloadButton} w20 hover`} onClick={(e) => { e.stopPropagation(); downloadBriefCaseButton(item?.file_id) }}>
          < DownloadIcon />Скачать
        </button>
      </div>
    );

    const RenderMobileUser = (item: any, index: any) => (
        <div 
        className={s.mobileUserBlock}
        onClick={(e) => {
          e.stopPropagation();
          setEditpopup(true);
          setSelectedBriefCase(item);
        }}
        key={index}>
          <div className={s.nameBlock}>
            <span className='fz16 fw600' style={{color: "#333"}}>{parseCompanyType(item?.company?.company_type)} "{item?.company?.name}"</span>
          </div>
          <div className={s.userInfoBlock}>
            <span className='fz14 fw600' style={{color: "#828282"}}>{`${parseShip(item?.shipping_type)}`}</span>
          </div>
          <div style={{borderBottom: "1px dashed #E0E0E0", height: "1px"}}></div>
          <div className={`${s.userInfoSecondBlock} df jcsb aic`}>
            <span className='fz14 fw600' style={{color: "#333"}}>{`Дата: ${parseDate(item?.rq_date_create)}`}</span>
            <button className={`${s.downloadButton} fz14 fw600`} style={{color: "#333"}} onClick={(e) => { e.stopPropagation(); downloadBriefCaseButton(item?.file_id) }}>
                < DownloadIcon />Скачать
              </button>
          </div>
        </div>
    );

    return (
        <>
          {addpopup && <AddBriefCasePopup popup={addpopup} setPopup={setAddpopup} refreshData={getBriefCases} />}
          {editpopup && <EditPopup selected={selectedUser} popup={editpopup} setPopup={setEditpopup} refreshData={getBriefCases} />}
          <div className={`main w100`}>
            <div className={`${s.navbar} w100 df jcsb aic fww-mobile`}>
              <span className='page-title fw600'>Бухгалтерия</span>
              <div className={`${s.right} df aic fww-mobile`}>
                <div className={`${s.adduser} cp df jcc aic`} onClick={() => setAddpopup(true)}>
                  <PlusIcon color={'black'} />
                  <span className='fw700 fz16'>Добавить накладную</span>
                </div>
                <div className='profile-button'>
                <ProfileBtn />
                </div>
              </div>
            </div>
            <div className={`${s.row} w100 aic fz18 fw600`}>
              <span className='w30'>Контрагент</span>
              <span className='w30'>Условие поставки</span>
              <span className='w20'>Дата</span>
              <span className='w20'>Файл</span>
            </div>
            <div className={`${s.lists}`}>
            {loading ? (
                <RenderSkeleton />
              ) : (
                briefCasesData.map((item, index) => (isMobile ? RenderMobileUser(item, index) : RenderDesktopUser(item, index)))
              )}
            </div>
            {isMobile && !addpopup && !popup && !editpopup && (
              <div className={`${s.mobileadduser} cp df jcc aic`} onClick={() => setAddpopup(true)}>
                <PlusIcon color={'black'} />
                <span className='fw700 fz16'>Добавить накладную</span>
              </div>
            )}
          </div>
        </>
      );
}