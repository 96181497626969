import { CloseIcon } from "assets/icons/Close";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCompaniesData, postAddObjectsData } from "store/features/apiSlice";
import { InputComponent } from "ui/InputComponent";
import { SelectComponent } from "ui/SelectComponent";

interface props {
  popup: boolean
  setPopup: Function
  refreshData: Function
  setErrorapi: any
  nowcompany?: any
}

export const AddObjectPopup = ({ popup, setPopup, refreshData, setErrorapi, nowcompany }: props) => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState(null);
  const [companyData, setCompanyData]: any = useState([]);
  const [clientName, setClientName] = useState('');
  const [number, setNumber] = useState('');
  const [addressurl, setAddressurl] = useState('');
  const [address, setAddress] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch()

  const getCompanies = useCallback(async () => {
    try {
      const companiesData = await dispatch(getCompaniesData());
      setCompanyData(companiesData?.payload?.data?.reverse());
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (popup) document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = ''; };
  }, [popup]);

  useEffect(() => {
    if (name !== '' && company !== null && address !== '' && addressurl !== '' && clientName !== '') setIsDisabled(false)
    else setIsDisabled(true)
  }, [name, number, clientName, company, address, addressurl]);

  useEffect(() => {
    getCompanies()
  }, [getCompanies]);

  async function addObject() {
    const obj = {
      name: name,
      company_id: companyData?.filter((item: any) => item.name === company)[0].company_id,
      address: address,
      address_link: addressurl,
      client_name: clientName,
      client_number: number
    }
    const response = await dispatch(postAddObjectsData(obj))
    if (response?.payload?.message === 'ok') {
      refreshData(nowcompany)
      setPopup(false)
    } else if (response?.error?.message.includes('409')) {
        setErrorapi('Такой объект уже существует')            
        return
    } else if (response?.error?.message.includes('403')) {
        setErrorapi('Недостаточно прав для выполнения этого запроса.')
        return
    } else if (response?.error?.message.includes('401')) {
        setErrorapi('Недостаточно прав для выполнения этого запроса.')
        return
    } else {
        setErrorapi('Ошибка сервера')
        return
    }
  }

  return (
    <div className="h100vh posa w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
      <div className="df fdc alfs" style={{ background: 'white', width: '350px', borderRadius: '30px', padding: '20px', gap: '20px' }}>
        <div className="df jcsb aic">
          <span style={{ fontSize: '20px' }}>Добавить объект</span>
          <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
        </div>
        <div className="df fdc" style={{ gap: '15px' }}>
          <InputComponent
            type={"default"}
            placeholder={"Наименование"}
            icon={false}
            state={name}
            setState={setName}
            maxLength={20}
          />
          <SelectComponent
            placeholder={'Компания'}
            items={companyData}
            state={company}
            setState={setCompany}
          />
          <InputComponent
            type={'default'}
            placeholder={'Имя встречающего лица'}
            icon={false}
            autocomplete={'off'}
            state={clientName}
            setState={setClientName}
            maxLength={50}
          />
          <InputComponent
            type={"phone"}
            placeholder={"Номер встречающего лица"}
            state={number}
            setState={setNumber}
            width={"100%"}
          />
          <InputComponent
            type={"default"}
            placeholder={"Ссылка на адрес"}
            icon={false}
            state={addressurl}
            setState={setAddressurl}
            maxLength={300}
          />
          <InputComponent
            type={"default"}
            placeholder={"Адрес"}
            icon={false}
            state={address}
            setState={setAddress}
            maxLength={50}
          />
        </div>
        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
          <button
            disabled={isDisabled}
            onClick={() => addObject()}
            className="tac" style={{
              width: '100%',
              cursor: isDisabled ? "not-allowed" : "pointer",
              background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
              borderRadius: '16px',
              padding: '10px 15px',
            }}
          >
            Добавить
          </button>
          <div
            onClick={() => setPopup(false)}
            className="cp tac"
            style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
          >
            Отменить
          </div>
        </div>
      </div>
    </div>
  )
}