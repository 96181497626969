interface Props {
  size?: number
  color?: string
}

export const ClickedCircle = ({ size = 20, color = "#F2C94C" }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_266_11553)">
        <path d="M19.1666 9.99967C19.1666 11.8127 18.629 13.5849 17.6217 15.0924C16.6145 16.5998 15.1828 17.7748 13.5079 18.4686C11.8329 19.1624 9.98975 19.3439 8.21159 18.9902C6.43343 18.6365 4.80009 17.7635 3.51811 16.4815C2.23613 15.1995 1.36309 13.5662 1.00939 11.788C0.655693 10.0098 0.837224 8.16673 1.53103 6.49174C2.22483 4.81675 3.39974 3.38512 4.90719 2.37787C6.41465 1.37062 8.18693 0.833008 9.99992 0.833008C12.4303 0.835574 14.7604 1.80217 16.4789 3.5207C18.1974 5.23922 19.164 7.56931 19.1666 9.99967ZM17.7563 9.99967C17.7563 8.4656 17.3014 6.96598 16.4491 5.69044C15.5969 4.41491 14.3855 3.42075 12.9682 2.83369C11.5509 2.24662 9.99132 2.09302 8.48672 2.3923C6.98212 2.69158 5.60007 3.43031 4.51531 4.51506C3.43056 5.59982 2.69183 6.98188 2.39255 8.48647C2.09327 9.99107 2.24687 11.5506 2.83393 12.9679C3.421 14.3852 4.41516 15.5966 5.69069 16.4489C6.96623 17.3012 8.46585 17.7561 9.99992 17.7561C12.0563 17.7537 14.0279 16.9358 15.482 15.4817C16.9361 14.0276 17.754 12.0561 17.7563 9.99967Z" fill={color} />
        <path d="M10.0001 4.16699C8.84636 4.16699 7.71854 4.50911 6.75926 5.15009C5.79997 5.79106 5.0523 6.7021 4.61079 7.768C4.16928 8.83391 4.05376 10.0068 4.27884 11.1384C4.50392 12.2699 5.05949 13.3093 5.87529 14.1251C6.6911 14.9409 7.7305 15.4965 8.86206 15.7216C9.99361 15.9467 11.1665 15.8311 12.2324 15.3896C13.2983 14.9481 14.2093 14.2004 14.8503 13.2412C15.4913 12.2819 15.8334 11.154 15.8334 10.0003C15.8318 8.45373 15.2167 6.97095 14.1231 5.87734C13.0295 4.78373 11.5467 4.16863 10.0001 4.16699Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_266_11553">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
