import { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  getSummaryInvoiceData,
  getCompaniesWithFilteringData,
  getObjectsData,
} from 'store/features/apiSlice'
import { DatePicker } from 'antd'
import { SelectComponent } from 'ui/SelectComponent'

import { exportBriefCaseType, parseShip } from 'constDatas'

import dayjs from 'dayjs'

import s from './index.module.scss'
import 'react-loading-skeleton/dist/skeleton.css'

import * as XLSX from 'xlsx'
import { SelectMultipleComponent } from 'ui/SelectMultipleComponent'

export const SummaryInvoice = () => {
  const currentDateTime = dayjs()

  const [errorapi, setErrorapi] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [company, setCompany]: any | string | null = useState(null)
  const [companies, setCompanies]: any = useState({})
  const [providers, setProviders]: any = useState({})
  const [provider, setProvider] = useState(null)
  const [typeexport, setTypeexport] = useState<string | null>(null)
  const [_, setForceUpdate] = useState(0)
  const [loadingText, setLoadingText] = useState('Выберите заказчика')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [objects, setObjects]: any = useState([])
  const [objectId, setObjectId] = useState<null | Array<any>>(null)

  const dateFromRef = useRef(currentDateTime.subtract(24, 'hour'))
  const dateToRef = useRef(currentDateTime)

  const dispatch = useDispatch()

  const getObjects = useCallback(
    async (companyname: string | undefined) => {
      const objectsData = await dispatch(getObjectsData())
      const filteredObjects =
        companyname === undefined
          ? objectsData?.payload?.data?.reverse()
          : objectsData?.payload?.data?.filter(
              (item: any) => item.company.name === companyname
            )
      setObjects(filteredObjects)
    },
    [dispatch]
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getCompanies = useCallback(async () => {
    const obj = {
      company_function: 'Customer',
    }
    const companiesData = await dispatch(getCompaniesWithFilteringData(obj))
    setCompanies(
      companiesData?.payload?.data.filter((item: any) => item.active === true)
    )
  }, [dispatch])

  const getProviderCompanies = useCallback(async () => {
    const obj = {
      company_function: 'Provider',
    }
    const companiesData = await dispatch(getCompaniesWithFilteringData(obj))
    setProviders(
      companiesData?.payload?.data.filter((item: any) => item.active === true)
    )
  }, [dispatch])

  useEffect(() => {
    getCompanies()
    getProviderCompanies()
  }, [getCompanies, getProviderCompanies])

  async function downloadSummaryInvoiceButton(
    response: any,
    date_from: any,
    date_to: any
  ) {
    // const byteCharacters = atob(response?.payload?.data?.file);
    // const byteNumbers = new Array(byteCharacters.length);

    // for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }

    // const byteArray = new Uint8Array(byteNumbers);
    // const arrayBuffer = byteArray.buffer;

    // const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const printWindow = window.open(
      response?.payload?.data?.template_url,
      '_blank'
    )

    if (printWindow) {
      printWindow.document.title = 'Электронный отвес'
      printWindow.document.head.innerHTML = document.head.innerHTML

      // printWindow.onafterprint = function () {
      //     printWindow.close();
      // };

      // printWindow.print();
    }

    // const downloadLink = document.createElement('a');
    // downloadLink.href = window.URL.createObjectURL(blob);
    // downloadLink.download = `${date_from}${date_to}`;

    // downloadLink.click();

    // // Используем библиотеку xlsx для обработки данных
    // const workbook = XLSX.read(arrayBuffer, { type: 'array', raw: true });

    // // Создаем новый блок div для отображения содержимого файла
    // const contentDiv = document.createElement('div');

    // // Преобразуем данные файла в HTML-таблицу
    // const htmlContent = XLSX.write(workbook, { bookType: 'html', bookSST: false, type: 'string' });
    // contentDiv.innerHTML = htmlContent;

    // // Открываем новое окно для печати
    // const printWindow:any = window.open('', '_blank');

    // // Копируем содержимое блока div в окно печати
    // printWindow.document.body.appendChild(contentDiv);

    // printWindow.onafterprint = function () {
    //   printWindow.close();
    // };

    // // Вызываем window.print() в контексте printWindow
    // printWindow.print();
  }

  async function getSummaryInvoice() {
    const obj = {
      date_from: dateFromRef.current
        ? dateFromRef.current.format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      date_to: dateToRef.current
        ? dateToRef.current.format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      company_customer_id: companies.filter(
        (item: any) => item.name === company
      )[0].company_id,
      company_provider_id: providers.filter(
        (item: any) => item.name === provider
      )[0].company_id,
      shipping_type: parseShip(typeexport),
      object_ids: objectId?.map((item: any) => {
        return objects.find((obj: any) => obj.name === item)?.object_id
      }),
    }
    const response = await dispatch(getSummaryInvoiceData(obj))
    if (response?.payload?.message === 'ok') {
      downloadSummaryInvoiceButton(response, obj.date_from, obj.date_to)
      setIsDisabled(false)
    } else if (response?.error?.message.includes('403')) {
      setErrorapi('Недостаточно прав для выполнения этого запроса.')
      return
    } else if (response?.error?.message.includes('401')) {
      setErrorapi('Недостаточно прав для выполнения этого запроса.')
      return
    } else if (response?.error?.message.includes('404')) {
      setErrorapi('По указанной дате заявки не найдены')
      return
    } else {
      setErrorapi('Ошибка сервера')
      return
    }
    setIsDisabled(false)
  }

  useEffect(() => {
    if (
      dateFromRef.current !== null &&
      dateToRef.current !== null &&
      company !== null &&
      provider !== null &&
      typeexport !== null
    )
      setIsDisabled(false)
    else setIsDisabled(true)
  }, [dateFromRef.current, dateToRef.current, company, provider, typeexport])

  return (
    <>
      <div className={s.main}>
        <div className={`${s.requestfiltering}`}>
          <div style={{ marginBottom: '12px' }}>
            <span
              className="fw700 fz18"
              style={{ color: '#333', lineHeight: 'normal' }}
            >
              Фильтры
            </span>
          </div>
          <div className="df fdc" style={{ gap: '20px' }}>
            <div className={`${s.summaryInvoiceSelectBlock} df`}>
              <SelectComponent
                placeholder="Заказчик"
                items={companies}
                height="56px"
                state={company}
                setState={setCompany}
                doSome={getObjects}
                loadingText={'Список подгружается...'}
              />
              <SelectMultipleComponent
                placeholder="Объект"
                items={objects}
                state={objectId}
                setState={setObjectId}
                loadingText={'Список подгружается...'}
              />
              <SelectComponent
                placeholder="Поставщик"
                items={providers}
                height="56px"
                state={provider}
                setState={setProvider}
                loadingText={'Список подгружается...'}
              />
              <SelectComponent
                placeholder="Условие поставки"
                items={exportBriefCaseType}
                state={typeexport}
                setState={setTypeexport}
                height="56px"
              />
            </div>
            <div
              className={`${s.requestDatePickerBlock} df w100 fdc-aic-mobile`}
              style={{ gap: '16px' }}
            >
              <DatePicker
                style={{
                  width: '100%',
                  height: '56px',
                  padding: '16px 16px 16px 12px',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '16px',
                  border: '1px solid #E0E0E0',
                }}
                showTime
                className="datePickerInput fz16"
                format="HH:mm - DD.MM.YYYY"
                defaultValue={dateFromRef.current}
                value={dateFromRef.current}
                onChange={(dateFrom: any) => {
                  dateFromRef.current = dateFrom
                  setForceUpdate((prev) => prev + 1)
                  setErrorapi('')
                }}
                placeholder="Начало отчетности"
              />
              <DatePicker
                style={{
                  width: '100%',
                  height: '56px',
                  padding: '16px 16px 16px 12px',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '16px',
                  border: '1px solid #E0E0E0',
                }}
                showTime
                className="datePickerInput fz16"
                format="HH:mm - DD.MM.YYYY"
                value={dateToRef.current}
                defaultValue={dateToRef.current}
                onChange={(dateTo: any) => {
                  dateToRef.current = dateTo
                  setForceUpdate((prev) => prev + 1)
                  setErrorapi('')
                }}
                placeholder="Конец отчетности"
              />
            </div>
            {errorapi !== '' && (
              <span
                style={{
                  color: 'rgb(235, 87, 87)',
                  marginLeft: '10px',
                  fontSize: '14px',
                }}
              >
                {errorapi}
              </span>
            )}
            <div className={`${s.summaryInvoiceButtons} df`}>
              <button
                className={`${s.applybutton} df jcc aic hover`}
                disabled={isDisabled}
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                }}
                onClick={() => {
                  getSummaryInvoice()
                }}
              >
                <span className="wsn">Сформировать</span>
              </button>
              <button
                className={`${s.resetbutton} df jcc aic hover`}
                onClick={() => {}}
              >
                <span className="wsn">Сбросить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
