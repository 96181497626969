import { useState } from "react";

import { UploadCircle } from 'assets/icons/UploadIcon'

interface Props {
  placeholder?: string | string[] | any;
  setState?: any;
  setFileName?: any;
  setFileExtension?: any;
  errortext?: string | string[] | any;
  width?: string;
}

export const UploadFileComponent = ({
  placeholder,
  setState,
  setFileName,
  setFileExtension,
  width = '100%'
}: Props) => {

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files && e.target.files[0];

    if (selectedFile) {
        const reader = new FileReader();

        const { name } = selectedFile;
        const lastDotIndex = name.lastIndexOf('.');

        const extractedFileName = name.substring(0, lastDotIndex);
        const extractedFileExtension = name.substring(lastDotIndex + 1);

        setFileName(extractedFileName);
        setFileExtension(extractedFileExtension);

        reader.onloadend = () => {
            const base64Data = reader.result?.toString().split(',')[1];
            setState(base64Data);
        };

        reader.readAsDataURL(selectedFile);
    }
};


  return (
    <div style={{ width: width }}>
        <div
          className="df w100 aic cp"
          style={{
            borderRadius: '16px',
            border: '1px solid #E0E0E0',
            padding: '14px 10px',
            background: 'white',
          }}
        >
        <input
            readOnly
            onChange={(e) => handleFileChange(e)}
            className="fw500 fz16 w100 cp"
            style={{ marginLeft: '8px', width: width }}
            type="file"
            placeholder={`${placeholder}`}
        />
        <div
            className={`aic`}
            style={{ width: '24px', height: '24px', flexShrink: '0' }}
        >
        <UploadCircle />
        </div>
        </div>
    </div>
  )}