interface props {
  width?: string
  height?: string
}

export const LogoIcon = ({ width = '208', height = '64' }: props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 208 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="208" height="64" rx="16" fill="#F2C94C" />
      <path
        d="M49.3376 43.52H39.153C38.7473 43.52 38.4484 43.4347 38.2562 43.264C38.0854 43.072 38 42.7733 38 42.368V38.624C38 38.2187 38.0854 37.9307 38.2562 37.76C38.4484 37.568 38.7473 37.472 39.153 37.472H47.416C47.6722 37.472 47.875 37.4187 48.0245 37.312C48.1953 37.184 48.2807 36.992 48.2807 36.736V35.488C48.2807 35.232 48.1953 35.0507 48.0245 34.944C47.875 34.816 47.6722 34.752 47.416 34.752H39.4733C39.0676 34.752 38.7687 34.6667 38.5765 34.496C38.4057 34.304 38.3203 34.0053 38.3203 33.6V21.632C38.3203 21.2267 38.4057 20.9387 38.5765 20.768C38.7687 20.576 39.0676 20.48 39.4733 20.48H53.277C53.6826 20.48 53.9709 20.576 54.1417 20.768C54.3339 20.9387 54.4299 21.2267 54.4299 21.632V25.44C54.4299 25.8453 54.3339 26.144 54.1417 26.336C53.9709 26.5067 53.6826 26.592 53.277 26.592H45.5904V28.672H49.9782C51.7076 28.672 53.0741 29.0987 54.0776 29.952C55.0812 30.8053 55.5829 32.0747 55.5829 33.76V38.208C55.5829 39.8293 55.0171 41.12 53.8855 42.08C52.7752 43.04 51.2593 43.52 49.3376 43.52Z"
        fill="#333333"
      />
      <path
        d="M59.4089 35.296C59.0032 35.296 58.7043 35.2107 58.5122 35.04C58.3413 34.848 58.2559 34.5493 58.2559 34.144V29.856C58.2559 29.4507 58.3413 29.1627 58.5122 28.992C58.7043 28.8 59.0032 28.704 59.4089 28.704H67.9922C68.3979 28.704 68.6861 28.8 68.8569 28.992C69.0491 29.1627 69.1452 29.4507 69.1452 29.856V34.144C69.1452 34.5493 69.0491 34.848 68.8569 35.04C68.6861 35.2107 68.3979 35.296 67.9922 35.296H59.4089Z"
        fill="#333333"
      />
      <path
        d="M88.1028 43.52H72.8578C72.4521 43.52 72.1532 43.4347 71.9611 43.264C71.7903 43.072 71.7048 42.7733 71.7048 42.368V36.864C71.7048 35.6267 71.8223 34.6133 72.0571 33.824C72.3134 33.0133 72.6657 32.352 73.114 31.84C73.5624 31.328 74.0962 30.9227 74.7154 30.624C75.3346 30.3253 76.0072 30.0693 76.7331 29.856L81.0248 28.512C81.3664 28.4267 81.5906 28.2987 81.6973 28.128C81.8254 27.9573 81.8895 27.7013 81.8895 27.36C81.8895 27.04 81.8254 26.8267 81.6973 26.72C81.5692 26.592 81.345 26.528 81.0248 26.528H74.0749C73.6692 26.528 73.3703 26.4427 73.1781 26.272C73.0073 26.08 72.9219 25.7813 72.9219 25.376V21.632C72.9219 21.2267 73.0073 20.9387 73.1781 20.768C73.3703 20.576 73.6692 20.48 74.0749 20.48H83.5229C85.3377 20.48 86.7256 20.9387 87.6864 21.856C88.6686 22.752 89.1597 24.064 89.1597 25.792V28.8C89.1597 30.4 88.7113 31.6267 87.8145 32.48C86.9178 33.3333 85.7007 33.9947 84.1634 34.464L79.8077 35.84C79.4875 35.9253 79.2633 36.0213 79.1352 36.128C79.0284 36.2347 78.975 36.4587 78.975 36.8V37.472H88.1028C88.5084 37.472 88.7967 37.568 88.9675 37.76C89.1597 37.9307 89.2557 38.2187 89.2557 38.624V42.368C89.2557 42.7733 89.1597 43.072 88.9675 43.264C88.7967 43.4347 88.5084 43.52 88.1028 43.52Z"
        fill="#333333"
      />
      <path
        d="M112.305 37.472C112.305 38.4533 112.166 39.3493 111.888 40.16C111.611 40.9493 111.109 41.632 110.383 42.208C109.657 42.7627 108.654 43.2 107.373 43.52C106.113 43.84 104.501 44 102.537 44C100.572 44 98.9495 43.84 97.6684 43.52C96.4086 43.2 95.4158 42.7627 94.6898 42.208C93.9639 41.632 93.4515 40.9493 93.1525 40.16C92.875 39.3493 92.7362 38.4533 92.7362 37.472V26.56C92.7362 25.5787 92.875 24.6933 93.1525 23.904C93.4515 23.0933 93.9639 22.4 94.6898 21.824C95.4158 21.248 96.4086 20.8 97.6684 20.48C98.9495 20.16 100.572 20 102.537 20C104.501 20 106.113 20.16 107.373 20.48C108.654 20.8 109.657 21.248 110.383 21.824C111.109 22.4 111.611 23.0933 111.888 23.904C112.166 24.6933 112.305 25.5787 112.305 26.56V37.472ZM99.9743 36.32C99.9743 36.7467 100.124 37.1093 100.423 37.408C100.743 37.6853 101.448 37.824 102.537 37.824C103.625 37.824 104.319 37.6853 104.618 37.408C104.939 37.1093 105.099 36.7467 105.099 36.32V27.712C105.099 27.3067 104.939 26.9653 104.618 26.688C104.319 26.3893 103.625 26.24 102.537 26.24C101.448 26.24 100.743 26.3893 100.423 26.688C100.124 26.9653 99.9743 27.3067 99.9743 27.712V36.32Z"
        fill="#333333"
      />
      <path
        d="M123.532 43.52H117.063C116.657 43.52 116.358 43.4347 116.166 43.264C115.995 43.072 115.91 42.7733 115.91 42.368V35.872C115.91 35.4667 115.995 35.1787 116.166 35.008C116.358 34.816 116.657 34.72 117.063 34.72H123.532C123.938 34.72 124.226 34.816 124.397 35.008C124.589 35.1787 124.685 35.4667 124.685 35.872V42.368C124.685 42.7733 124.589 43.072 124.397 43.264C124.226 43.4347 123.938 43.52 123.532 43.52Z"
        fill="#333333"
      />
      <path
        d="M134.767 43.52H129.803C129.397 43.52 129.098 43.4347 128.906 43.264C128.735 43.072 128.65 42.7733 128.65 42.368V21.632C128.65 21.2267 128.735 20.9387 128.906 20.768C129.098 20.576 129.397 20.48 129.803 20.48H134.767C135.173 20.48 135.461 20.576 135.632 20.768C135.824 20.9387 135.92 21.2267 135.92 21.632V28.992H137.201L140.5 21.536C140.649 21.152 140.852 20.8853 141.108 20.736C141.365 20.5653 141.696 20.48 142.101 20.48H147.034C147.396 20.48 147.642 20.576 147.77 20.768C147.898 20.9387 147.888 21.1947 147.738 21.536L144.471 28.992C145.667 29.056 146.617 29.4827 147.322 30.272C148.048 31.04 148.411 32.064 148.411 33.344V42.368C148.411 42.7733 148.315 43.072 148.122 43.264C147.952 43.4347 147.663 43.52 147.258 43.52H142.326C141.92 43.52 141.621 43.4347 141.429 43.264C141.258 43.072 141.173 42.7733 141.173 42.368V36.128C141.173 35.7867 141.087 35.52 140.916 35.328C140.746 35.136 140.489 35.04 140.148 35.04H135.92V42.368C135.92 42.7733 135.824 43.072 135.632 43.264C135.461 43.4347 135.173 43.52 134.767 43.52Z"
        fill="#333333"
      />
      <path
        d="M168.847 43.52H152.962C152.556 43.52 152.257 43.4347 152.065 43.264C151.894 43.072 151.809 42.7733 151.809 42.368V38.08C151.809 37.6747 151.873 37.3227 152.001 37.024C152.129 36.704 152.332 36.3947 152.609 36.096L160.392 26.656H153.538C153.132 26.656 152.833 26.5707 152.641 26.4C152.47 26.208 152.385 25.9093 152.385 25.504V21.632C152.385 21.2267 152.47 20.9387 152.641 20.768C152.833 20.576 153.132 20.48 153.538 20.48H168.271C168.676 20.48 168.964 20.576 169.135 20.768C169.327 20.9387 169.424 21.2267 169.424 21.632V26.4C169.424 26.8267 169.359 27.2 169.231 27.52C169.103 27.8187 168.9 28.1173 168.623 28.416L161.193 37.408H168.847C169.253 37.408 169.541 37.504 169.712 37.696C169.904 37.8667 170 38.1547 170 38.56V42.368C170 42.7733 169.904 43.072 169.712 43.264C169.541 43.4347 169.253 43.52 168.847 43.52Z"
        fill="#333333"
      />
    </svg>
  )
}
