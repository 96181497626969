import { ToggleOn } from 'assets/icons/ToggleOn';
import s from './index.module.scss'
import { ToggleOff } from 'assets/icons/ToggleOff';
import { ProfileBtn } from 'ui/ProfileBtn';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHide } from 'store/features/apiSlice';

export const ListPage = () => {
    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isBtn, setIsBtn] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setHide(!show))
    }, [show, dispatch])

    const url: any = useLocation()

    const handleClick = () => {
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false)
        }, 200);
    }

    const clickHandler = () => {
        setIsBtn(true)
        setTimeout(() => {
            setIsBtn(false)
        }, 200);
    }

    return (
        <div className={`main w100`}>
            <div className={`${s.navbar} w100 df jcsb aic fww-mobile`}>
                <span className='page-title fw600'>Справочники</span>
                <div className={`${s.right} df aic fww-mobile`}>
                    <div className={`${s.toggle} page-toggle df jcsb aic`}>
                        <span className='fz16 fw700'>Скрытые</span>
                        {show &&
                            <button
                                disabled={isDisabled}
                                className='cp'
                                style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                onClick={() => { setShow(!show); handleClick() }}
                            >
                                <ToggleOff />
                            </button>}
                        {!show &&
                            <button
                                disabled={isDisabled}
                                className='cp'
                                style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                onClick={() => { setShow(!show); handleClick() }}
                            >
                                <ToggleOn />
                            </button>}
                    </div>
                    <div className='profile-button'>
                        <ProfileBtn />
                    </div>
                </div>
            </div>
            <div>
                <div className={`${s.bar} df aic fz18`}>
                    <button
                        className={s.bar_button}
                        disabled={isBtn}
                        onClick={() => { navigate('/main/lists'); clickHandler() }}
                        style={{
                            borderBottom: url.pathname === "/main/lists" ? '2px solid black' : '',
                            fontWeight: url.pathname === "/main/lists" ? '600' : '',
                        }}>
                        Компании
                    </button>
                    <button
                        className={s.bar_button}
                        disabled={isBtn}
                        style={{
                            borderBottom: url.pathname.includes("objects") ? '2px solid black' : '',
                            fontWeight: url.pathname.includes("objects") ? '600' : '',
                        }}
                        onClick={() => { navigate('/main/lists/objects'); clickHandler() }}
                    >
                        Объекты
                    </button>
                    <button
                        className={s.bar_button}
                        disabled={isBtn}
                        style={{
                            borderBottom: url.pathname.includes("materials") ? '2px solid black' : '',
                            fontWeight: url.pathname.includes("materials") ? '600' : '',
                        }}
                        onClick={() => { navigate('/main/lists/materials'); clickHandler() }}
                    >
                        Материалы
                    </button>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

