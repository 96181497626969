interface Props {
  size?: number
  color?: string
}

export const UnclickedCircle = ({ size = 20, color = "#333333" }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_266_11548)">
        <path d="M19.1666 9.99967C19.1666 11.8127 18.629 13.5849 17.6217 15.0924C16.6145 16.5998 15.1828 17.7748 13.5079 18.4686C11.8329 19.1624 9.98975 19.3439 8.21159 18.9902C6.43343 18.6365 4.80009 17.7635 3.51811 16.4815C2.23613 15.1995 1.36309 13.5662 1.00939 11.788C0.655693 10.0098 0.837224 8.16673 1.53103 6.49174C2.22483 4.81675 3.39974 3.38512 4.90719 2.37787C6.41465 1.37062 8.18693 0.833008 9.99992 0.833008C12.4303 0.835574 14.7604 1.80217 16.4789 3.5207C18.1974 5.23922 19.164 7.56931 19.1666 9.99967ZM17.7563 9.99967C17.7563 8.4656 17.3014 6.96598 16.4491 5.69044C15.5969 4.41491 14.3855 3.42075 12.9682 2.83369C11.5509 2.24662 9.99132 2.09302 8.48672 2.3923C6.98212 2.69158 5.60007 3.43031 4.51531 4.51506C3.43056 5.59982 2.69183 6.98188 2.39255 8.48647C2.09327 9.99107 2.24687 11.5506 2.83393 12.9679C3.421 14.3852 4.41516 15.5966 5.69069 16.4489C6.96623 17.3012 8.46585 17.7561 9.99992 17.7561C12.0563 17.7537 14.0279 16.9358 15.482 15.4817C16.9361 14.0276 17.754 12.0561 17.7563 9.99967Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_266_11548">
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
