import { CloseIcon } from "assets/icons/Close";
import { companyTypes, parseCompanyType, companyFunction, parseCompanyFunction } from "constDatas";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InputComponent } from "ui/InputComponent";
import { SelectComponent } from "ui/SelectComponent";
import { postAddCompaniesData } from 'store/features/apiSlice';

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    refreshData: Function
    refreshData2: Function
    hide: boolean
}

export const AddCompanyPopup = ({ popup, setPopup, refreshData, refreshData2, hide }: props) => {
    const [name, setName] = useState('');
    const [type, setType]:  any | null = useState(null);
    const [compFunction, setCompFunction]:  any | null = useState(null);
    const [contact, setContact] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [apierror, setApierror] = useState('');

    const dispatch = useDispatch()

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    useEffect(() => {
        if (name !== '' && type !== null && compFunction !== null) {
            setIsDisabled(false)
        } else setIsDisabled(true)
    }, [type, name, compFunction]);

    async function addCompany() {
        const obj = {
            name: name,
            company_type: parseCompanyType(type),
            company_function: parseCompanyFunction(compFunction),
            representative_contacts: contact,
        }
        const response = await dispatch(postAddCompaniesData(obj))
        if (response?.payload?.message === 'ok') {
            hide ? refreshData() : refreshData2()
            setPopup(false)
        } else if (response?.error?.message.includes('409')) {
            setApierror('Компания с такими данными уже существует.')
            return
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setApierror('Ошибка сервера')
            return
        }
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
                <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                    <div className="df jcsb aic">
                        <span style={{ fontSize: '20px', marginRight: '30px' }}>Добавить компанию</span>
                        <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                    </div>
                    <div className="df fdc" style={{ gap: '15px' }}>
                        <InputComponent
                            type={"default"}
                            icon={false}
                            placeholder={'Наименование'}
                            state={name}
                            setState={setName}
                            width={"100%"}
                            autocomplete={"off"}
                            maxLength={30}
                        />
                        <SelectComponent
                            placeholder={"Тип компании"}
                            items={companyTypes}
                            state={type}
                            setState={setType}
                            width={"100%"}
                        />
                        <SelectComponent
                            placeholder={"Функция компании"}
                            items={companyFunction}
                            state={compFunction}
                            setState={setCompFunction}
                            width={"100%"}
                        />
                        <InputComponent
                            type={"phone"}
                            icon={false}
                            placeholder={'Номер представителя'}
                            state={contact}
                            setState={setContact}
                            width={"100%"}
                            autocomplete={"off"}
                        />
                        <div>
                            {apierror !== '' && <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{apierror}</span>}
                        </div>
                    </div>
                    <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                        <button disabled={isDisabled}
                            onClick={() => addCompany()}
                            className="tac" style=
                            {{
                                width: '100%',
                                cursor: isDisabled ? "not-allowed" : "pointer",
                                background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                                borderRadius: '16px',
                                padding: '10px 15px',
                            }}
                        >
                            Сохранить
                        </button>
                        <button
                                onClick={() => setPopup(false)}
                                className="cp tac"
                                style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                            >
                                Отменить
                        </button>
                    </div>
                </div>
        </div>
    )
}