import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getNotificationsData } from 'store/features/apiSlice'
import { Toast } from 'ui/ToasterComponent'

import config from 'config.json'

export const NotificationCheckComponent = () => {
  const [showToast, setShowToast] = useState(false)

  const dispatch = useDispatch()

  const handleToastClose = () => {
    setShowToast(false)
  }

  async function setNotificationCountToLocalStorage(value: string) {
    localStorage.setItem('notificationCount', value)
  }

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // Использование актуальных данных
      const updatedNotifications = await dispatch(getNotificationsData())
      const numberOfUnreadNotifications =
        updatedNotifications?.payload?.data?.filter((item: any) => !item.readed)
          ?.length
      const hasNewNotifications = updatedNotifications?.payload?.data?.some(
        (item: any) => !item.readed
      )

      if (localStorage.getItem('notificationCount')) {
        localStorage.removeItem('notificationCount')
      }
      setNotificationCountToLocalStorage(numberOfUnreadNotifications)

      if (hasNewNotifications) {
        setShowToast(true)
      }
    }, config.notification_check)

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId)
  }, [dispatch])

  return (
    <>
      {showToast && (
        <Toast
          message="Вам пришло новое уведомление"
          onClose={handleToastClose}
        />
      )}
    </>
  )
}
