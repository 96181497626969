import { CloseIcon } from "assets/icons/Close";
import { exportBriefCaseType, parseShip } from "constDatas";
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { postAddBriefCaseData, getCompaniesData } from "store/features/apiSlice";
import { SelectComponent } from 'ui/SelectComponent';
import { DatePickerComponent } from "ui/DatePickerComponent";
import { UploadFileComponent } from "ui/UploadFileComponent";
import dayjs from "dayjs";


interface props {
    popup: boolean
    setPopup: Function
    refreshData: Function
}

export const AddBriefCasePopup = ({ popup, setPopup, refreshData }: props) => {
    const [date, setDate] = useState('')
    const [apierror, setApierror] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [companyNameData, setCompanyNameData]: any | null = useState(null);
    const [selectedCompany, setSelectedCompany]: any | null = useState(null);
    const [typeexport, setTypeexport] = useState<string | null>(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileExtension, setFileExtension] = useState('');

    const dispatch = useDispatch()

    const getCompanies = useCallback(async () => {
        const companiesData = await dispatch(getCompaniesData());
        setCompanyNameData(companiesData?.payload?.data.reverse());
      }, [dispatch]);

    useEffect(() => {
        if (companyNameData !== null && typeexport !== null && file !== null && date !== null && date !== '' && date !== undefined) setIsDisabled(false)
        else setIsDisabled(true)}, [companyNameData, typeexport, file, date]);

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    useEffect(() => {
        getCompanies();
      }, [getCompanies]);

    const addHandler = async () => {
        const obj: any = {
            company_id: companyNameData?.filter((item: any) => item.name === selectedCompany)[0].company_id,
            shipping_type: parseShip(typeexport),
            data: file,
            filename: fileName,
            rq_date_create: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
            extension: fileExtension
        }
        const response = await dispatch(postAddBriefCaseData(obj))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                refreshData()
            }, 500);
            setPopup(false)
            setIsDisabled(false)
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('409')) {
            setApierror("Накладная с такими данными уже существует.")
            return
        }else {
            setApierror('Ошибка сервера')
            return
        }
    }

    return (
        <div className="h100vh posa w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>Добавить накладную</span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                <div className="df fdc" style={{ gap: '15px' }}>
                    <SelectComponent
                        placeholder={'Контрагент'}
                        items={companyNameData}
                        state={selectedCompany}
                        setState={setSelectedCompany}
                        loadingText={'Нет данных...'}
                    />
                    <SelectComponent
                        placeholder='Условие поставки'
                        items={exportBriefCaseType}
                        state={typeexport}
                        setState={setTypeexport}
                    />
                    <DatePickerComponent
                        placeholder='Дата'
                        state={date}
                        setState={setDate}
                    />
                    <UploadFileComponent 
                        placeholder='Загрузите файл'
                        setState={setFile}      
                        setFileName={setFileName}
                        setFileExtension={setFileExtension}      
                    />
                </div>
                {apierror !== '' && <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{apierror}</span>}
                <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                    <button
                        disabled={isDisabled}
                        onClick={() => { addHandler(); setIsDisabled(true) }}
                        className="tac" style={{
                            width: '100%',
                            cursor: isDisabled ? "not-allowed" : "pointer",
                            background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                            borderRadius: '16px',
                            padding: '10px 15px',
                        }}>
                        Добавить
                    </button>
                    <button
                        onClick={() => setPopup(false)}
                        className="cp tac w100"
                        style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </div>
    )
}