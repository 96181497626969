interface props {
    size?: number
    color?: string
}

export const PasswordHideIcon = ({ size = 24, color = '#333333' }: props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill={color}>
            <path d="M23.1853 12.1962C23.1525 12.1222 22.3584 10.3606 20.5931 8.59531C18.2409 6.24312 15.27 5 12 5C8.72999 5 5.75905 6.24312 3.40687 8.59531C1.64155 10.3606 0.843741 12.125 0.814679 12.1962C0.772035 12.2922 0.75 12.396 0.75 12.5009C0.75 12.6059 0.772035 12.7097 0.814679 12.8056C0.847491 12.8797 1.64155 14.6403 3.40687 16.4056C5.75905 18.7569 8.72999 20 12 20C15.27 20 18.2409 18.7569 20.5931 16.4056C22.3584 14.6403 23.1525 12.8797 23.1853 12.8056C23.2279 12.7097 23.25 12.6059 23.25 12.5009C23.25 12.396 23.2279 12.2922 23.1853 12.1962ZM12 18.5C9.11437 18.5 6.59343 17.4509 4.50655 15.3828C3.65028 14.5313 2.92179 13.5603 2.34374 12.5C2.92164 11.4396 3.65014 10.4686 4.50655 9.61719C6.59343 7.54906 9.11437 6.5 12 6.5C14.8856 6.5 17.4066 7.54906 19.4934 9.61719C20.3514 10.4684 21.0815 11.4394 21.6609 12.5C20.985 13.7619 18.0403 18.5 12 18.5ZM12 8C11.11 8 10.2399 8.26392 9.49993 8.75839C8.7599 9.25285 8.18313 9.95566 7.84253 10.7779C7.50194 11.6002 7.41282 12.505 7.58646 13.3779C7.76009 14.2508 8.18867 15.0526 8.81801 15.682C9.44735 16.3113 10.2492 16.7399 11.1221 16.9135C11.995 17.0872 12.8998 16.9981 13.7221 16.6575C14.5443 16.3169 15.2471 15.7401 15.7416 15.0001C16.2361 14.26 16.5 13.39 16.5 12.5C16.4988 11.3069 16.0242 10.163 15.1806 9.31939C14.337 8.47575 13.1931 8.00124 12 8ZM12 15.5C11.4066 15.5 10.8266 15.3241 10.3333 14.9944C9.83993 14.6648 9.45542 14.1962 9.22835 13.6481C9.00129 13.0999 8.94188 12.4967 9.05764 11.9147C9.17339 11.3328 9.45911 10.7982 9.87867 10.3787C10.2982 9.95912 10.8328 9.6734 11.4147 9.55764C11.9967 9.44189 12.5999 9.5013 13.148 9.72836C13.6962 9.95542 14.1648 10.3399 14.4944 10.8333C14.824 11.3266 15 11.9067 15 12.5C15 13.2956 14.6839 14.0587 14.1213 14.6213C13.5587 15.1839 12.7956 15.5 12 15.5Z" fill="#333333" />
        </svg>
    )
}
