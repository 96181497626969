import { CloseIcon } from "assets/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteBriefCaseData } from 'store/features/apiSlice';

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    refreshData?: any
}

export const EditPopup = ({ selected, popup, setPopup, refreshData }: props) => {
    const [isDelete, setIsDelete] = useState(false);
    const [errortext, setErrortext] = useState('');
    const [want, setWant] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    const deleteBriefCase = async () => {
        setIsDelete(true)
        const response = await dispatch(deleteBriefCaseData(selected?.file_id))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                refreshData()
            }, 500);
            setPopup(false);
            setIsDelete(false)
        }  else if (response?.error?.message.includes('403')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        }  else if (response?.error?.message.includes('409')) {
            setErrortext("Накладная с такими данными уже существует.")
            return
        } else {
            setErrortext("Ошибка при удалении накладного")
            return
        }
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>
                        Удалить накладную?
                    </span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                {!want ? (
                    <>
                        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                            <div
                                onClick={() => setWant(true)}
                                className="cp tac"
                                style={{
                                    width: '100%',
                                    border: '1px solid #D32929',
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                    color: '#D32929'
                                }}
                            >
                                Удалить
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <span>Подтвердите удаление накладного</span>
                        <div>
                            <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                                <button
                                    disabled={isDelete}
                                    onClick={() => deleteBriefCase()}
                                    className="cp tac"
                                    style={{
                                        width: '100%',
                                        border: '1px solid #D32929',
                                        borderRadius: '16px',
                                        padding: '10px 15px',
                                        color: '#D32929',
                                        cursor: isDelete ? "not-allowed" : "pointer"
                                    }}
                                >
                                    Удалить
                                </button>
                                <div
                                    onClick={() => setWant(false)}
                                    className="cp tac"
                                    style={{
                                        width: '100%',
                                        border: '1px solid rgb(51, 51, 51)',
                                        borderRadius: '16px',
                                        padding: '10px 15px',
                                        color: 'rgb(51, 51, 51)'
                                    }}
                                >
                                    Нет
                                </div>
                            </div>
                            {errortext !== '' && (
                                <div className="w100 df jcc aic" style={{ marginTop: '5px' }}>
                                    <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{errortext}</span>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}