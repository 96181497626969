import { SignoutIcon } from "assets/icons/Signout"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { parseRole } from 'constDatas'

export const ProfileBtn = () => {
    const [fullUser, setFullUser] = useState<any>({});
    const navigate = useNavigate();

    const waitForUserInLocalStorage = async () => {
      let attempts = 0;
      const maxAttempts = 10;
      const interval = 500; // Интервал в миллисекундах
  
      while (attempts < maxAttempts) {
        const localUser = localStorage.getItem("user");
  
        if (localUser) {
          try {
            const parsedUser = JSON.parse(localUser);
            setFullUser(parsedUser);
            break;
          } catch (error) {
            console.error("Error parsing user from localStorage:", error);
            handleSignOut()
          }
        }
  
        attempts += 1;
        await new Promise((resolve) => setTimeout(resolve, interval));
      }
    };
  
    useEffect(() => {
      waitForUserInLocalStorage();
    }, []);
  
    const handleSignOut = () => {
      const localStorageKeysToRemove = [
        "authtoken",
        "user",
        "username",
        "userrole",
        "userfullname",
        "selectedRequest",
        "notificationCount",
        "userdeposit",
      ];
  
      localStorageKeysToRemove.forEach((key) => {
        localStorage.removeItem(key);
      });
  
      navigate("/login");
    };
  
    return (
      <div
        className="profile-button-style df jcsb aic w100"
      >
        <div className="df fdc jcc">
          <span className="fz16 fw600" style={{ color: "#333" }} title="Ваш Логин">
          {fullUser?.full_name || "Загрузка..."}
          </span>
            <span className="fz14 fw100" style={{ color: "#828282" }} title="Роль">
                {fullUser?.roles && fullUser.roles.length > 0
                    ? parseRole(fullUser.roles[0].role)
                    : "Роль не определена"}
            </span>
        </div>
        <div onClick={handleSignOut}>
          <SignoutIcon />
        </div>
      </div>
    );
  };