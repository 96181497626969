import { CloseIcon } from "assets/icons/Close";
import { bornData, parseBorn, parseRole, roleData } from "constDatas";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SelectComponent } from 'ui/SelectComponent';

import { postAddUserData } from "store/features/apiSlice";
import { InputComponent } from "ui/InputComponent";

interface props {
    popup: boolean
    setPopup: Function
    refreshData: Function
}

export const AdduserPopup = ({ popup, setPopup, refreshData }: props) => {
    const [role, setRole]: any | null = useState(null);
    const [born, setBorn]: any | null = useState(null);
    const [fullname, setFullname] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [repeat, setRepeat] = useState('');
    const [apierror, setApierror] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [isFullname, setIsFullname] = useState(false);
    const [isUsername, setIsUsername] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const dispatch = useDispatch()

    useEffect(() => {
        if (isFullname && isPassword && isUsername && role != null && born != null) setIsDisabled(false)
    }, [isFullname, isPassword, isUsername, role, born]);

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    const addHandler = async () => {
        const obj: any = {
            username: login.trim(),
            password: password,
            full_name: fullname,
            role: parseRole(role),
            deposit: parseBorn(born),
        }
        const response = await dispatch(postAddUserData(obj))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                refreshData()
            }, 500);
            setPopup(false)
            setIsDisabled(false)
        } else if (response?.error?.message.includes('409')) {
            setApierror('Пользователь уже существует')
            return
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setApierror('Ошибка сервера')
            return
        }
    }

    const selectRole = (e: string) => {
        setRole(e)
        if (e === 'Менеджер Отдела Сбыта' ||
            e === 'Ст. Менеджер Отдела Сбыта' ||
            e === 'Учредитель' ||
            e === 'Бухгалтер' ||
            e === 'РОП'
        ) setBorn(parseRole("All"))
        else setBorn(null)
    }

    return (
        <div className="h100vh posa w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>Добавить нового пользователя</span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                <div className="df fdc" style={{ gap: '15px' }}>
                    <InputComponent
                        type={"default"}
                        placeholder={"Введите ФИО"}
                        icon={false}
                        state={fullname}
                        setState={setFullname}
                        autocomplete={"off"}
                        setIs={setIsFullname}
                    />
                    <InputComponent
                        type={"user"}
                        placeholder={"Создайте логин"}
                        icon={false}
                        state={login}
                        setState={setLogin}
                        setIs={setIsUsername}
                        regex={/^.{3,}$/}
                        errortext={"Логин слишком короткий"}
                    />
                    <InputComponent
                        type={"repeatpassword"}
                        placeholder={["Создайте пароль", "Подтвердите пароль"]}
                        icon={true}
                        state={password}
                        setState={setPassword}
                        repeat={repeat}
                        setRepeat={setRepeat}
                        regex={/^.{6,10}$/}
                        gap={"15px"}
                        autocomplete={"off"}
                        errortext={["Пароль должен состоять из мин. 6-10 символов", "Пароли не совпадают"]}
                        setIs={setIsPassword}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                addHandler();
                            }
                        }}
                    />
                    <SelectComponent
                        placeholder={'Выберите роль'}
                        items={roleData}
                        state={role}
                        setState={selectRole}
                        loadingText={'Нет данных...'}
                    />
                    <SelectComponent
                        placeholder={'Выберите месторождение'}
                        items={bornData.filter((item: any) => !(role === 'Директор месторождения' || role === 'Оператор Весовой') || item.label !== 'Все')}
                        state={born}
                        disabled={born === 'Все'}
                        setState={setBorn}
                        loadingText={'Нет данных...'}
                    />
                    {apierror !== '' && <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{apierror}</span>}
                </div>
                <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                    <button
                        disabled={isDisabled}
                        onClick={() => { addHandler(); setIsDisabled(true) }}
                        className="tac" style={{
                            width: '100%',
                            cursor: isDisabled ? "not-allowed" : "pointer",
                            background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                            borderRadius: '16px',
                            padding: '10px 15px',
                        }}>
                        Добавить
                    </button>
                    <button
                        onClick={() => setPopup(false)}
                        className="cp tac"
                        style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </div>
    )
}