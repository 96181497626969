import { DatePicker } from "antd";

interface props {
    placeholder: string
    state: any
    setState: Function
    width?: any
    doSome?: Function
}

export const DatePickerComponent = ({ placeholder, state, setState, width = '100%', doSome }: props) => {
    return (
        <DatePicker
        style={{
            alignItems: 'center',
            width: width,
            gap: '8px',
            color: '#757575',
            borderRadius: '16px',
            border: '1px solid #E0E0E0',
            height: '49px',
        }}
        className='datePickerInput fz16'
        showTime
        value={state}
        onChange={(e) => {
            if (doSome) doSome()
            setState(e)
        }}
        format="DD.MM.YYYY"
        placeholder={`${placeholder}*`}
    />
    )
}