import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getCurrentUser,
  getCompanies,
  getDeactivatedOrFinishedRequests,
  getMaterials,
  getObjects,
  getOffCompanies,
  getOffMaterials,
  getOffObjects,
  getRequestDataByCurrentId,
  getTodayRequests,
  getUsers,
  getWeighings,
  postAddcompanies,
  postAddmaterials,
  postAddobject,
  postAddrequest,
  postAdduser,
  postAddweighings,
  putEditWeighings,
  retieWeighings,
  getWeighingsHistory,
  postUser,
  putEditcompanies,
  putEditmaterials,
  putEditobjects,
  putEditrequest,
  putEditrequestStatus,
  putEdituser,
  getBriefCases,
  postBriefCase,
  getBriefCase,
  postAddBriefCase,
  deleteBriefCase,
  getRequestReport,
  getSummaryInvoice,
  getNotifications,
  markNotificationAsRead,
  getElectronReport,
  getCompaniesWithFiltering,
  getWeighingByID,
} from 'api'

interface GeneralQueryParams {
  page?: number
  per_page?: number
  company_customer_name?: any, 
  deposit?: any, 
  status?: any,
  date_from?: any, 
  date_to?: any

}
// USERS
export const getUsersData: any = createAsyncThunk(
  'api/getPeople',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getUsers(token)
    return response.data
  }
)

export const getCurrentUserData: any = createAsyncThunk(
  'whoami',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getCurrentUser(token)
    return response.data
  }
)

export const postUserData = createAsyncThunk(
  'api/postUser',
  async (obj: any) => {
    const response = await postUser(obj)
    return response.data
  }
)

export const postAddUserData: any = createAsyncThunk(
  'api/postAdduser',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await postAdduser(obj, token)
    return response.data
  }
)

export const putEditUserData: any = createAsyncThunk(
  'api/putEdituser',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await putEdituser(obj, token)
    return response.data
  }
)

// COMPANIES
export const getCompaniesData: any = createAsyncThunk(
  'api/getCompanies',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getCompanies(token)
    return response.data
  }
)

export const getCompaniesWithFilteringData: any = createAsyncThunk(
  'api/getCompaniesWithFiltering',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await getCompaniesWithFiltering(obj, token)
    return response.data
  }
)

export const getOffCompaniesData: any = createAsyncThunk(
  'api/getOffCompanies',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getOffCompanies(token)
    return response.data
  }
)

export const postAddCompaniesData: any = createAsyncThunk(
  'api/postAddcompanies',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await postAddcompanies(obj, token)
    return response.data
  }
)

export const putEditCompaniesData: any = createAsyncThunk(
  'api/putEditcompanies',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await putEditcompanies(obj, token)
    return response.data
  }
)

// OBJECTS
export const getObjectsData: any = createAsyncThunk(
  'api/getObjects',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getObjects(token)
    return response.data
  }
)

export const getOffObjectsData: any = createAsyncThunk(
  'api/getOffObjects',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getOffObjects(token)
    return response.data
  }
)

export const postAddObjectsData: any = createAsyncThunk(
  'api/postAddobjects',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await postAddobject(obj, token)
    return response.data
  }
)

export const putEditObjectsData: any = createAsyncThunk(
  'api/putEditobjects',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await putEditobjects(obj, token)
    return response.data
  }
)

// Материалы
export const getMaterialsData: any = createAsyncThunk(
  'api/getMaterials',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getMaterials(token)
    return response.data
  }
)

export const getOffMaterialsData: any = createAsyncThunk(
  'api/getOffMaterials',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getOffMaterials(token)
    return response.data
  }
)

export const postAddmaterialsData: any = createAsyncThunk(
  'api/postAddmaterials',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await postAddmaterials(obj, token)
    return response.data
  }
)

export const putEditMaterialsData: any = createAsyncThunk(
  'api/putEditmaterials',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await putEditmaterials(obj, token)
    return response.data
  }
)

// REQUESTS

export const getDeactivatedOrFinishedRequestsData: any = createAsyncThunk(
  'api/getDeactivatedOrFinishedRequests',
  async ({ page, per_page = 0, company_customer_name, deposit, status, date_from, date_to  }: GeneralQueryParams = {}, { getState }) => {
    try {
      const token = selectToken(getState())
      const response = await getDeactivatedOrFinishedRequests(token, page, per_page, company_customer_name, deposit, status,date_from, date_to)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
      }
    }
  }
)

export const getRequestDataById: any = createAsyncThunk(
  'api/getRequestDataById',
  async (obj, { getState }) => {
    const token = selectToken(getState())
    const response = await getRequestDataByCurrentId(obj, token)
    return response.data
  }
)


export const getTodayRequestsData: any = createAsyncThunk(
  'api/getTodayRequestsData',
  async ({ page, per_page = 0, company_customer_name, deposit, status  }: GeneralQueryParams = {}, { getState }) => {
    try {
      const token = selectToken(getState())
      const response = await getTodayRequests(token, page, per_page, company_customer_name, deposit, status,)
      return response.data
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
      }
    }
  }
)

export const postAddrequestData: any = createAsyncThunk(
  'api/postAddrequest',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await postAddrequest(obj, token)
    return response.data
  }
)

export const putEditrequestStatusData: any = createAsyncThunk(
  'api/putEditrequestStatus',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await putEditrequestStatus(obj, token)
    return response.data
  }
)

export const putEditrequestData: any = createAsyncThunk(
  'api/putEditrequestData',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await putEditrequest(obj, token)
    return response.data
  }
)

// WEIGHINGS
export const getWeighingsData: any = createAsyncThunk(
  'api/getWeighingsData',
  async (obj, { getState }) => {
    let token: string | null = selectToken(getState())
    if (token === undefined) token = localStorage.getItem('authtoken')
    const response = await getWeighings(obj, token)
    return response.data
  }
)

export const getWeighingByIDData: any = createAsyncThunk(
  'api/getWeighingByID',
  async (id, { getState }) => {
    let token: string | null = selectToken(getState())
    if (token === undefined) token = localStorage.getItem('authtoken')
    const response = await getWeighingByID(id, token)
    return response.data
  }
)

export const postAddweighingsData: any = createAsyncThunk(
  'api/postAddweighings',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await postAddweighings(obj, token)
    return response.data
  }
)

export const putEditWeighingsData: any = createAsyncThunk(
  'api/putEditWeighingsData',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await putEditWeighings(obj, token)
    return response.data
  }
)

export const retieWeighingsData: any = createAsyncThunk(
  'api/retieWeighingsData',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await retieWeighings(obj, token)
    return response.data
  }
)

export const getHistoryWeighingsData: any = createAsyncThunk(
  'api/getWeighingsData',
  async (weigh_id: any, { getState }) => {
    const token = selectToken(getState())
    const response = await getWeighingsHistory(weigh_id, token)
    return response.data
  }
)

export const getElectronReportData: any = createAsyncThunk(
  'api/getElectronReport',
  async (weigh_id: any, { getState }) => {
    const token = selectToken(getState())
    const response = await getElectronReport(weigh_id, token)
    return response.data
  }
)

// BRIEFCASE
export const getBriefCasesData: any = createAsyncThunk(
  'api/getBriefCases',
  async (_, { getState }) => {
    const token = selectToken(getState())
    const response = await getBriefCases(token)
    return response.data
  }
)

export const getBriefCaseData: any = createAsyncThunk(
  'api/getBriefCase',
  async (file_id: any, { getState }) => {
    const token = selectToken(getState())
    const response = await getBriefCase(file_id, token)
    return response.data
  }
)

export const postBriefCaseData = createAsyncThunk(
  'api/postBriefCase',
  async (obj: any) => {
    const response = await postBriefCase(obj)
    return response.data
  }
)

export const postAddBriefCaseData: any = createAsyncThunk(
  'api/postAddBriefCase',
  async (obj: any, { getState }) => {
    const token = selectToken(getState())
    const response = await postAddBriefCase(obj, token)
    return response.data
  }
)

export const deleteBriefCaseData: any = createAsyncThunk(
  'api/deleteBriefCase',
  async (file_id: any, { getState }) => {
    const token = selectToken(getState())
    const response = await deleteBriefCase(file_id, token)
    return response.data
  }
)

// REQUEST REPORT
export const getRequestReportData: any = createAsyncThunk(
  'api/getRequestReport',
  async (obj, { getState }) => {
    const token = selectToken(getState())
    const response = await getRequestReport(obj, token)
    return response.data
  }
)

// SUMMARY INVOICE
export const getSummaryInvoiceData: any = createAsyncThunk(
  'api/getSummaryInvoice',
  async (obj, { getState }) => {
    const token = selectToken(getState())
    const response = await getSummaryInvoice(obj, token)
    return response.data
  }
)

// NOTIFICATIONS
export const getNotificationsData: any = createAsyncThunk(
  'api/getNotifications',
  async (obj, { getState }) => {
    const token = selectToken(getState())
    const response = await getNotifications(obj, token)
    return response.data
  }
)

export const markNotificationAsReadData: any = createAsyncThunk(
  'api/markNotificationAsRead',
  async (obj, { getState }) => {
    const token = selectToken(getState())
    const response = await markNotificationAsRead(obj, token)
    return response.data
  }
)

interface TState {
  data: any[] | undefined
  loading: boolean | undefined
  error: any | undefined
  token: any | undefined
  hide: boolean | undefined
  user: string | null | undefined
}

const initialState: TState = {
  data: undefined,
  loading: false,
  error: undefined,
  token: localStorage.getItem('authtoken'),
  hide: true,
  user: localStorage.getItem('user'),
}

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setHide: (state, action) => {
      state.hide = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersData.pending, (state) => {
        state.loading = true
        state.error = null
      })

      .addCase(getUsersData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })

      .addCase(getUsersData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      .addCase(setHide, (state, action) => {
        state.hide = action.payload
      })

      .addCase(setUser, (state, action) => {
        state.user = action.payload
      })
  },
})

export const { setToken, setHide, setUser } = apiSlice.actions
export const selectUser = (state: any) => state.api.user
export const selectHide = (state: any) => state.api.hide
export const selectToken = (state: any) => state.api.token
export default apiSlice.reducer
