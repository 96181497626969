import { LogoIcon } from 'assets/icons/Logo'
import config from 'config.json'

import { BookIcon } from 'assets/icons/Book'
import { BellIcon } from 'assets/icons/Bell'
import { FilesIcon } from 'assets/icons/Files'
import { ListIcon } from 'assets/icons/List'
import { BadgeIcon } from 'assets/icons/Badge'
import { BriefcaseIcon } from 'assets/icons/Briefcase'
import { ProfileBtn } from 'ui/ProfileBtn'
import { BurgerIcon } from 'assets/icons/BurgerIcon'
import { Link, useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCurrentUserData } from 'store/features/apiSlice'
import { parseRole } from 'constDatas'
import { setUser as setCurrentUser } from 'store/features/apiSlice'

import s from './index.module.scss'

export const SideBar = () => {
  const url = useLocation()
  const [user, setUser] = useState<any>(null)
  const [userFullName, setUserFullName] = useState('')
  const [userRole, setUserRole] = useState('')
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const [notificationCount, setNotificationCount] = useState('0')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const dispatch = useDispatch()

  // data of our nav btns
  const navigateBtns = [
    { icon: <BookIcon />, text: 'Журнал заявок', url: '/main/requests' },
    { icon: <BellIcon />, text: 'Уведомления', url: '/main/notifications' },
    { icon: <FilesIcon />, text: 'Документы', url: '/main/files' },
    { icon: <ListIcon />, text: 'Справочники', url: '/main/lists' },
    { icon: <BadgeIcon />, text: 'Пользователи', url: '/main/users' },
    { icon: <BriefcaseIcon />, text: 'Бухгалтерия', url: '/main/briefcase' },
  ]

  const updateLocalStorage = (key: string, value: any) => {
    const serializedValue =
      typeof value === 'object' ? JSON.stringify(value) : value
    localStorage.setItem(key, serializedValue)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const setUserAndLocalStorage = useCallback((requestData: any) => {
    setUserFullName(requestData?.payload?.data?.full_name)
    setUserRole(requestData?.payload?.data?.roles?.[0]?.role)
    setUser(requestData?.payload?.data)

    updateLocalStorage('user', requestData?.payload?.data)
    updateLocalStorage('userfullname', requestData?.payload?.data?.full_name)
    updateLocalStorage('userrole', requestData?.payload?.data?.roles?.[0]?.role)
    updateLocalStorage('userdeposit', requestData?.payload?.data?.deposit)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      loadNotificationCountFromLocalStorage()
    }, config.sidebar_notification_count_interval)

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId)
  }, [dispatch])

  async function loadNotificationCountFromLocalStorage() {
    const notificationCount = localStorage.getItem('notificationCount')

    if (notificationCount) {
      setNotificationCount(notificationCount)
    }
  }

  const loadUserFromLocalStorage = useCallback(() => {
    const localData = localStorage.getItem('user')
    const userFullName = localStorage.getItem('userfullname')
    const userRole = localStorage.getItem('userrole')

    if (localData) {
      const data = JSON.parse(localData)
      dispatch(setCurrentUser(data))
      setUser(data)
    }
    if (userFullName) {
      setUserFullName(userFullName)
    }
    if (userRole) {
      setUserRole(userRole)
    }
  }, [dispatch])

  const getUser = useCallback(async () => {
    const requestData = await dispatch(getCurrentUserData())
    setUserAndLocalStorage(requestData)
    loadUserFromLocalStorage()
  }, [dispatch, setUserAndLocalStorage, loadUserFromLocalStorage])

  useEffect(() => {
    getUser()
  }, [loadUserFromLocalStorage, getUser])

  const handleMenuItemClick = () => {
    setIsSidebarVisible(false)
  }

  return (
    <div className={s.container}>
      <div>
        <button
          className={`${s.mobilenavbtn}  ${!isSidebarVisible ? '' : s.hidden}`}
          onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        >
          <BurgerIcon />
        </button>
      </div>
      <div className={`${s.main} ${isSidebarVisible ? '' : s.hidden}`}>
        <div className={`${s.top} w100 df fdc`}>
          <LogoIcon width={'200'} height={'60'} />
          <div className={`${s.profilebox} w100`}>
            <span className="fz16 fw600">{userFullName || 'Loading...'}</span>
            <span className="fz14 fw400">
              {parseRole(userRole) || 'Loading...'}
            </span>
          </div>
        </div>

        <div className={`${s.list} df fdc`}>
          {navigateBtns.map((item, index) => {
            const commonItemsForUserRole =
              (config.commonItems as any)[userRole] || []
            const isCommonItem = commonItemsForUserRole.includes(item.text)

            return (
              (userRole === 'ROP' || isCommonItem) && (
                <Link
                  to={item.url}
                  className={`${s.item} df aic`}
                  key={index}
                  onClick={handleMenuItemClick}
                  style={{
                    background: url.pathname.includes(item.url)
                      ? '#F2C94C'
                      : '',
                  }}
                >
                  {item.icon}

                  {item.text === 'Уведомления' ? (
                    <div className="df fdr aic" style={{ gap: '29px' }}>
                      <span style={{ marginLeft: '8px' }}>{item.text}</span>
                      {parseInt(notificationCount) > 0 ? (
                        <span
                          className={`${s.notificationCount} df aic fz14`}
                          style={{
                            background: url.pathname.includes(item.url)
                              ? '#fff'
                              : '#D32929',
                            color: url.pathname.includes(item.url)
                              ? '#333'
                              : '#fff',
                          }}
                        >
                          {parseInt(notificationCount)}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <span style={{ marginLeft: '8px' }}>{item.text}</span>
                  )}
                </Link>
              )
            )
          })}
        </div>
        <div>{isMobile && <ProfileBtn />}</div>
      </div>
    </div>
  )
}
