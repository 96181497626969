interface props {
    width?: string
    height?: string
}

export const ToggleOn = ({ width = '56px', height = '28px' }: props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 56 28" fill="none">
            <rect width={width} height={height} rx="14" fill="#BDBDBD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.1667 24.5C20.9657 24.5 25.6667 19.799 25.6667 14C25.6667 8.20101 20.9657 3.5 15.1667 3.5C9.36776 3.5 4.66675 8.20101 4.66675 14C4.66675 19.799 9.36776 24.5 15.1667 24.5Z" fill="white" />
        </svg>
    )
}