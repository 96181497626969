interface Props {
  width?: string
  height?: string
}

export const DownloadIcon = ({ width = "24", height = '24' }: Props) => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M21 14.25V19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V14.25C3 14.0511 3.07902 13.8603 3.21967 13.7197C3.36032 13.579 3.55109 13.5 3.75 13.5C3.94891 13.5 4.13968 13.579 4.28033 13.7197C4.42098 13.8603 4.5 14.0511 4.5 14.25V19.5H19.5V14.25C19.5 14.0511 19.579 13.8603 19.7197 13.7197C19.8603 13.579 20.0511 13.5 20.25 13.5C20.4489 13.5 20.6397 13.579 20.7803 13.7197C20.921 13.8603 21 14.0511 21 14.25ZM11.4694 14.7806C11.539 14.8504 11.6217 14.9057 11.7128 14.9434C11.8038 14.9812 11.9014 15.0006 12 15.0006C12.0986 15.0006 12.1962 14.9812 12.2872 14.9434C12.3783 14.9057 12.461 14.8504 12.5306 14.7806L16.2806 11.0306C16.3503 10.9609 16.4056 10.8782 16.4433 10.7872C16.481 10.6961 16.5004 10.5985 16.5004 10.5C16.5004 10.4015 16.481 10.3039 16.4433 10.2128C16.4056 10.1218 16.3503 10.0391 16.2806 9.96937C16.2109 9.89969 16.1282 9.84442 16.0372 9.8067C15.9461 9.76899 15.8485 9.74958 15.75 9.74958C15.6515 9.74958 15.5539 9.76899 15.4628 9.8067C15.3718 9.84442 15.2891 9.89969 15.2194 9.96937L12.75 12.4397V3.75C12.75 3.55109 12.671 3.36032 12.5303 3.21967C12.3897 3.07902 12.1989 3 12 3C11.8011 3 11.6103 3.07902 11.4697 3.21967C11.329 3.36032 11.25 3.55109 11.25 3.75V12.4397L8.78063 9.96937C8.63989 9.82864 8.44902 9.74958 8.25 9.74958C8.05098 9.74958 7.86011 9.82864 7.71937 9.96937C7.57864 10.1101 7.49958 10.301 7.49958 10.5C7.49958 10.699 7.57864 10.8899 7.71937 11.0306L11.4694 14.7806Z" fill="#333333"/>
  </svg>
  )
}