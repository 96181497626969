interface props {
    size?: number
    color?: string
}

export const BookIcon = ({ size = 24, color = '#333333' }: props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={color}
        >
            <path d="M19.5 2.25H6.75C5.95435 2.25 5.19129 2.56607 4.62868 3.12868C4.06607 3.69129 3.75 4.45435 3.75 5.25V21C3.75 21.1989 3.82902 21.3897 3.96967 21.5303C4.11032 21.671 4.30109 21.75 4.5 21.75H18C18.1989 21.75 18.3897 21.671 18.5303 21.5303C18.671 21.3897 18.75 21.1989 18.75 21C18.75 20.8011 18.671 20.6103 18.5303 20.4697C18.3897 20.329 18.1989 20.25 18 20.25H5.25C5.25 19.8522 5.40804 19.4706 5.68934 19.1893C5.97064 18.908 6.35218 18.75 6.75 18.75H19.5C19.6989 18.75 19.8897 18.671 20.0303 18.5303C20.171 18.3897 20.25 18.1989 20.25 18V3C20.25 2.80109 20.171 2.61032 20.0303 2.46967C19.8897 2.32902 19.6989 2.25 19.5 2.25ZM11.25 3.75H15.75V10.5L13.9491 9.15C13.8192 9.05263 13.6613 9 13.4991 9C13.3368 9 13.1789 9.05263 13.0491 9.15L11.25 10.5V3.75ZM18.75 17.25H6.75C6.22326 17.2493 5.70572 17.388 5.25 17.6522V5.25C5.25 4.85218 5.40804 4.47064 5.68934 4.18934C5.97064 3.90804 6.35218 3.75 6.75 3.75H9.75V12C9.75 12.1393 9.78879 12.2758 9.86201 12.3943C9.93524 12.5128 10.04 12.6085 10.1646 12.6708C10.2892 12.7331 10.4286 12.7595 10.5674 12.747C10.7061 12.7345 10.8386 12.6836 10.95 12.6L13.5 10.6875L16.0509 12.6C16.1805 12.6972 16.338 12.7498 16.5 12.75C16.6989 12.75 16.8897 12.671 17.0303 12.5303C17.171 12.3897 17.25 12.1989 17.25 12V3.75H18.75V17.25Z" fill="#333333" />
        </svg>
    )
}
