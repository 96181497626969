import ReactDOM from 'react-dom/client'
import App from './App'
import './index.scss'
import store from 'store/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { SkeletonTheme } from 'react-loading-skeleton'
import { ConfigProvider } from 'antd'
import ru from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
dayjs.locale('ru')
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <ConfigProvider locale={ru}>
            <BrowserRouter>
                <SkeletonTheme baseColor="#F2F2F2" highlightColor="#e1e1e1">
                    <App />
                </SkeletonTheme>
            </BrowserRouter>
        </ConfigProvider>
    </Provider>
)
