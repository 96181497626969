import { useEffect, useState } from "react";

interface Props {
  min: number;
  max: number;
}

export const ProgressBar = ({ min, max }: Props) => {
  const [progress, setProgress] = useState(0);

  // Parse number with space format
  const numberSpaceFormat = (num: number): string => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Calculate percentage min of max
  const calculatePercentage = (num: number, denom: number): number => {
    if (num >= denom) {
      return 100;
    }
    return num ? Math.round((num / denom) * 100) : 0;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (min !== 0 && min !== undefined) {
          const nextProgress = prevProgress + 1;
          if (nextProgress >= calculatePercentage(min, max)) clearInterval(intervalId);
          return nextProgress;
        }
        return prevProgress;
      });
    }, Math.abs(100 - calculatePercentage(min, max))); // Adjusted interval duration
    return () => clearInterval(intervalId);
  }, [max, min]);

  return (
    <div
      className="progressBar posr df jcc aic w100"
    >
      <span className="posa">{`${numberSpaceFormat(min)} / ${numberSpaceFormat(max)}`}</span>
      <div className="w100 h100">
        <div
          style={{
            borderRadius: '8px',
            width: `${progress}%`,
            background: '#F2C94C',
            height: '36px',
            transition: 'width .2s ease',
          }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
