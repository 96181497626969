import { MainLayout } from 'layout/MainLayout'
import { NavigateToLogin } from 'navigatetologin'
import { BriefcasePage } from 'pages/Briefcase/BriefcasePage'
import { FilePage } from 'pages/File/FilePage'
import { ApplicationReport } from 'pages/File/components/ApplicationReport/ApplicationReport'
import { SummaryInvoice } from 'pages/File/components/SummaryInvoice/SummaryInvoice'
import { ListPage } from 'pages/List/ListPage'
import { Companies } from 'pages/List/components/Companies/Companies'
import { Materials } from 'pages/List/components/Materials/Materials'
import { Objects } from 'pages/List/components/Objects/Objects'
import { LoginPage } from 'pages/Login/LoginPage'
import { NotificationPage } from 'pages/Notification/NotificationPage'
import { RequestPage } from 'pages/Request/RequestPage'
import { RequestAddPage } from 'pages/RequestAdd/RequestAddPage'
import { RequestDetails } from 'pages/RequestDetails/RequestDetails'
import { RequestEditPage } from 'pages/RequestEdit/RequestEdit'
import { UserPage } from 'pages/User/UserPage'
import { useEffect } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'

const routes = {
  main: '/main',
  requests: '/main/requests',
  notifications: '/main/notifications',
  files: '/main/files',
  lists: '/main/lists',
  users: '/main/users',
  briefcase: '/main/briefcase',
  login: '/login'
};

const commonRoutes = [
  <Route key="requests" path={routes.requests} element={<Outlet />}>
    <Route path={routes.requests} element={<RequestPage />} />
    <Route path={`${routes.requests}/add`} element={<RequestAddPage />} />
    <Route path={`${routes.requests}/details/:id`} element={<RequestDetails />} />
    <Route path={`${routes.requests}/edit/:id`} element={<RequestEditPage />} />
  </Route>,
  <Route key="files" path={routes.files} element={<FilePage />}>
    <Route path={routes.files} element={<SummaryInvoice />} />
    <Route path={`${routes.files}/application-report`} element={<ApplicationReport />} />
  </Route>,
  <Route key="lists" path={routes.lists} element={<ListPage />}>
    <Route path={routes.lists} element={<Companies />} />
    <Route path={`${routes.lists}/objects`} element={<Objects />} />
    <Route path={`${routes.lists}/materials`} element={<Materials />} />
  </Route>
];

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('authtoken');
    if (!authToken) navigate('/login');
  }, [navigate]);

  return (
    <Routes>
      <Route path={routes.main} element={<MainLayout />}>
        {commonRoutes}
        <Route path={routes.notifications} element={<NotificationPage />} />
        <Route path={routes.users} element={<UserPage />} />
        <Route path={routes.briefcase} element={<BriefcasePage />} />
      </Route>
      <Route path={routes.login} element={<LoginPage />} />
      <Route path={''} element={<NavigateToLogin />} />
    </Routes>
  );
}

export default App;