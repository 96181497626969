import s from './index.module.scss'
import { InputComponent } from 'ui/InputComponent'
import { useCallback, useEffect, useState } from 'react';
import { SelectComponent } from 'ui/SelectComponent';
import { PlusIcon } from 'assets/icons/Plus';
import { GlobeIcon } from 'assets/icons/Globe';
import { EditObjectPopup } from './components/EditObjectPopup';
import { useDispatch, useSelector } from 'react-redux';
import { getCompaniesData, getObjectsData, getOffObjectsData, postAddObjectsData, selectHide } from 'store/features/apiSlice';
import { SkeletonComponent } from 'ui/SkeletonComponent';
import { RestoreObjectPopup } from './components/RestoreObjectPopup';
import { AddObjectPopup } from './components/AddObjectPopup';

export const Objects = () => {
    const [name, setName] = useState('');
    const [clientName, setClientName] = useState('');
    const [number, setNumber] = useState('');
    const [adressurl, setAdressurl] = useState('');
    const [errorapi, setErrorapi] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [adress, setAdress] = useState('');
    const [companyNameData, setCompanyNameData] = useState([]);
    const [addobjectpopup, setAddObjectPopup] = useState(false);
    const [editpopup, setEditpopup] = useState(false);
    const [selectedObject, setSelectedObject] = useState([]);
    const [selected, setSelected] = useState('');
    const [restorepopup, setRestorepopup] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [data2, setData2] = useState([]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const dispatch = useDispatch();
    const hide = useSelector(selectHide);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      // Добавляем слушатель события изменения размера окна
      window.addEventListener('resize', handleResize);
  
      // Убираем слушатель при размонтировании компонента
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 
  
    const getData = useCallback(async () => {
      setLoading(true);
      const objectsData = await dispatch(getObjectsData());
      setData2(objectsData?.payload?.data);
      setLoading(false);
    }, [dispatch]);
  
    const getOffObjects = useCallback(async () => {
      setLoading(true);
      const objectsData = await dispatch(getOffObjectsData());
      setData2(objectsData?.payload?.data?.slice());
      setLoading(false);
    }, [dispatch]);
  
    const getCompanies = useCallback(async () => {
      const companiesData = await dispatch(getCompaniesData());
      setCompanyNameData(companiesData?.payload?.data.reverse());
    }, [dispatch]);
  
    useEffect(() => {
      getData();
      getCompanies();
    }, [getData, getCompanies]);
  
    useEffect(() => {
      const isValid = isNumber && adressurl !== '' && name !== '' && clientName !== '' && adress !== '' && adress !== null && selectedCompany !== null;
      setIsDisabled(!isValid);
    }, [name, selectedCompany, adress, clientName, adressurl, isNumber]);
  
    const addObject = async () => {
      const selectedCompanyData:any = companyNameData.find((item: any) => item.name === selectedCompany);
      if (!selectedCompanyData) return;
  
      const obj = {
        name,
        company_id: selectedCompanyData?.company_id,
        address: adress,
        address_link: adressurl,
        client_number: number,
        client_name: clientName,
      };
  
      const response = await dispatch(postAddObjectsData(obj));
      if (response?.payload?.message === 'ok') {
        hide ? getData() : getOffObjects();
        setName('');
        setClientName('');
        setAdress('');
        setAdressurl('');
        setSelectedCompany(null);
        setNumber('');
        setIsDisabled(false);
      } else if (response?.error?.message.includes('409')) {
          setErrorapi('Такой материал уже существует')
          return
      } else if (response?.error?.message.includes('403')) {
          setErrorapi('Недостаточно прав для выполнения этого запроса.')
          return
      } else if (response?.error?.message.includes('401')) {
        setErrorapi('Недостаточно прав для выполнения этого запроса.')
        return
      } else {
          setErrorapi('Ошибка сервера')
          return
      }
    };
  
    useEffect(() => {
      hide ? getData() : getOffObjects();
    }, [hide, getData, getOffObjects]);

    const RenderDesktopObjects = (item: any, index: any) => (
      <div
      className={`${s.secondrow} cp w100 df aic fz16`}
      onClick={() => {
        setEditpopup(true);
        setSelected(item);
      }}
      key={index}
    >
      <span className='w20'>{item.name}</span>
      <span className='w20'>{item.company.name}</span>
      <span className='w30'>{item.client_name} {item.client_number}</span>
      <div className="df aic jcsb w30 textellipsis" style={{ gap: '5px'}}>
        {hide ? (
          <>
            <div className="df aic jcsb" style={{ gap: '5px' }}>
              <div>
              <GlobeIcon />
              </div>
              <div>
              <span>{item.address_link}</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="df aic w100" style={{ gap: '5px' }}>
              <div>
              <GlobeIcon />
              </div>
              <span style={{width: "300px"}}>{item.address_link}</span>
                <button
                className={`${s.recover} cp df jcc aic`}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedObject(item);
                  setRestorepopup(true);
                }}
              >
                Восстановить
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  ) 

  const RenderMobileObjects = (item: any, index: any) => (
      <div 
      className={s.mobileObjectsBlock}
      onClick={() => {
        setEditpopup(true);
        setSelected(item);
      }}
      key={index}>
        <div className={s.nameBlock}>
          <span className='fz16 fw600' style={{color: "#333"}}>{`${item?.name}`}</span>
        </div>
        <div className={s.infoBlock}>
          <span className='fz14 fw600' style={{color: "#828282"}}>{`Компания: ${item?.company?.name}`}</span>
          <span className='fz14 fw600' style={{color: "#828282"}}>{`Имя встречающего: ${item?.client_name}`}</span>
        </div>
        <div style={{borderBottom: "1px dashed #E0E0E0", height: "1px"}}></div>
        <div className={`${s.infoSecondBlock} df jcsb`}>
          <span className='fz14 fw600' style={{color: "#333"}}>{`${item?.client_number}`}</span>
          <span className='df fz14 fw600 aic textellipsis' style={{color: "#333", gap: '16px'}}>{`${item?.address}`} <GlobeIcon /></span>

        </div>
        {!hide ? (<div>
          <button className={`${s.recoverMobileBlock} cp df aic fz14 fw600`} onClick={(e) => { 
                  e.stopPropagation();
                  setRestorepopup(true);
                  setSelectedCompany(item) }}>
              Восстановить
          </button>
        </div>) : <></>}
      </div>
  ) 
  
    return (
      <>
        {addobjectpopup && (
          <AddObjectPopup
            selected={selected}
            popup={editpopup}
            setPopup={setAddObjectPopup}
            companyNames={companyNameData}
            refreshObjects={getData}
            refreshOffObjects={getOffObjects}
            hide={hide}
          />
        )}
        {editpopup && (
          <EditObjectPopup
            selected={selected}
            popup={editpopup}
            setPopup={setEditpopup}
            companyNames={companyNameData}
            refreshObjects={getData}
            refreshOffObjects={getOffObjects}
            hide={hide}
          />
        )}
        {restorepopup && (
          <RestoreObjectPopup
            title={'Восстановить объект?'}
            subtitle={'Подтвердите восстановление объекта'}
            popup={restorepopup}
            setPopup={setRestorepopup}
            selected={selectedObject}
            refreshObjects={getData}
            refreshOffObjects={getOffObjects}
            hide={hide}
          />
        )}
        <div className={s.main}>
          <div className={`df fdc`}>
            <div className={`${s.createFields} fww-mobile`} style={{ gap: '16px' }}>
              <InputComponent
                type={'default'}
                placeholder={'Наименование'}
                icon={false}
                autocomplete={'off'}
                state={name}
                setState={setName}
                maxLength={20}
              />
              <SelectComponent
                placeholder={'Компания'}
                items={companyNameData}
                state={selectedCompany}
                setState={setSelectedCompany}
                loadingText={'Нет данных...'}
              />
              <InputComponent
                type={'default'}
                placeholder={'Имя встречающего лица'}
                icon={false}
                autocomplete={'off'}
                state={clientName}
                setState={setClientName}
                maxLength={50}
              />
              <InputComponent
                type={'phone'}
                icon={false}
                placeholder={'Номер встречающего лица'}
                state={number}
                setState={setNumber}
                width={'100%'}
                setIs={setIsNumber}
              />
            </div>
            {/* ВТОРОЙ ROW */}
            <div className={`${s.createFields} aic fww-mobile`} style={{ gap: '15px', marginTop: '10px' }}>
              <InputComponent
                type={'default'}
                placeholder={'Ссылка на адрес'}
                icon={false}
                autocomplete={'off'}
                state={adressurl}
                setState={setAdressurl}
                maxLength={2000}
              />
              <InputComponent
                type={'default'}
                placeholder={'Адрес'}
                icon={false}
                autocomplete={'off'}
                state={adress}
                setState={setAdress}
                maxLength={50}
              />
              <button
                className={`${s.adduser} df jcc aic cp h100`}
                disabled={isDisabled}
                style={{
                  width: '100%',
                  height: '49px',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  background: isDisabled ? '#f2c94c94' : '#f2c94c',
                }}
                onClick={() => {
                  addObject();
                  setIsDisabled(true);
                }}
              >
                <PlusIcon />
                <span className="wsn">Добавить в справочник</span>
              </button>
              {errorapi !== '' && <span style={{ color: 'rgb(235, 87, 87)', marginLeft: '10px', fontSize: '14px' }}>{errorapi}</span>}
            </div>
          </div>
          <div className={`${s.row} w100 aic fz18 fw600`}>
            <span className='w20'>Наименование</span>
            <span className='w20'>Компании</span>
            <span className='w30'>Имя и номер встречающего</span>
            <span className='w30'>Адрес</span>
          </div>
          <div className={`${s.lists}`}>
            {loading ? (
              <>
                {data2.length !== 0 ? (
                  <>
                    {Array(data2.length).fill('').map((_, i: number) => (
                      <div style={{ height: '50px', borderRadius: '16px', marginTop: '5px' }} key={i}>
                        <SkeletonComponent height={'100%'} bradius={'16px'} />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {Array(7).fill('').map((_, i: number) => (
                      <div style={{ height: '50px', borderRadius: '16px', marginTop: '5px' }} key={i}>
                        <SkeletonComponent height={'100%'} bradius={'16px'} />
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                {data2.map((item, index) => (isMobile ? RenderMobileObjects(item, index) : RenderDesktopObjects(item, index)))}
              </>
            )}
          </div>
          {isMobile && !addobjectpopup && !editpopup && !restorepopup && (
              <div className={`${s.mobileadd} cp df jcc aic`} onClick={() => setAddObjectPopup(true)}>
                  <PlusIcon color={'black'} />
                  <span className='fw700 fz16'>Добавить в справочник</span>
              </div>
              )}
        </div>
      </>
    );
  };