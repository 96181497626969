import { useEffect, useState, useRef } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { getRequestReportData } from 'store/features/apiSlice'
import { DatePicker } from 'antd'

import * as XLSX from 'xlsx'

import dayjs from 'dayjs'

import s from './index.module.scss'
import { SelectMultipleComponent } from 'ui/SelectMultipleComponent'
import { bornData, parseBorn } from 'constDatas'

interface BornDataItem {
  key: string
  label: string
}

export const ApplicationReport = () => {
  const currentDateTime = dayjs()

  const [errorapi, setErrorapi] = useState('')

  const [isDisabled, setIsDisabled] = useState(true)
  const [_, setForceUpdate] = useState(0)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const dateFromRef = useRef(currentDateTime.subtract(24, 'hour'))
  const dateToRef = useRef(currentDateTime)

  const [born, setBorn] = useState<null | Array<any>>(null)

  const dispatch = useDispatch()

  const filteredBornData: BornDataItem[] = bornData.filter(
    (item: BornDataItem) => item.label !== 'Все'
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  async function downloadApplicationReportButton(
    response: any,
    date_from: any,
    date_to: any
  ) {
    const byteCharacters = atob(response?.payload?.data?.file)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const arrayBuffer = byteArray.buffer

    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const downloadLink = document.createElement('a')
    downloadLink.href = window.URL.createObjectURL(blob)
    downloadLink.download = `${date_from}${date_to}`

    downloadLink.click()

    // // Используем библиотеку xlsx для обработки данных
    // const workbook = XLSX.read(arrayBuffer, { type: 'array', raw: true });

    // // Создаем новый блок div для отображения содержимого файла
    // const contentDiv = document.createElement('div');

    // // Преобразуем данные файла в HTML-таблицу
    // const htmlContent = XLSX.write(workbook, { bookType: 'html', bookSST: false, type: 'string' });
    // contentDiv.innerHTML = htmlContent;

    // // Открываем новое окно для печати
    // const printWindow:any = window.open('', '_blank');

    // // Копируем содержимое блока div в окно печати
    // printWindow.document.body.appendChild(contentDiv);

    // printWindow.onafterprint = function () {
    //     printWindow.close();
    // };

    // // Вызываем window.print() в контексте printWindow
    // printWindow.print();
  }

  async function getRequestReport() {
    const obj = {
      date_from: dateFromRef.current
        ? dateFromRef.current.format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      date_to: dateToRef.current
        ? dateToRef.current.format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      deposit: born?.map((item: any) => parseBorn(item)),
    }

    const response = await dispatch(getRequestReportData(obj))
    if (response?.payload?.message === 'ok') {
      downloadApplicationReportButton(response, obj.date_from, obj.date_to)
      setIsDisabled(false)
    } else if (response?.error?.message.includes('403')) {
      setErrorapi('Недостаточно прав для выполнения этого запроса.')
      return
    } else if (response?.error?.message.includes('404')) {
      setErrorapi('По указанной дате отвесы не найдены')
      return
    } else {
      setErrorapi('Ошибка сервера')
      return
    }
  }

  useEffect(() => {
    if (dateFromRef.current !== null && dateToRef.current !== null)
      setIsDisabled(false)
    else setIsDisabled(true)
  }, [dateFromRef.current, dateToRef.current])

  return (
    <>
      <div className={s.main}>
        <div className={`${s.requestfiltering}`}>
          <div style={{ marginBottom: '12px' }}>
            <span
              className="fw700 fz18"
              style={{ color: '#333', lineHeight: 'normal' }}
            >
              Фильтры
            </span>
          </div>

          <div className="df fdc fdc-aic-mobile" style={{ gap: '20px' }}>
            <div
              style={{
                maxWidth: '344px',
                width: '100%',
              }}
            >
              <SelectMultipleComponent
                placeholder="Месторождение"
                items={filteredBornData}
                state={born}
                setState={setBorn}
              />
            </div>

            <div
              className={`${s.requestDatePickerBlock} df w100 fdc-aic-mobile`}
              style={{ gap: '16px' }}
            >
              <DatePicker
                style={{
                  maxWidth: '344px',
                  width: '100%',
                  height: '56px',
                  padding: '16px 16px 16px 12px',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '16px',
                  border: '1px solid #E0E0E0',
                }}
                showTime
                className="datePickerInput fz16"
                format="HH:mm - DD.MM.YYYY"
                defaultValue={dateFromRef.current}
                value={dateFromRef.current}
                onChange={(dateFrom: any) => {
                  dateFromRef.current = dateFrom
                  setForceUpdate((prev) => prev + 1)
                  setErrorapi('')
                }}
                placeholder="Начало отчетности"
              />
              <DatePicker
                style={{
                  maxWidth: '344px',
                  width: '100%',
                  height: '56px',
                  padding: '16px 16px 16px 12px',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '16px',
                  border: '1px solid #E0E0E0',
                }}
                showTime
                className="datePickerInput fz16"
                format="HH:mm - DD.MM.YYYY"
                value={dateToRef.current}
                defaultValue={dateToRef.current}
                onChange={(dateTo: any) => {
                  dateToRef.current = dateTo
                  setForceUpdate((prev) => prev + 1)
                  setErrorapi('')
                }}
                placeholder="Конец отчетности"
              />
            </div>
            {errorapi !== '' && (
              <span
                style={{
                  color: 'rgb(235, 87, 87)',
                  marginLeft: '10px',
                  fontSize: '14px',
                }}
              >
                {errorapi}
              </span>
            )}
            <div className={`${s.applicationReportButtons} df w100`}>
              <button
                className={`${s.applybutton} df jcc aic hover`}
                onClick={() => {
                  getRequestReport()
                }}
                disabled={isDisabled}
                style={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                }}
              >
                <span className="wsn">Сформировать</span>
              </button>
              <button
                className={`${s.resetbutton} df jcc aic hover`}
                onClick={() => {
                  dateFromRef.current = currentDateTime.subtract(24, 'hour')
                  dateToRef.current = currentDateTime
                  setForceUpdate((prev) => prev + 1)
                }}
              >
                <span className="wsn">Сбросить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
