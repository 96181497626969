interface Props {
    height?: number
    width?: number
  }
  
  export const RequestPriorityStar = ({ height = 18, width = 18 }: Props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none">
        <path d="M16.4884 8.0422L13.3173 10.8097L14.2672 14.93C14.3175 15.1454 14.3031 15.3708 14.226 15.5781C14.1488 15.7854 14.0123 15.9654 13.8334 16.0955C13.6545 16.2256 13.4413 16.3001 13.2203 16.3096C12.9993 16.3192 12.7804 16.2634 12.591 16.1492L8.9973 13.9695L5.41136 16.1492C5.22192 16.2634 5.00303 16.3192 4.78205 16.3096C4.56107 16.3001 4.34781 16.2256 4.16894 16.0955C3.99006 15.9654 3.85351 15.7854 3.77635 15.5781C3.69919 15.3708 3.68485 15.1454 3.73511 14.93L4.68363 10.8139L1.51183 8.0422C1.34407 7.89752 1.22276 7.70652 1.16312 7.49317C1.10348 7.27981 1.10815 7.0536 1.17656 6.84289C1.24497 6.63218 1.37407 6.44636 1.54766 6.30873C1.72126 6.1711 1.93162 6.08779 2.15238 6.06923L6.33316 5.70712L7.96511 1.81463C8.05034 1.61038 8.19409 1.43591 8.37826 1.3132C8.56243 1.19048 8.7788 1.125 9.00011 1.125C9.22143 1.125 9.43779 1.19048 9.62197 1.3132C9.80614 1.43591 9.94989 1.61038 10.0351 1.81463L11.672 5.70712L15.8514 6.06923C16.0721 6.08779 16.2825 6.1711 16.4561 6.30873C16.6297 6.44636 16.7588 6.63218 16.8272 6.84289C16.8956 7.0536 16.9003 7.27981 16.8406 7.49317C16.781 7.70652 16.6597 7.89752 16.4919 8.0422H16.4884Z" fill="#EFB810"/>
      </svg>
    )
  }
  