import { LogoIcon } from 'assets/icons/Logo'
import React, { useState, useEffect } from 'react';
import { parseCompanyType, parseShip } from 'constDatas';

interface props {
    weighData: any
}

export const RSComponent = ({weighData}: props) => {
    
    return (
        <>
          <div className="firstBlock df fdr jcsa">
            <div className="leftBlock">
              <div>
                <LogoIcon width={'100'} height={'35'} />
              </div>
              <ul>
                <li>Накладная №:{weighData?.id}</li>
                <li>Дата взвешивания: {weighData?.rq_date_create}</li>
                <li>Кому: {parseCompanyType(weighData?.requisition?.company_customer?.company_type)} {weighData?.requisition?.company_customer?.name}</li>
                <li>Продукт: {weighData?.requisition?.material?.name}</li>
                <li>Гос.номер: {weighData?.car_number}</li>
                <li>Тара: {weighData?.weight_tare}т</li>
                <li>Брутто: {weighData?.weight_gross}т</li>
                <li>Нетто: {weighData?.weight_netto}т</li>
                <li>Тип перевозки: {parseShip(weighData?.requisition?.shipping_type)}</li>
                <li>Оператор: {weighData?.operator_name}</li>
                <br /><br />
                <li>Отсканируйте QR-код ниже для<br />перехода в 2ГИС</li>
                <li>{weighData?.requisition?.object?.address}</li>
              </ul>
              <div>
              <img src={`data:image/png;base64,${weighData?.address_link_qr}`} className="qr" alt="QR code" />
              </div>
            </div>
            <hr />
            <div className="rightBlock">
              <ul>
                <li>Накладная №:{weighData?.id}</li>
                <li>Дата: {weighData?.rq_date_create}</li>
                <li>Гос.номер: {weighData?.car_number}</li>
                <li>Продукт: {weighData?.requisition?.material?.name}</li>
                <li>Вес: {weighData?.weight_netto}т</li>
                <li>Кому: {parseCompanyType(weighData?.requisition?.company_customer?.company_type)} {weighData?.requisition?.company_customer?.name}</li>
                <li>Объект: {weighData?.requisition?.object?.name}</li>
                <br /><br /><br />
                <li>Водитель __________</li>
                <li>Подпись __________</li>
                <li>Адрес: {weighData?.requisition?.object?.address}</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="secondBlock df fdr jcsa">
            <div className="leftBlock">
              <div>
                <LogoIcon width={'100'} height={'35'} />
              </div>
              <ul>
                <li>Накладная №:{weighData?.id}</li>
                <li>Дата взвешивания: {weighData?.rq_date_create}</li>
                <li>Кому: {parseCompanyType(weighData?.requisition?.company_customer?.company_type)} {weighData?.requisition?.company_customer?.name}</li>
                <li>Продукт: {weighData?.requisition?.material?.name}</li>
                <li>Гос.номер: {weighData?.car_number}</li>
                <li>Тара: {weighData?.weight_tare}т</li>
                <li>Брутто: {weighData?.weight_gross}т</li>
                <li>Нетто: {weighData?.weight_netto}т</li>
                <li>Тип перевозки: {parseShip(weighData?.requisition?.shipping_type)}</li>
                <li>Оператор: {weighData?.operator_name}</li>
                <li><strong>Заказывайте инертные материалы на</strong></li>
                <li><strong><a href="https://5-20.kz" target="_blank">https://5-20.kz</a></strong></li>
                <li><strong>Или по номеру +77753038325</strong></li>
              </ul>
            </div>
            <hr />
            <div className="rightBlock">
              <ul>
                <li>Накладная №:{weighData?.id}</li>
                <li>Дата: {weighData?.rq_date_create}</li>
                <li>Гос.номер: {weighData?.car_number}</li>
                <li>Продукт: {weighData?.requisition?.material?.name}</li>
                <li>Гос.номер: {weighData?.car_number}</li>
                <li>Вес: {weighData?.weight_netto}т</li>
                <li>Кому: {parseCompanyType(weighData?.requisition?.company_customer?.company_type)} {weighData?.requisition?.company_customer?.name}</li>
                <li>Объект: {weighData?.requisition?.object?.name}</li>
                <br /><br /><br />
                <li>Водитель __________</li>
                <li>Подпись __________</li>
                <li>Адрес: {weighData?.requisition?.object?.address}</li>
              </ul>
            </div>
          </div>
        </>
      );
}