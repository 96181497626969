import { SelectComponent } from 'ui/SelectComponent';
import { CloseIcon } from "assets/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { putEditUserData } from "store/features/apiSlice";
import { InputComponent } from "ui/InputComponent";
import { roleData, bornData, parseRole, parseBorn } from "constDatas";

interface props {
    selected?: any
    popup: boolean
    setPopup: Function
    refreshData?: any
}

export const EditPopup = ({ selected, popup, setPopup, refreshData }: props) => {
    const [role, setRole]: any = useState(selected ? parseRole(selected?.roles?.[0]?.role) : '');
    const [born, setBorn]: any = useState(selected ? parseBorn(selected?.deposit) : '');
    const [fullname, setFullname] = useState(selected ? selected?.full_name : '');
    const [login, setLogin] = useState(selected ? selected?.username : '');
    const [password, setPassword] = useState('');
    const [repeat, setRepeat] = useState('');
    const [isFullname, setIsFullname] = useState(false);
    const [isUsername, setIsUsername] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDelete, setIsDelete] = useState(false);
    const [errortext, setErrortext] = useState('');
    const [want, setWant] = useState(false);
    const [isPassword, setIsPassword] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        if (isFullname && isUsername && role != null && born != null) setIsDisabled(false)
    }, [isFullname, isUsername, role, born]);

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden';
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    const editUser = async () => {
        setIsDisabled(true)
        const { active, is_online, last_online, roles, deposit, all_notifications_last, all_unread_notifications_all, all_unread_notifications_last, ...obj2 } = selected;
        obj2.user_id = obj2.id
        const { id, ...obj } = obj2
        obj.full_name = fullname
        obj.username = login.trim()
        if (password) {obj.password = password}
        obj.role = parseRole(role)
        obj.deposit = parseBorn(born)
        const response = await dispatch(putEditUserData(obj))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                refreshData()
            }, 500);
            setPopup(false)
            setIsDisabled(false)
        } else if (response?.error?.message.includes('409')) {
            setErrortext('Пользователь уже существует')
            return
        } else if (response?.error?.message.includes('401')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('403')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message?.includes("400")) {
            setErrortext("Вы не можете удалить самого себя")
            return
        }else {
            setErrortext('Ошибка сервера')
            return
        }
    }

    const deleteUser = async () => {
        setIsDelete(true)
        const { is_online, last_online, roles, deposit, full_name, username, all_notifications_last, all_unread_notifications_all, all_unread_notifications_last, ...obj2 } = selected;
        obj2.active = false
        obj2.user_id = obj2.id
        const { id, ...obj } = obj2
        const response = await dispatch(putEditUserData(obj))
        if (response?.payload?.message === 'ok') {
            setTimeout(() => {
                refreshData()
            }, 500);
            setPopup(false);
            setIsDelete(false)
        } else if (response?.error?.message.includes('409')) {
            setErrortext('Пользователь уже существует')
            return
        } else if (response?.error?.message.includes('401')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('403')) {
            setErrortext('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message?.includes("400")) {
            setErrortext("Вы не можете удалить самого себя")
            return
        }else {
            setErrortext('Ошибка сервера')
            return
        }
    }

    const selectRole = (e: string) => {
        setRole(e)
        if (e === 'Менеджер Отдела Сбыта' ||
            e === 'Ст. Менеджер Отдела Сбыта' ||
            e === 'Учредитель' ||
            e === 'Бухгалтер'
        ) setBorn(parseRole("All"))
        else setBorn(null)
    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>
                        {want ? "Удалить пользователя?" : "Редактировать пользователя"}
                    </span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                {!want ? (
                    <>
                        <div className="df fdc" style={{ gap: '15px' }}>
                            <InputComponent
                                type={"default"}
                                placeholder={"Введите ФИО"}
                                icon={false}
                                state={fullname}
                                setState={setFullname}
                                autocomplete={"off"}
                                setIs={setIsFullname}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        editUser();
                                    }
                                }}
                            />
                            <InputComponent
                                type={"default"}
                                placeholder={"Изменить логин"}
                                icon={false}
                                state={login}
                                setState={setLogin}
                                setIs={setIsUsername}
                                regex={/^.{3,}$/}
                                autocomplete={"off"}
                                errortext={"Логин слишком короткий"}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        editUser();
                                    }
                                }}
                            />
                    <InputComponent
                        type={"repeatpassword"}
                        placeholder={["Создайте пароль", "Подтвердите пароль"]}
                        icon={true}
                        state={password}
                        setState={setPassword}
                        repeat={repeat}
                        setRepeat={setRepeat}
                        regex={/^.{6,10}$/}
                        gap={"15px"}
                        autocomplete={"off"}
                        errortext={["Пароль должен состоять из мин. 6-10 символов", "Пароли не совпадают"]}
                        setIs={setIsPassword}
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                editUser();
                            }
                        }}
                    />
                            <SelectComponent
                                placeholder={'Выберите роль'}
                                items={roleData}
                                state={role}
                                setState={selectRole}
                                loadingText={'Нет данных...'}
                            />
                            <SelectComponent
                                placeholder={'Выберите месторождение'}
                                items={bornData.filter((item: any) => !(role === 'Директор месторождения' || role === 'Оператор Весовой') || item.label !== 'Все')}
                                state={born}
                                disabled={born === 'Все'}
                                setState={setBorn}
                                loadingText={'Нет данных...'}
                            />
                            {errortext !== '' && (
                                <div className="w100 df jcc aic" style={{ marginTop: '5px' }}>
                                    <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{errortext}</span>
                                </div>
                            )}
                        </div>
                        <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                            <button
                                disabled={isDisabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!isDisabled) editUser()
                                }}
                                className="tac"
                                style={{
                                    width: '100%',
                                    cursor: isDisabled ? "not-allowed" : "pointer",
                                    background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                }}
                            >
                                Сохранить
                            </button>
                            {selected?.active && <div
                                onClick={() => setWant(true)}
                                className="cp tac"
                                style={{
                                    width: '100%',
                                    border: '1px solid #D32929',
                                    borderRadius: '16px',
                                    padding: '10px 15px',
                                    color: '#D32929'
                                }}
                            >
                                Удалить
                            </div>}
                        </div>
                    </>
                ) : (
                    <>
                        <span>Подтвердите удаление пользователя</span>
                        <div>
                            <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                                <button
                                    disabled={isDelete}
                                    onClick={() => deleteUser()}
                                    className="cp tac"
                                    style={{
                                        width: '100%',
                                        border: '1px solid #D32929',
                                        borderRadius: '16px',
                                        padding: '10px 15px',
                                        color: '#D32929',
                                        cursor: isDelete ? "not-allowed" : "pointer"
                                    }}
                                >
                                    Удалить
                                </button>
                                <div
                                    onClick={() => setWant(false)}
                                    className="cp tac"
                                    style={{
                                        width: '100%',
                                        border: '1px solid rgb(51, 51, 51)',
                                        borderRadius: '16px',
                                        padding: '10px 15px',
                                        color: 'rgb(51, 51, 51)'
                                    }}
                                >
                                    Нет
                                </div>
                            </div>
                            {errortext !== '' && (
                                <div className="w100 df jcc aic" style={{ marginTop: '5px' }}>
                                    <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{errortext}</span>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}