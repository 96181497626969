import { DatePicker } from 'antd'
import { CloseIcon } from 'assets/icons/Close'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { InputComponent } from 'ui/InputComponent'
import { putEditWeighingsData } from 'store/features/apiSlice'
import { exportWeighingType, parseShip } from 'constDatas'
import dayjs from 'dayjs'

import config from 'config.json'
import { SelectComponent } from 'ui/SelectComponent'

interface props {
  selected?: any
  popup: boolean
  setPopup: Function
  refreshData?: any
  selectedWeighing: any
}

export const EditWeighingsPopup = ({
  selected,
  popup,
  setPopup,
  refreshData,
  selectedWeighing,
}: props) => {
  const [fullname, setFullname] = useState(
    selectedWeighing ? selectedWeighing?.operator_name : ''
  )
  const [carnumber, setCarnumber] = useState(
    selectedWeighing ? selectedWeighing?.car_number : ''
  )
  const [tara, setTara] = useState(
    selectedWeighing ? selectedWeighing?.weight_tare : ''
  )
  const [brutto, setBrutto] = useState(
    selectedWeighing ? selectedWeighing?.weight_gross : ''
  )
  const [netto, setNetto] = useState(
    selectedWeighing ? selectedWeighing?.weight_netto : ''
  )
  const [date, setDate]: any = useState(
    selectedWeighing ? dayjs(selectedWeighing?.rq_date_create) : ''
  )
  const [cubature, setCubature] = useState(
    selectedWeighing ? selectedWeighing?.cubic_capacity : ''
  )
  const [comment, setComment] = useState('')
  const [userRole, setUserRole] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isTons] = useState(selected?.unit_type === 'Tons' ? true : false)
  const [errortext, setErrortext] = useState('')

  const [tsWeighingID, setTsWeighingID] = useState(
    selectedWeighing ? selectedWeighing?.ts_weighing_id : ''
  )

  const [typeexport, setTypeexport] = useState(
    selectedWeighing ? parseShip(selectedWeighing?.shipping_weighing_type) : ''
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (isTons) {
      if (
        fullname !== '' &&
        date !== null &&
        /^[0-9.]+$/.test(tara) &&
        /^[0-9.]+$/.test(brutto) &&
        /^[0-9.]+$/.test(netto)
      )
        setIsDisabled(false)
      else setIsDisabled(true)
    } else {
      if (fullname !== '' && date !== null && /^[0-9.]+$/.test(cubature))
        setIsDisabled(false)
      else setIsDisabled(true)
    }
  }, [fullname, carnumber, tara, brutto, netto, comment, cubature, date])

  useEffect(() => {
    const userRole = localStorage.getItem('userrole')

    if (userRole) {
      setUserRole(userRole)
    }
  }, [userRole])

  useEffect(() => {
    if (popup) document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [popup])

  async function editWeighing() {
    if (isTons) {
      setIsDisabled(true)
      const { created_on, status, ts_weighing_id, ...obj2 } = selectedWeighing
      obj2.weigh_id = obj2.id
      const { id, ...obj } = obj2
      delete obj.created_by
      obj.desc = comment
      obj.car_number = carnumber
      obj.weight_tare = tara
      obj.weight_gross = brutto
      obj.weight_netto = netto
      obj.rq_date_create = dayjs(date).format('YYYY-MM-DD HH:mm:ss')
      obj.operator_name = fullname
      obj.ts_weighing_id = tsWeighingID || undefined
      obj.shipping_weighing_type = parseShip(typeexport) || undefined
      if (config.edit_weigh_tons_in_tons.includes(userRole)) {
        const response = await dispatch(putEditWeighingsData(obj))
        if (response?.payload?.message === 'ok') {
          setTimeout(() => {
            refreshData()
          }, 500)
          setPopup(false)
          setIsDisabled(false)
        } else if (response?.error?.message.includes('401')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else if (response?.error?.message.includes('403')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else {
          setErrortext('Ошибка сервера')
          return
        }
      } else {
        setErrortext('Недостаточно прав для выполнения этого запроса.')
        return
      }
    } else {
      if (config.edit_weigh_cubes_in_cubes.includes(userRole)) {
        setIsDisabled(true)
        const { created_on, status, ts_weighing_id, ...obj2 } = selectedWeighing
        obj2.weigh_id = obj2.id
        const { id, ...obj } = obj2
        delete obj.created_by
        obj.desc = comment
        obj.car_number = carnumber
        obj.cubic_capacity = cubature
        obj.weight_netto = netto
        obj.rq_date_create = dayjs(date).format('YYYY-MM-DD HH:mm:ss')
        obj.operator_name = fullname
        obj.ts_weighing_id = tsWeighingID || undefined
        obj.shipping_weighing_type = parseShip(typeexport) || undefined
        const response = await dispatch(putEditWeighingsData(obj))
        if (response?.payload?.message === 'ok') {
          setTimeout(() => {
            refreshData()
          }, 500)
          setPopup(false)
          setIsDisabled(false)
        } else if (response?.error?.message.includes('403')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else if (response?.error?.message.includes('401')) {
          setErrortext('Недостаточно прав для выполнения этого запроса.')
          return
        } else {
          setErrortext('Ошибка сервера')
          return
        }
      } else {
        setErrortext('Недостаточно прав для выполнения этого запроса.')
        return
      }
    }
  }

  return (
    <div
      className="h100vh posf t0 l0 w100 df jcc aic"
      style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}
    >
      <div
        className="df fdc alfs w100"
        style={{
          overflowY: 'auto',
          maxHeight: '100vh',
          background: 'white',
          borderRadius: '30px',
          padding: '20px',
          gap: '20px',
          maxWidth: '428px',
        }}
      >
        <div className="df jcsb aic">
          <span style={{ fontSize: '20px', marginRight: '15px' }}>
            Редактировать отвес
          </span>
          <div className="df jcc aic cp" onClick={() => setPopup(false)}>
            <CloseIcon />
          </div>
        </div>
        <>
          <div className="df fdc" style={{ gap: '15px' }}>
            {isTons ? (
              <>
                <InputComponent
                  type={'default'}
                  placeholder={'Введите ФИО'}
                  icon={false}
                  state={fullname}
                  setState={setFullname}
                  autocomplete={'off'}
                  maxLength={30}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'Гос.номер транспорта'}
                  icon={false}
                  state={carnumber}
                  setState={setCarnumber}
                  regex={/^[a-zA-Z0-9]+$/}
                  errortext={'Некорректное значение'}
                  autocomplete={'off'}
                  maxLength={50}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'Тара'}
                  icon={false}
                  state={tara}
                  setState={setTara}
                  regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                  errortext={'Некорректное значение'}
                  autocomplete={'off'}
                  maxLength={20}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'Брутто'}
                  icon={false}
                  state={brutto}
                  setState={setBrutto}
                  regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                  errortext={'Некорректное значение'}
                  autocomplete={'off'}
                  maxLength={20}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'Нетто'}
                  icon={false}
                  state={netto}
                  setState={setNetto}
                  regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                  errortext={'Некорректное значение'}
                  autocomplete={'off'}
                  maxLength={20}
                />
                <DatePicker
                  style={{
                    borderRadius: '16px',
                    height: '49px',
                    paddingLeft: '18px',
                    fontFamily: 'inherit',
                  }}
                  showTime
                  format="DD-MM-YYYY HH:mm:ss"
                  className="datePickerWeighingsInput"
                  value={date}
                  onChange={(selectedDate: any) => setDate(selectedDate)}
                  placeholder="Дата - время отвеса"
                />
                <SelectComponent
                  placeholder="Тип перевозки отвеса"
                  items={exportWeighingType}
                  state={typeexport}
                  setState={setTypeexport}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'TS ID'}
                  icon={false}
                  state={tsWeighingID}
                  setState={setTsWeighingID}
                  autocomplete={'off'}
                  regex={/^\d+$/}
                  errortext={'Неккоректное значение'}
                  maxLength={100}
                />
                <InputComponent
                  type={'textarea'}
                  placeholder={'Примечание'}
                  icon={false}
                  state={comment}
                  setState={setComment}
                  maxLength={500}
                />
              </>
            ) : (
              <>
                <InputComponent
                  type={'default'}
                  placeholder={'Введите ФИО'}
                  icon={false}
                  state={fullname}
                  setState={setFullname}
                  autocomplete={'off'}
                  maxLength={30}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'Гос.номер транспорта'}
                  icon={false}
                  state={carnumber}
                  setState={setCarnumber}
                  regex={/^[a-zA-Z0-9]+$/}
                  errortext={'Некорректное значение'}
                  autocomplete={'off'}
                  maxLength={50}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'Кубатура'}
                  icon={false}
                  state={cubature}
                  setState={setCubature}
                  regex={/^(?!0\d)(\d+(\.\d+)?)$/}
                  errortext={'Некорректное значение'}
                  autocomplete={'off'}
                  maxLength={20}
                />
                <DatePicker
                  style={{
                    borderRadius: '16px',
                    height: '49px',
                    paddingLeft: '18px',
                    fontFamily: 'inherit',
                  }}
                  showTime
                  className="datePickerWeighingsInput"
                  format="DD-MM-YYYY HH:mm:ss"
                  value={date}
                  onChange={(selectedDate: any) => setDate(selectedDate)}
                  placeholder="Дата - время отвеса"
                />
                <SelectComponent
                  placeholder="Тип перевозки отвеса"
                  items={exportWeighingType}
                  state={typeexport}
                  setState={setTypeexport}
                />
                <InputComponent
                  type={'default'}
                  placeholder={'TS ID'}
                  icon={false}
                  state={tsWeighingID}
                  setState={setTsWeighingID}
                  autocomplete={'off'}
                  regex={/^\d+$/}
                  errortext={'Неккоректное значение'}
                  maxLength={100}
                />
                <InputComponent
                  type={'textarea'}
                  placeholder={'Примечание'}
                  icon={false}
                  state={comment}
                  setState={setComment}
                  maxLength={500}
                />
              </>
            )}
            {errortext !== '' && (
              <span className="fz14 w100" style={{ color: '#EB5757' }}>
                {errortext}
              </span>
            )}
          </div>
          <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
            <button
              disabled={isDisabled}
              onClick={(e) => {
                e.stopPropagation()
                if (!isDisabled) editWeighing()
              }}
              className="tac"
              style={{
                width: '100%',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                background: isDisabled ? 'rgb(242 201 76 / 50%)' : '#F2C94C',
                borderRadius: '16px',
                padding: '10px 15px',
              }}
            >
              Изменить
            </button>
            <button
              onClick={() => setPopup(false)}
              className="cp tac w100"
              style={{
                width: '100%',
                border: '1px solid #333',
                borderRadius: '16px',
                padding: '10px 15px',
              }}
            >
              Отменить
            </button>
          </div>
        </>
      </div>
    </div>
  )
}
