import { useEffect, useState, useCallback } from 'react';
import { CaretRightIcon } from 'assets/icons/CaretRightIcon';
import { ProfileBtn } from 'ui/ProfileBtn';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getNotificationsData, markNotificationAsReadData } from 'store/features/apiSlice';
import { Toast } from 'ui/ToasterComponent'
import config from 'config.json'

import s from './index.module.scss';

export const NotificationPage = () => {
  const [loading, setLoading] = useState(true);
  const [notificationsData, setNotificationsData] = useState([]);
  const [readNotifications, setReadNotifications]:any = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);  
  const [showToast, setShowToast] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filteredReadedNotifications = notificationsData.filter((item:any) => item.readed === true);
  const filteredUnReadedNotifications = notificationsData.filter((item:any) => item.readed === false);

  const getNotifications = useCallback(async () => {
    setLoading(true);
    const notificationsData = await dispatch(getNotificationsData());
    setNotificationsData(notificationsData?.payload?.data);
    setLoading(false);
  }, [dispatch]);

  const markAsRead = useCallback(
    async (notificationId: any) => {
        const obj = {
            ids: [notificationId]
        }
        await dispatch(markNotificationAsReadData(obj));
        setReadNotifications((prevReadNotifications:any) => [
            ...prevReadNotifications,
            notificationId,
        ]);
        getNotifications();
    },
    [dispatch]
  );

  const markAllNotificationAsRead = async () => {
    const allNotificationIds = notificationsData.map((item:any) => item.notify_id);
    if (allNotificationIds.length === 0) {
      return;
    }

    const obj = {
      ids: allNotificationIds,
    };

    await dispatch(markNotificationAsReadData(obj));

    setReadNotifications((prevReadNotifications:any) => [
      ...prevReadNotifications,
      ...allNotificationIds,
    ]);

    getNotifications();
  };

  const handleToastClose = () => {
    setShowToast(false);
  };


  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // Использование актуальных данных
      const updatedNotifications = await dispatch(getNotificationsData());
      const hasNewNotifications = updatedNotifications?.payload?.data?.some((item: any) => !item.readed);
  
      if (hasNewNotifications) {
        setShowToast(true);
      }
    }, config.get_notification_in_np);
  
    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [dispatch, getNotifications]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const RenderNotificationBlock = (item: any, index: any) => (
    <div
    className={`${s.notificationAlertBlock} df cp jcsb`}
    key={index}
    onClick={() => {markAsRead(item.notify_id); navigate(`/main/requests/details/${item?.requisition_id}`);}}
    style={{
      backgroundColor: item?.readed
        ? 'inherit'
        : '#f0f0f0',
    }}
  >
    <div className={`${s.leftNotificationBlock} df fdc`} style={{"width": "70%"}}>
      <div className={`${s.objectWithIDBlock} df fdr`}>
        <span className='fw600 fz12' style={{ color: '#828282' }}>
          Объект: {item?.object?.name}
        </span>
      </div>
      <div className='df'>
      <span className='fw600 fz12' style={{ color: '#333' }}>
        {item?.description.split('\n').map((line:any, index:any) => (
          <div key={index}>{line}</div>
        ))}
      </span>
      </div>
    </div>

    <div
      className={`${s.rightNotificationBlock} df fdr aic cp`}
      onClick={() => {
        navigate(`/main/requests/details/${item?.requisition_id}`);
      }}
    >
      <span className='fw600 fz12' style={{ color: '#333' }}>
        {!isMobile && 'Перейти к заявке'}
      </span>
      <CaretRightIcon />
    </div>
  </div>
  )

  return (
    <div className={`main w100`}>
      <div className={`${s.navbar} w100 df jcsb aic fww-mobile`}>
        <span className='page-title fw600'>Уведомления</span>
        <div className={`${s.right} df aic fww-mobile`}>
          <div className='profile-button'>
            <ProfileBtn />
          </div>
        </div>
      </div>
      <div className='df fdc' style={{ gap: '16px' }}>
        <div>
            <button className={`${s.btn} df jcc aic hover`} onClick={() => {markAllNotificationAsRead()}}>
                Прочитать все уведомления
            </button>
        </div>
        <div className={`${s.readUnreadBlock} df jcc aic`}>
          <span className='fw600 fz14' style={{'color': '#333'}}>Непрочитанные</span>
        </div>
        {filteredUnReadedNotifications.length !== 0 ? (
            filteredUnReadedNotifications.map((item: any, index: any) => RenderNotificationBlock(item, index))
          ) : (
            <>
              <span className='fz14 fw600'>Уведомления отсутствуют</span>
            </>
          )}

        <div className={`${s.readUnreadBlock} df jcc aic`}>
          <span className='fw600 fz14' style={{'color': '#333'}}>Прочитанные</span>
        </div>
        {filteredReadedNotifications.length !== 0 ? (
            filteredReadedNotifications.map((item: any, index: any) => RenderNotificationBlock(item, index))
          ) : (
            <>
              <span className='fz14 fw600'>Уведомления отсутствуют</span>
            </>
          )}
      </div>
      {showToast && <Toast message="Вам пришло новое уведомление" onClose={handleToastClose} />}
    </div>
  );
};
