import { Select } from 'antd'
import { ArrowBottom } from 'assets/icons/ArrowBottom'

interface props {
  placeholder: string
  items?: any
  state: any
  setState: Function
  width?: any
  doSome?: Function
  loadingText?: string
  height?: any
  disabled?: boolean
}

export const SelectComponent = ({
  placeholder,
  items,
  state,
  setState,
  width = '100%',
  height = '49px',
  disabled = false,
  doSome,
  loadingText = 'Нет данных...',
}: props) => {
  return (
    <Select
      showSearch
      className="fz16"
      disabled={disabled}
      placeholder={`${placeholder}*`}
      value={state}
      style={{ width: width, height: height }}
      onChange={(e) => {
        if (doSome) doSome(e)
        setState(e)
      }}
      dropdownRender={(menu) => (
        <div>
          <span
            className="fw600 fz14"
            style={{ marginLeft: '8px', marginBottom: '10px' }}
          >
            {placeholder}
          </span>
          {menu}
        </div>
      )}
      suffixIcon={<ArrowBottom />}
      notFoundContent={
        <div
          className="df fdc jcc aic"
          style={{ padding: '20px', gap: '10px' }}
        >
          <span className="fw600 tac">{loadingText}</span>
        </div>
      }
    >
      {items?.length >= 1 && (
        <>
          {items?.map((i: any, index: number) => (
            <Select.Option key={index} value={i?.name ? i?.name : i?.label}>
              {i?.name}
            </Select.Option>
          ))}
        </>
      )}
    </Select>
  )
}
