import { useCallback, useEffect, useState } from 'react';
import s from './index.module.scss'
import 'react-loading-skeleton/dist/skeleton.css'

// ICONS
import { ToggleOff } from 'assets/icons/ToggleOff';
import { ToggleOn } from 'assets/icons/ToggleOn';
import { PlusIcon } from 'assets/icons/Plus';
import { OnlineDot } from 'assets/icons/OnlineDot';

// UI
import { ProfileBtn } from 'ui/ProfileBtn';
import { AdduserPopup } from './components/AdduserPopup';
import { EditPopup } from './components/EditPopup';
import { RestorePopup } from 'ui/RestorePopup';
import { useDispatch } from 'react-redux';
import { getUsersData } from 'store/features/apiSlice';
import { SkeletonComponent } from 'ui/SkeletonComponent';
import { parseBorn, parseRole } from 'constDatas';


export const UserPage = () => {
    const [selectedUser, setSelectedUser] = useState<any | null>(null);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    // for toggle button/popup
    const [hide, setHide] = useState(false);

    // states for popup menus
    const [popup, setPopup] = useState(false);
    const [addpopup, setAddpopup] = useState(false);
    const [editpopup, setEditpopup] = useState(false);
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const dispatch = useDispatch()


    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      // Добавляем слушатель события изменения размера окна
      window.addEventListener('resize', handleResize);
  
      // Убираем слушатель при размонтировании компонента
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 

    const getUsers = useCallback(async () => {
        setLoading(true);
        const usersData = await dispatch(getUsersData());
        setAllData(usersData?.payload?.data);
        setData(usersData?.payload?.data?.filter((user: any) => user.active === !hide).slice().reverse());
        setLoading(false);
    }, [dispatch, hide]);

    const btnOff = () => {
        setHide(!hide)
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false)
        }, 1000);
    }

    useEffect(() => {
        getUsers()
    }, [getUsers]);

    useEffect(() => {
        setData(allData?.filter((user: any) => user.active === !hide))
    }, [hide, allData]);

    const getLastOnline = (lastOnline: any) => {
      const currentTime: any = new Date();
      const lastOnlineTime: any = new Date(lastOnline);
  
      const differenceInMinutes = Math.floor((currentTime - lastOnlineTime) / (1000 * 60));
      const differenceInHours = Math.floor((currentTime - lastOnlineTime) / (1000 * 60 * 60));
      const differenceInDays = Math.floor((currentTime - lastOnlineTime) / (1000 * 60 * 60 * 24));

      if (differenceInMinutes < 60) {
        return `был(а) в сети ${differenceInMinutes} минут назад`;
      } else if (differenceInHours < 24) {
        return `был(а) в сети ${differenceInHours} часов назад`;
      } else {
        return `был(а) в сети ${differenceInDays} дней назад`;
      }
    };

    const RenderSkeleton = () => (
        <>
          {Array(loading ? data?.length || 7 : 7).fill('').map((_, i) => (
            <div style={{ height: '45px', borderRadius: '16px', marginTop: '3px' }} key={i}>
              <SkeletonComponent height={'100%'} bradius={'16px'} />
            </div>
          ))}
        </>
      );
      
      const RenderDesktopUser = (item: any, index: any) => (
        <div
          className={`${s.secondrow} cp w100 df aic fz16`}
          onClick={() => {
            setEditpopup(true);
            setSelectedUser(item);
          }}
          key={index}
        >
          <span className={`textellipsis w40`}>{item?.full_name}
            {item?.is_online ? <div><OnlineDot /><span className='line fz14' style={{ marginLeft: '8px' }}>в сети</span></div>
              : <div><span className='line fz14'>{getLastOnline(item?.last_online)}</span></div>}
          </span>
          <span className={`textellipsis w20`}>{item?.username}</span>
          <span className={`textellipsis w30`}>{parseRole(item?.roles?.[0]?.role)}</span>
          {hide ? (
            <div className='df aic w10' style={{gap: "10px"}}>
              <span className={`w10`}>{parseBorn(item?.deposit) ? parseBorn(item?.deposit) : 'Отстутсвует'}</span>
              <button className={`${s.recover} cp df jcc aic`} onClick={(e) => { e.stopPropagation(); setPopup(true) }}>Восстановить</button>
            </div>
          ) : <span className={`textellipsis w20`}>{parseBorn(item?.deposit)}</span>}
        </div>
      );

      const RenderMobileUser = (item: any, index: any) => (
          <div 
          className={s.mobileUserBlock}
          onClick={() => {
            setEditpopup(true);
            setSelectedUser(item);
          }}
          key={index}>
            <div className={s.nameBlock}>
              <span className='fz16 fw600' style={{color: "#333"}}>{`${item?.full_name}`}</span>
              {item?.is_online ? <div><OnlineDot /><span className={`${s.onlineStatus} fz14 fw600`} style={{ marginLeft: '8px' }}>в сети</span></div>
              : <div><span className={`${s.onlineStatus} fz14 fw600`}>{getLastOnline(item?.last_online)}</span></div>}
            </div>
            <div className={s.userInfoBlock}>
              <span className='fz14 fw600' style={{color: "#828282"}}>{`${parseRole(item?.roles?.[0]?.role)}`}</span>
            </div>
            <div style={{borderBottom: "1px dashed #E0E0E0", height: "1px"}}></div>
            <div className={`${s.userInfoSecondBlock} df`}>
              <span className='fz14 fw600' style={{color: "#333"}}>{`${item?.username}`}</span>
              <span className='fz14 fw600' style={{color: "#333"}}>{`${parseBorn(item?.deposit) ? parseBorn(item?.deposit) : 'Отстутсвует'}`}</span>
            </div>
            {hide ? (<div>
              <button className={`${s.recoverMobileBlock} cp df aic fz14 fw600`} onClick={(e) => { e.stopPropagation(); setPopup(true) }}>Восстановить</button>
            </div>) : <></>
            }
          </div>
      );

    return (
        <>
          {popup && <RestorePopup title={"Восстановить пользователя?"} subtitle={"Подтвердить восстановление пользователя"} popup={popup} setPopup={setPopup} selected={data[0]} refreshData={getUsers} />}
          {addpopup && <AdduserPopup popup={addpopup} setPopup={setAddpopup} refreshData={getUsers} />}
          {editpopup && <EditPopup selected={selectedUser} popup={editpopup} setPopup={setEditpopup} refreshData={getUsers} />}
      
          <div className={`main w100`}>
            <div className={`${s.navbar} w100 df jcsb aic fww-mobile`}>
              <span className='page-title fw600'>Пользователи</span>
              <div className={`${s.right} df aic fww-mobile`}>
                <div className={`${s.toggle} page-toggle df jcsb aic`}>
                  <span className='fz16 fw700'>Скрытые</span>
                  <button
                    disabled={isDisabled}
                    className='cp'
                    onClick={btnOff}
                    style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                  >
                    {hide ? <ToggleOff /> : <ToggleOn />}
                  </button>
                </div>
                <div className={`${s.adduser} cp df jcc aic`} onClick={() => setAddpopup(true)}>
                  <PlusIcon color={'black'} />
                  <span className='fw700 fz16'>Новый пользователь</span>
                </div>
                <div className='profile-button'>
                <ProfileBtn />
                </div>
              </div>
            </div>
            <div className={`${s.row} w100 aic fz18 fw600`}>
              <span className='w40'>ФИО</span>
              <span className='w20'>Логин</span>
              <span className='w30'>Роль</span>
              <span className='w10'>Месторождение</span>
            </div>
            <div className={`${s.lists}`}>
              {loading ? (
                <RenderSkeleton />
              ) : (
                data.map((item, index) => (isMobile ? RenderMobileUser(item, index) : RenderDesktopUser(item, index)))
              )}
            </div>
            {isMobile && !addpopup && !popup && !editpopup && (
              <div className={`${s.mobileadduser} cp df jcc aic`} onClick={() => setAddpopup(true)}>
                <PlusIcon color={'black'} />
                <span className='fw700 fz16'>Новый пользователь</span>
              </div>
            )}
          </div>
        </>
      );
}