import { useCallback, useEffect, useState, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Pagination } from 'ui/PaginationComponent'
import {
  getDeactivatedOrFinishedRequestsData,
  getTodayRequestsData,
  setUser,
} from 'store/features/apiSlice'
import { pageSize, bornTranslations } from 'constDatas';
import { ProfileBtn } from 'ui/ProfileBtn'
import { PlusIcon } from 'assets/icons/Plus'
import { FilterIcon } from 'assets/icons/FilterIcon'
import { ProgressBar } from 'ui/ProgressBar'
import { RequestPriorityStar } from 'assets/icons/RequestPriorityStar'
import { SkeletonComponent } from 'ui/SkeletonComponent'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

import config from 'config.json'

import s from './index.module.scss'
import {
  allBorns,
  allRequests,
  bornData,
  parseBorn,
  parseCompanyType,
  parsePriority,
  parseStatus,
} from 'constDatas'
import { InputComponent } from 'ui/InputComponent'

export const RequestPage = () => {
  const currentDateTime = dayjs()
  const [requestData, setRequestData] = useState([])
  const [highRequests, setHighRequests] = useState([])
  const [midRequests, setMidRequests] = useState([])
  const [lowRequests, setLowRequests] = useState([])
  const [activeRequest, setActiveRequest] = useState(0)
  const [activeBorn, setActiveBorn] = useState(0)
  const [filterDisabled, setFilterDisabled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [forceUpdate, setForceUpdate] = useState(0)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [isBtn, setIsBtn] = useState(false)
  const [activeButton, setActiveButton] = useState('all')
  const [searchValue, setSearchValue] = useState('')
  const [userRole, setUserRole] = useState(
    localStorage.getItem('userrole') || ''
  )
  const [userDeposit, setUserDeposit] = useState(
    localStorage.getItem('userdeposit') || null
  )
  let initialActiveBorn = 0
  let debounceTimeout: NodeJS.Timeout;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCurrentPage: any = searchParams.get('current_page');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(queryCurrentPage ? Number(queryCurrentPage) : 1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [EveresthighRequests, setEverestHighRequests] = useState([])
  const [EverestmidRequests, setEverestMidRequests] = useState([])
  const [EverestlowRequests, setEverestLowRequests] = useState([])
  

  const [NorthernhighRequests, setNorthernHighRequests] = useState([])
  const [NorthernmidRequests, setNorthernMidRequests] = useState([])
  const [NorthernlowRequests, setNorthernLowRequests] = useState([])

  const [KozykoshhighRequests, setKozykoshHighRequests] = useState([])
  const [KozykoshmidRequests, setKozykoshMidRequests] = useState([])
  const [KozykoshlowRequests, setKozykoshLowRequests] = useState([])

  const [showFilter, setShowFilter] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const querySearchValue = searchParams.get('search_value');
  const dateFromRef = useRef(currentDateTime.subtract(24, 'hour'))
  const dateToRef = useRef(currentDateTime)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const processRequests = (data: any) => {
    setRequestData(data.reverse());
    setHighRequests(data.filter((item: { priority: string }) => item?.priority === 'High').reverse());
    setMidRequests(data.filter((item: { priority: string }) => item?.priority === 'Normal').reverse());
    setLowRequests(data.filter((item: { priority: string }) => item?.priority === 'Low').reverse());
  
    setEverestHighRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'High' && item?.deposit === 'Everest').reverse());
    setEverestMidRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'Normal' && item?.deposit === 'Everest').reverse());
    setEverestLowRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'Low' && item?.deposit === 'Everest').reverse());
  
    setNorthernHighRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'High' && item?.deposit === 'Northern').reverse());
    setNorthernMidRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'Normal' && item?.deposit === 'Northern').reverse());
    setNorthernLowRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'Low' && item?.deposit === 'Northern').reverse());
  
    setKozykoshHighRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'High' && item?.deposit === 'Kozykosh').reverse());
    setKozykoshMidRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'Normal' && item?.deposit === 'Kozykosh').reverse());
    setKozykoshLowRequests(data.filter((item: { priority: string; deposit: string }) => item?.priority === 'Low' && item?.deposit === 'Kozykosh').reverse());
  };
  
  const fetchData = useCallback(async (status: any, index: any, isToday: any) => {
    setLoading(true);
  
    const obj = {
      status: parseStatus(status),
      page: currentPage,
      per_page: pageSize,
      company_customer_name: querySearchValue,
      deposit: parseBorn(bornData?.[index || activeBorn]?.label),
      date_from: dateFromRef.current ? dateFromRef.current.format('YYYY-MM-DD HH:mm:ss') : undefined,
      date_to: dateToRef.current ? dateToRef.current.format('YYYY-MM-DD HH:mm:ss') : undefined,
    };
  
    const requestSlice = isToday ? getTodayRequestsData : getDeactivatedOrFinishedRequestsData;
    const requestData = await dispatch(requestSlice(obj));
  
    processRequests(requestData?.payload?.data || []);

    let calculatedTotalPages = Math.ceil(requestData?.payload?.total / pageSize) || 1;
    if (calculatedTotalPages === 0) {
      calculatedTotalPages = 1;
    }
    setTotalPages(calculatedTotalPages);
  
    setLoading(false);
    setFilterDisabled(false);
    setForceUpdate((prev) => prev + 1);
  }, [dispatch, activeBorn, currentPage, pageSize, querySearchValue]);

  useEffect(() => {
    setFilterDisabled(true)
  }, [activeBorn, activeRequest])

  const getRequests = useCallback(async () => {
    if (userDeposit === "All" || (userDeposit !== "All" && activeBorn !== 0 && activeRequest !== null)) {
        const selectedRequest = allRequests?.[activeRequest];
        if (
            selectedRequest === 'Открытые' ||
            selectedRequest === 'Открытые, Незавершенные на сегодня' ||
            selectedRequest === 'Открытые, Завершенные на сегодня'
        ) {
            fetchData(selectedRequest, activeBorn, true);
        } else if (selectedRequest === 'Деактивированные' || selectedRequest === 'Полностью завершенные') {
            fetchData(selectedRequest, activeBorn, false);
        }
    }
}, [activeBorn, activeRequest, fetchData, userDeposit, allRequests]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const localStorageUserRole = localStorage.getItem('userrole')
      const localStorageUserDeposit = localStorage.getItem('userdeposit')

      if (localStorageUserRole && localStorageUserDeposit) {
        setUserRole(localStorageUserRole)
        setUserDeposit(localStorageUserDeposit)
        clearInterval(intervalId)
      }
    })

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    getRequests()
  }, [getRequests])

  useEffect(() => {
    if (isWeighingOperatorOrFieldDirector(userRole)) {
      const timeout = setTimeout(() => {
        setActiveBorn(initialActiveBorn)
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [userRole, showFilter])

  useEffect(() => {
    if (querySearchValue) {
      setSearchValue(querySearchValue);
    }
  }, [querySearchValue]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const params = new URLSearchParams(url.search);

      if (searchValue) {
        params.set('search_value', searchValue);
      } else {
        params.delete('search_value');
      }

      const newUrl = `${url.pathname}?${params.toString()}`;

      navigate(newUrl);
    }, 500);

    return () => clearTimeout(timerId);
  }, [searchValue, navigate]);

  

    useEffect(() => {
        if (searchValue) {
            setCurrentPage(1);
        }
    }, [searchValue])

  const clickHandler = (buttonName: string) => {
    if (buttonName === 'all') {
      fetchData(allRequests?.[activeRequest], activeBorn, true)
    } else if (buttonName === 'high') {
      setMidRequests([])
      setLowRequests([])
      setEverestMidRequests([])
      setNorthernMidRequests([])
      setKozykoshMidRequests([])
      setEverestLowRequests([])
      setNorthernLowRequests([])
      setKozykoshLowRequests([])

      setHighRequests(
        requestData?.filter((item: any) => item?.priority === 'High')?.reverse()
      )
      setEverestHighRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'High' && item?.deposit === 'Everest'
          )
          ?.reverse()
      )
      setNorthernHighRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'High' && item?.deposit === 'Northern'
          )
          ?.reverse()
      )
      setKozykoshHighRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'High' && item?.deposit === 'Kozykosh'
          )
          ?.reverse()
      )
    } else if (buttonName === 'normal') {
      setHighRequests([])
      setLowRequests([])
      setEverestHighRequests([])
      setNorthernHighRequests([])
      setKozykoshHighRequests([])
      setEverestLowRequests([])
      setNorthernLowRequests([])
      setKozykoshLowRequests([])

      setMidRequests(
        requestData
          ?.filter((item: any) => item?.priority === 'Normal')
          ?.reverse()
      )
      setEverestMidRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'Normal' && item?.deposit === 'Everest'
          )
          ?.reverse()
      )
      setNorthernMidRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'Normal' && item?.deposit === 'Northern'
          )
          ?.reverse()
      )
      setKozykoshMidRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'Normal' && item?.deposit === 'Kozykosh'
          )
          ?.reverse()
      )
    } else if (buttonName === 'low') {
      setHighRequests([])
      setMidRequests([])
      setEverestHighRequests([])
      setNorthernHighRequests([])
      setKozykoshHighRequests([])
      setEverestMidRequests([])
      setNorthernMidRequests([])
      setKozykoshMidRequests([])
      setLowRequests(
        requestData?.filter((item: any) => item?.priority === 'Low')?.reverse()
      )
      setEverestLowRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'Low' && item?.deposit === 'Everest'
          )
          ?.reverse()
      )
      setNorthernLowRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'Low' && item?.deposit === 'Northern'
          )
          ?.reverse()
      )
      setKozykoshLowRequests(
        requestData
          ?.filter(
            (item: any) =>
              item?.priority === 'Low' && item?.deposit === 'Kozykosh'
          )
          ?.reverse()
      )
    }
    setActiveButton(buttonName)
    setIsBtn(true)
    setTimeout(() => {
      setIsBtn(false)
    }, 200)
  }

  const searchValueHandler = (e: any) => {
    const newSearchValue = e.target.value.toLowerCase();
    setSearchValue(newSearchValue);
  
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(async () => {
      const selectedRequest = allRequests?.[activeRequest];
  
      if (selectedRequest && activeBorn !== undefined) {
        const response = await dispatch(getTodayRequestsData({
          per_page: pageSize,
          page: currentPage,
          status: parseStatus(selectedRequest),
          deposit: parseBorn(bornData?.[activeBorn || activeBorn]?.label),
          company_customer_name: newSearchValue,
        }));
  
        setData(response?.payload?.data);
      }
    }, 500);
  };
  

  const filteredHighRequests = highRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredHighEverestRequests = EveresthighRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'High' && item?.deposit === 'Everest' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredMidEverestRequests = EverestmidRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'Normal' && item?.deposit === 'Everest' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredLowEverestRequests = EverestlowRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'Low' && item?.deposit === 'Everest' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredHighNorthernRequests = NorthernhighRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'High' && item?.deposit === 'Northern' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredMidNorthernRequests = NorthernmidRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'Normal' && item?.deposit === 'Northern' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredLowNorthernRequests = NorthernlowRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'Low' && item?.deposit === 'Northern' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredHighKozykoshRequests = KozykoshhighRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'High' && item?.deposit === 'Kozykosh' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredMidKozykoshRequests = KozykoshmidRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'Normal' && item?.deposit === 'Kozykosh' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredLowKozykoshRequests = KozykoshlowRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return item?.priority === 'Low' && item?.deposit === 'Kozykosh' && companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredMidRequests = midRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return companyName && companyName.includes(searchValue);
  }).reverse();

  const filteredLowRequests = lowRequests.filter((item: any) => {
    const companyName = item?.company_customer_name?.toLowerCase();
    return companyName && companyName.includes(searchValue);
  }).reverse();

  const handlePageChange = async (pageNumber: any) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      
      const newOffset = pageNumber;
      const selectedRequest = allRequests?.[activeRequest];
      
      if (selectedRequest && activeBorn !== undefined) {
        const response = await dispatch(getTodayRequestsData({
          per_page: pageSize,
          page: newOffset,
          status: parseStatus(selectedRequest),
          deposit: parseBorn(bornData?.[activeBorn || activeBorn]?.label),
          company_customer_name: searchValue,
        }));
  
        setData(response?.payload?.data);
      }
    }
  };
  

  const isWeighingOperatorOrFieldDirector = (userRole: string) =>
    userRole === 'WeighingOperator' || userRole === 'FieldDirector'

  const renderFilteringByBorn = () => {
    if (!userRole || !userDeposit) {
      return null
    }

    return allBorns.map((item, index) => {
      const isOperatorOrDirector = isWeighingOperatorOrFieldDirector(userRole)
      const depositMatches = parseBorn(userDeposit)
      const born = item.replace('м/р ', '')

      if (
        initialActiveBorn === 0 &&
        isOperatorOrDirector &&
        depositMatches === born
      ) {
        initialActiveBorn = index
      }

      if (!isOperatorOrDirector || depositMatches === born) {
        return (
          <button
            className={`${s.tag} df jcc aic cp usa hover`}
            key={index}
            disabled={filterDisabled}
            onClick={() => {
              setActiveBorn(index)
              setCurrentPage(1);
              setActiveButton('all')
            }}
            style={{
              background: index === activeBorn ? '#F2C94C' : '',
              cursor: filterDisabled ? 'not-allowed' : 'pointer',
            }}
          >
            <span className="wsn">{item}</span>
          </button>
        )
      }
      return null
    })
  }

  const renderRequestDesktopCards = (requests: any) => (
    <>
      {requests?.map((t: any, i: any) => (
        <Link
          to={`/main/requests/details/${t?.id}`}
          className={`${s.card}`}
          key={i}
          onClick={() =>
            localStorage.setItem('selectedRequest', JSON.stringify(t))
          }
        >
          <div className={`${s.cardcontainer} hover`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '15px',
                color: '#828282',
              }}
            >
              <span className="fz14 fw600">
                {parseCompanyType(t?.company_customer_type)} "
                {t?.company_customer_name}"
              </span>
              <div className="df" style={{ gap: '12px' }}>
                <span>{`ID: ${t?.id}`}</span>
                {`${parsePriority(t?.priority)}` === 'Высокий' ? (
                  <div>
                    <RequestPriorityStar />
                    <RequestPriorityStar />
                    <RequestPriorityStar />
                  </div>
                ) : `${parsePriority(t?.priority)}` === 'Средний' ? (
                  <div>
                    <RequestPriorityStar />
                    <RequestPriorityStar />
                  </div>
                ) : (
                  <div>
                    <RequestPriorityStar />
                  </div>
                )}
              </div>
            </div>
            {t?.object_name && (
              <div style={{ marginTop: '8px' }}>
                <span
                  style={{
                    fontWeight: '700',
                    fontSize: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textTransform: 'uppercase',
                  }}
                >
                  Объект: {`${t?.object_name}`}
                </span>
              </div>
            )}
            <div style={{ marginTop: '8px' }}>
              <span
                style={{
                  color: '#828282',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Материал: {`${t?.material_name}`}
              </span>
            </div>
            <div style={{ marginTop: '8px' }}>
              <span
                style={{
                  color: '#828282',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Месторождение: {bornTranslations[t?.deposit] || t?.deposit}
              </span>
            </div>
            {t?.alltime_requisition_volume ? (
              <ProgressBar
                min={t?.alltime_weight}
                max={t?.alltime_requisition_volume}
              />
            ) : (
              <div
                className="df fdc"
                style={{
                  gap: '8px',
                  marginTop: '10px',
                  color: '#828282',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                <span>Отгружено за день: {t?.today_weight}</span>
                <span>Отгружено за всё время: {t?.alltime_weight}</span>
              </div>
            )}
          </div>
        </Link>
      ))}
    </>
  )

  const renderSkeletonCards = (length: any) => (
    <>
      {Array(length)
        .fill('')
        .map((_, i) => (
          <div key={i}>
            <SkeletonComponent height="170px" bradius="15px" />
          </div>
        ))}
    </>
  )

  const RenderRequestFilter = () => (
    <div className={`${s.bar}`}>
      <span style={{ color: '#333' }} className="asfs fw600 fz14 ttuc">
        По статусу
      </span>
      <div className="w100 df jcfs aic fww" style={{ gap: '16px' }}>
        {allRequests.map((item, index) => (
          <button
            className={`${s.tag} df jcc aic cp usa hover`}
            key={index}
            disabled={filterDisabled}
            onClick={() => {
              setActiveRequest(index)
              setCurrentPage(1);
              setActiveButton('all')
            }}
            style={{
              background: index === activeRequest ? '#F2C94C' : '',
              cursor: filterDisabled ? 'not-allowed' : 'pointer',
            }}
          >
            <span className="wsn">{item}</span>
          </button>
        ))}
      </div>
      <span
        style={{ color: '#333', marginTop: '16px' }}
        className="asfs fw600 fz14 ttuc"
      >
        По месторождению
      </span>

      <div className="w100 df jcfs aic fww" style={{ gap: '16px' }}>
        {renderFilteringByBorn()}
      </div>

      <span
        style={{ color: '#333', marginTop: '16px' }}
        className="asfs fw600 fz14 ttuc"
      >
        По компаниям
      </span>
      <InputComponent
            type='default'
            placeholder='Поиск'
            width={'50%'}
            state={searchValue}
            setState={setSearchValue}
        />
        <Pagination 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePageChange} 
            prevButtonText="Назад" 
            nextButtonText="Вперед" 
      />
      {(allRequests?.[activeRequest] === 'Деактивированные' ||
        allRequests?.[activeRequest] === 'Полностью завершенные') && (
        <div className={`${s.requestfiltering}`}>
          <div style={{ marginBottom: '12px' }}>
            <span
              className="fw700 fz18"
              style={{ color: '#333', lineHeight: 'normal' }}
            >
              Фильтр
            </span>
          </div>
          <div
            className={`${s.requestDatePickerBlock} df w100 fdc-aic-mobile`}
            style={{ gap: '16px' }}
          >
            <DatePicker
              style={{
                width: '266px',
                height: '56px',
                padding: '16px 16px 16px 12px',
                alignItems: 'center',
                gap: '8px',
              }}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              defaultValue={dateFromRef.current}
              value={dateFromRef.current}
              onChange={(dateFrom: any) => {
                dateFromRef.current = dateFrom
                setForceUpdate((prev) => prev + 1)
              }}
              placeholder="Начало отчетности"
            />
            <DatePicker
              style={{
                width: '266px',
                height: '56px',
                padding: '16px 16px 16px 12px',
                alignItems: 'center',
                gap: '8px',
              }}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              value={dateToRef.current}
              defaultValue={dateToRef.current}
              onChange={(dateTo: any) => {
                dateToRef.current = dateTo
                setForceUpdate((prev) => prev + 1)
              }}
              placeholder="Конец отчетности"
            />
            <button
              className={`${s.applybutton} w100 df jcc aic`}
              onClick={() =>
                fetchData(allRequests?.[activeRequest], activeBorn, false)
              }
            >
              <PlusIcon />
              <span className="wsn">Применить</span>
            </button>
            <button
              className={`${s.resetbutton} w100 df jcc aic`}
              onClick={() => {
                dateFromRef.current = currentDateTime.subtract(24, 'hour')
                dateToRef.current = currentDateTime
                setForceUpdate((prev) => prev + 1)
              }}
            >
              <PlusIcon />
              <span className="wsn">Сбросить</span>
            </button>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <>
      <div className={`main w100`}>
        <div className={`${s.navbar} w100 df jcsb aic`}>
          <span className="page-title fw600">Журнал заявок</span>
          {isMobile && (
            <div
              className={`cp df jcc aic`}
              onClick={() => setShowFilter(!showFilter)}
            >
              <FilterIcon />
            </div>
          )}
          <div className={`${s.right} df aic fdc-aic-mobile`}>
            <div
              className={`${s.addrequest} df jcc aic cp hover`}
              onClick={() => navigate('/main/requests/add')}
            >
              <PlusIcon />
              <span className="wsn">Новая заявка</span>
            </div>
            <div className="profile-button">
              <ProfileBtn />
            </div>
          </div>
        </div>
        {!isMobile && RenderRequestFilter()}
        {showFilter && isMobile && RenderRequestFilter()}
        <div className={`${s.priorityRow}`}>
          <span className={`fw600 ttuc`}>Высокий приоритет</span>
          <span className={`fw600 ttuc`}>Средний приоритет</span>
          <span className={`fw600 ttuc`}>Низкий приоритет</span>
        </div>
        <div className={`${s.lineRow}`}></div>

        {isMobile && (
          <>
            <div style={{ marginTop: '24px' }}>
              <span className={`fz14 fw600 ttuc`} style={{ color: '#333' }}>
                Приоритет
              </span>
            </div>
            <div className={`${s.priorityBar}  df aic fz18`}>
              <button
                className={`${s.priorityBarButton} ${
                  activeButton === 'all' ? s.activeButton : ''
                }`}
                disabled={isBtn}
                onClick={() => {
                  clickHandler('all')
                }}
              >
                Все
              </button>
              <button
                className={`${s.priorityBarButton} ${
                  activeButton === 'high' ? s.activeButton : ''
                }`}
                disabled={isBtn}
                onClick={() => {
                  clickHandler('high')
                }}
              >
                Высокий
              </button>
              <button
                className={`${s.priorityBarButton} ${
                  activeButton === 'normal' ? s.activeButton : ''
                }`}
                disabled={isBtn}
                onClick={() => {
                  clickHandler('normal')
                }}
              >
                Средний
              </button>
              <button
                className={`${s.priorityBarButton} ${
                  activeButton === 'low' ? s.activeButton : ''
                }`}
                disabled={isBtn}
                onClick={() => {
                  clickHandler('low')
                }}
              >
                Низкий
              </button>
            </div>
          </>
        )}

        {bornData?.[activeBorn]?.label === 'Все' ? (
          <>
            <div className={`df jcsb fdc-aic-mobile`}>
              <div className={`${s.left} w100`}>
                {!loading ? (
                  <>{renderRequestDesktopCards(filteredHighEverestRequests)}
                    {renderRequestDesktopCards(filteredHighNorthernRequests)}
                    {renderRequestDesktopCards(filteredHighKozykoshRequests)}

                  </>
                ) : (
                  <>
                    {EveresthighRequests?.length !== 0 ? (
                      <>{renderSkeletonCards(2)}</>
                    ) : (
                      <>{renderSkeletonCards(2)}</>
                    )}
                  </>
                )}
              </div>
              <div className={`${s.mid} w100`}>
                {!loading ? (
                  <>{renderRequestDesktopCards(filteredMidEverestRequests)}
                    {renderRequestDesktopCards(filteredMidNorthernRequests)}
                    {renderRequestDesktopCards(filteredMidKozykoshRequests)}
                  </>
                ) : (
                  <>
                    {EverestmidRequests?.length !== 0 ? (
                      <>{renderSkeletonCards(2)}</>
                    ) : (
                      <>{renderSkeletonCards(2)}</>
                    )}
                  </>
                )}
              </div>
              <div className={`${s.right} w100`}>
                {!loading ? (
                  <>
                  {renderRequestDesktopCards(filteredLowEverestRequests)}
                  {renderRequestDesktopCards(filteredLowNorthernRequests)}
                  {renderRequestDesktopCards(filteredLowKozykoshRequests)}
                </>
                
                ) : (
                  <>
                    {EverestlowRequests?.length !== 0 ? (
                      <>{renderSkeletonCards(2)}</>
                    ) : (
                      <>{renderSkeletonCards(2)}</>
                    )}
                  </>
                )}
              </div>
            </div>
            { EveresthighRequests?.length === 0 && EverestmidRequests?.length === 0 && EverestlowRequests?.length === 0 &&
              NorthernhighRequests?.length === 0 && NorthernmidRequests?.length === 0 && NorthernlowRequests?.length === 0 &&
              KozykoshhighRequests?.length === 0 && KozykoshmidRequests?.length === 0 && KozykoshlowRequests?.length === 0 && (
                <div className="tac" style={{ margin: '20px' }}>
                  <span>Заявки отсутствуют</span>
                </div>
              )}
              <Pagination 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePageChange} 
            prevButtonText="Назад" 
            nextButtonText="Вперед" 
      />
          </>
        ) : (
          <>
            <div
              style={{ marginTop: '12px', marginBottom: '12px' }}
              className="tac fz14 fw700 ttuc"
            >
              <span style={{ color: '#333' }}>
                Месторождение {bornData?.[activeBorn].label}
              </span>
            </div>
            <div className={`df jcsb fdc-aic-mobile`}>
              <div className={`${s.left} w100`}>
                {!loading ? (
                  <>{renderRequestDesktopCards(filteredHighRequests)}</>
                ) : (
                  <>
                    {highRequests?.length !== 0 ? (
                      <>{renderSkeletonCards(2)}</>
                    ) : (
                      <>{renderSkeletonCards(2)}</>
                    )}
                  </>
                )}
              </div>
              <div className={`${s.mid} w100`}>
                {!loading ? (
                  <>{renderRequestDesktopCards(filteredMidRequests)}</>
                ) : (
                  <>
                    {midRequests?.length !== 0 ? (
                      <>{renderSkeletonCards(2)}</>
                    ) : (
                      <>{renderSkeletonCards(2)}</>
                    )}
                  </>
                )}
              </div>
              <div className={`${s.right} w100`}>
                {!loading ? (
                  <>{renderRequestDesktopCards(filteredLowRequests)}</>
                ) : (
                  <>
                    {lowRequests?.length !== 0 ? (
                      <>{renderSkeletonCards(2)}</>
                    ) : (
                      <>{renderSkeletonCards(2)}</>
                    )}
                  </>
                )}
              </div>
            </div>
            {highRequests?.length === 0 &&
              midRequests?.length === 0 &&
              lowRequests?.length === 0 && (
                <div className="tac" style={{ margin: '20px' }}>
                  <span>Заявки отсутствуют</span>
                </div>
              )}
              <Pagination 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePageChange} 
            prevButtonText="Назад" 
            nextButtonText="Вперед" 
      />
          </>
        )}
        {isMobile && (
          <div
            className={`${s.mobileadd} cp df jcc aic`}
            onClick={() => navigate('/main/requests/add')}
          >
            <PlusIcon color={'black'} />
          </div>
        )}
      </div>
    </>
  )
}
