import { CloseIcon } from "assets/icons/Close";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { putEditCompaniesData } from "store/features/apiSlice";

interface props {
    title: string
    subtitle: string
    popup: boolean
    setPopup: Function
    selected?: any
    refreshData?: any
    refreshData2?: any
    hide?: boolean
}

export const RestoreCompanyPopup = ({ title, subtitle, popup, setPopup, selected, refreshData, refreshData2, hide }: props) => {
    const [isRecover, setIsRecover] = useState(false);
    const [apierror, setApierror] = useState('');

    const dispatch = useDispatch()

    useEffect(() => {
        if (popup) document.body.style.overflow = 'hidden'
        return () => { document.body.style.overflow = ''; };
    }, [popup]);

    async function recoverHandler() {
        setIsRecover(true)
        const { representative_contacts, company_type, name, ...obj }: any = selected;
        obj.active = true
        const response = await dispatch(putEditCompaniesData(obj))
        if (response?.payload?.message === 'ok') {
            hide ? refreshData() : refreshData2()
            setPopup(false)
            setIsRecover(false)
        } else if (response?.error?.message.includes('403')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else if (response?.error?.message.includes('401')) {
            setApierror('Недостаточно прав для выполнения этого запроса.')
            return
        } else {
            setApierror('Ошибка сервера')
            return
        }

    }

    return (
        <div className="h100vh posf t0 l0 w100 df jcc aic" style={{ zIndex: '10', background: 'rgba(51, 51, 51, 0.55)' }}>
            <div className="df fdc alfs" style={{ background: 'white', borderRadius: '30px', padding: '20px', gap: '20px' }}>
                <div className="df jcsb aic">
                    <span style={{ fontSize: '20px', marginRight: '15px' }}>{title}</span>
                    <div className="df jcc aic cp" onClick={() => setPopup(false)}><CloseIcon /></div>
                </div>
                <span>{subtitle}</span>
                {apierror !== '' && <span style={{ color: '#EB5757', fontSize: '14px', marginLeft: '8px' }}>{apierror}</span>}
                <div className="df jcsb aic fz18" style={{ gap: '10px' }}>
                    <button
                        onClick={() => recoverHandler()}
                        className="cp tac"
                        style={{
                            width: '100%',
                            background: '#F2C94C',
                            borderRadius: '16px',
                            padding: '10px 15px',
                            cursor: isRecover ? "not-allowed" : "pointer"
                        }}
                    >
                        Восстановить
                    </button>
                    <div
                        onClick={() => setPopup(false)}
                        className="cp tac"
                        style={{ width: '100%', border: '1px solid #333', borderRadius: '16px', padding: '10px 15px' }}
                    >
                        Нет
                    </div>
                </div>
            </div>
        </div>
    )
}