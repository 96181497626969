import s from './index.module.scss'
import { ProfileBtn } from 'ui/ProfileBtn';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const FilePage = () => {
    const [isBtn, setIsBtn] = useState(false);

    const navigate = useNavigate()

    const url: any = useLocation()

    const clickHandler = () => {
        setIsBtn(true)
        setTimeout(() => {
            setIsBtn(false)
        }, 200);
    }

    return (
        <div className={`main w100`}>
            <div className={`${s.navbar} w100 df jcsb aic fww-mobile`}>
                <span className='page-title fw600'>Документы</span>
                <div className={`${s.right} df aic fww-mobile`}>
                    <div className='profile-button'>
                        <ProfileBtn />
                    </div>
                </div>
            </div>
            <div>
                <div className={`${s.bar} df aic fz18`}>
                    <button
                        className={s.bar_button}
                        disabled={isBtn}
                        onClick={() => { navigate('/main/files'); clickHandler() }}
                        style={{
                            borderBottom: url.pathname === "/main/files" ? '2px solid black' : '',
                            fontWeight: url.pathname === "/main/files" ? '600' : '',
                        }}>
                        Сводная накладная
                    </button>
                    <button
                        className={s.bar_button}
                        disabled={isBtn}
                        style={{
                            borderBottom: url.pathname.includes("application-report") ? '2px solid black' : '',
                            fontWeight: url.pathname.includes("application-report") ? '600' : '',
                        }}
                        onClick={() => { navigate('/main/files/application-report'); clickHandler() }}
                    >
                        Отчет по заявкам
                    </button>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

