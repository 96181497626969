import { SideBar } from "components/SideBar/SideBar"
import { Outlet } from "react-router-dom"
import { NotificationCheckComponent } from 'ui/NotificationCheckComponent';

export const MainLayout = () => {
    return (
        <div className="df posr">
            <SideBar />

            {/* Все остальное слева бара */}
            <NotificationCheckComponent/>
            <Outlet />
        </div>
    )
}